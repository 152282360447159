.sub_container {
  position: relative;
  display: flex;
  overflow-y: hidden;
}

// 사이드 바

.side_container {
  width: 300px;
  padding-top: 71.5px;
  box-sizing: border-box;
  margin-bottom: -5000px;
  padding-bottom: 5000px;
}

.side_inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.menu_list_box {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.active .menu_list_item_box {
  background-color: #f9f9f9;
}

.menu_list_item_box {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 20px 50px;
  box-sizing: border-box;
}

.menu_list_item_box:hover .list_text {
  color: #ff5360;
}

.menu_list_item_box:hover .img_off {
  display: none;
}

.menu_list_item_box:hover .img_on {
  display: block;
}

.active .list_text {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.active .img_on {
  display: block;
}

.active .img_off {
  display: none;
}

.img_on {
  display: none;
}

.img_off {
  display: block;
}

.list_text {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.select_media_box {
  padding: 30px;
  box-sizing: border-box;
  border-top: 1px solid #dfdfdf;
  height: calc(100vh + 550px);
  overflow-y: scroll;
}

.select_text_box {
  margin-bottom: 20px;
}

.select_text {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.play_list_item .select_sub_text {
  color: #ff5360;
}

.select_item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.select_item:hover:after {
  content: 'X';
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 30px;
  background-color: rgba(255, 83, 96, 0.3);
}

.select_item.play_list_item:hover:after {
  content: '플레이리스트에 담긴 영상은\A리셋으로 초기화하실 수 있으십니다.';
  font-size: 8px;
  white-space: pre;
  text-align: center;
  line-height: 1.2;
}

.select_item + .select_item {
  margin-top: 20px;
}

.select_img_box {
  width: 80px;
  height: 45px;
  margin-right: 15px;
}

.select_img {
  width: 80px;
  height: 45px;
  border-radius: 4px;
}

.select_sub_text_box {
  overflow: hidden;
}

.select_sub_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.bottom_select_inner {
  // position: absolute;
  // left: 0;
  // bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #fff;
}

.select_number_box {
  padding: 25px 30px;
  box-sizing: border-box;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_number_text {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.selecet_count_number {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #969696;
}

.selecet_count_number_b {
  font-weight: bold;
  color: #ff5360;
}

.select_keep_box {
  padding: 30px;
  box-sizing: border-box;
}

.select_keep_text_box {
  margin-bottom: 20px;
}

.select_keep_button {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 100%;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.select_keep_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
}

// 전체

.inner {
  width: calc(100% - 300px);
  padding-top: 71.5px;
  box-sizing: border-box;
}

.inner_box {
  background-color: #f6f6f6;
  padding: 30px;
  box-sizing: border-box;
  height: 100%;
}

// 추천 콘텐츠

.suggestion_inner {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
}

// 리스트 없을 때

.empty_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #101e32;
  margin-bottom: 20px;
}

.empty_text {
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #101e32;
}

// 내용물 있을때

.suggestion_session {
  position: relative;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
}

.suggestion_title_box {
  margin-bottom: 20px;
}

.suggestion_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #101e32;
}

.overflow_inner {
  width: 100%;
  display: flex;
  gap: 0 20px;
  overflow-x: scroll;

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.suggestion_box {
  width: 286px;
  cursor: pointer;
}

.suggestion_img_box {
  width: 100%;
  height: 160px;
  border-radius: 10px;
  position: relative;
}

.suggestion_img,
.active_suggestion_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid transparent;
}

.active_suggestion_img {
  border: 3px solid #ff5360;
}

.detail_img_box,
.basket_img_box,
.active_basket_img_box {
  cursor: pointer;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #000;
  backdrop-filter: blur(15px);
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 56px;
}

.basket_img_box {
  right: 10px;
}

.active_basket_img_box {
  right: 10px;
  background-color: rgba($color: #ff5360, $alpha: 0.8);
}

.active_basket_img_box img {
  width: 24px;
}

.quality_box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 11.5px;
  width: 40px;
  padding: 4px 0;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.quality_text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.suggestion_info_inner {
  margin-top: 15px;
}

.suggestion_info_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.suggestion_info_title_box {
  width: calc(100% - 50px);
}

.suggestion_info_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2e384b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestion_info_name {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestion_info_img_box {
  cursor: pointer;
}

.suggestion_payment_box {
  margin-top: 10px;
}

.suggestion_payment {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #ff5360;
}

.mobile_footer_inner {
  display: none;
}

.select_keep_text_box {
  margin-bottom: 20px;
}

.select_keep_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
}

// 추가
.media_left_button_box,
.media_right_button_box {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
  border-radius: 50%;
  background-color: #fff;
}

.media_right_button_box {
  left: initial;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .side_container {
    display: none;
  }

  .inner {
    width: 100%;
    padding-top: 71.5px;
    box-sizing: border-box;
  }

  .inner_box {
    padding: 20px;
  }

  .overflow_inner {
    display: flex;
    overflow-x: initial;
    flex-wrap: wrap;
  }

  .suggestion_box {
    width: calc((100% - 46px) / 3);
    margin-bottom: 30px;
  }

  .suggestion_img_box {
    height: 180px;
  }

  // 모바일 부분

  .mobile_footer_inner {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
  }

  .mobile_footer_box {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .mobile_footer_header_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    box-sizing: border-box;
    width: 100%;
    background-color: #ff5360;
  }

  .mobile_footer_select_box {
    position: absolute;
    border-radius: 14px;
    background-color: #2e384b;
    padding: 8px 12px;
    box-sizing: border-box;
    top: -14px;
    right: 20px;
  }

  .mobile_footer_select {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_footer_select_b {
    font-weight: bold;
  }

  .mobile_footer_header_img_box {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .mobile_footer_header_title {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_footer_menu_inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: inset 0 1px 0 0 #dfdfdf;
    background-color: #fff;
  }

  .menu_list_item_box {
    padding: 10px;
    flex-direction: column;
  }

  .list_text {
    margin-left: 0;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .active .menu_list_item_box {
    background-color: #ffffff;
  }

  .active .list_text {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5360;
  }

  //누르면 나오는 메뉴

  .mobile_hide_select_box {
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    z-index: -1;
    animation: close 0.5s;
  }

  @keyframes close {
    from {
      top: -320px;
    }

    to {
      top: 0;
    }
  }

  .active_mobile_hide_select_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    z-index: -1;
    top: -320px;
    transition: all 0.3s ease-in-out;
    animation: open 0.5s;
  }

  @keyframes open {
    from {
      top: 0;
    }

    to {
      top: -320px;
    }
  }

  .mobile_hide_button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
  }

  .mobile_hide_text_box {
    margin-bottom: 24px;
  }

  .mobile_hide_text {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .mobile_hide_menu_item_inner {
    height: 230px;
    overflow-y: scroll;
  }

  .mobile_hide_menu_item_box {
    padding: 12px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .btn_remove_play {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 24px;
    height: 24px;
    font-weight: bold;
    background-color: #101e32;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .mobile_hide_img_box {
    margin-right: 15px;
  }

  .mobile_hide_img {
    border-radius: 4px;
    width: 80px;
    height: 45px;
  }

  .mobile_hide_menu_item_text_box {
    overflow: hidden;
  }

  .play_list_item .mobile_hide_menu_item_text {
    color: #ff5360;
  }

  .mobile_hide_menu_item_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #2e384b;
  }

  .mobile_hide_menu_item_button_box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_hide_menu_item_button {
    width: 100%;
    border: 0;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #fff;
    padding: 16px 0;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #101e32;
  }
  .media_left_button_box,
  .media_right_button_box {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .overflow_inner {
    gap: 0;
  }

  .suggestion_box {
    width: calc(100% - 6px);
  }

  .suggestion_img_box {
    height: 220px;
  }
}
