.sub_container {
    padding: 71.5px 20px 0;
}

.inner {
    padding: 80px 0 80px;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

.back_box {
    cursor: pointer;
    position: absolute;
    top: 80px;
    left: -50px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.back_text_box {
    margin-left: 10px;
}

.back_text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #56677e;
}

.inner_box {
    margin-top: 63px;
}

.header_inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.title {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #101e32;
}

.change_box {
    cursor: pointer;
    text-decoration: none;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    padding: 20px 25px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
}

.change_text {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #56677e;
    transition: all 0.3s ease-in-out;
}

.change_box:hover {
    border: solid 1px #ff5360;
}

.change_box:hover .change_text {
    color: #ff5360;
}

.text_box {
    margin-bottom: 80px;
}

.text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #565656;
}

/* 캘린더 */


/* 캘린더 해더 */

.calendar_header_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 10px 10px 0 0;
    border: solid 1px #efefef;
}

.calendar_header {
    margin: 0 73.5px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #101e32;
}

.calendar_header_button_box {
    cursor: pointer;
}

.calendar_days_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar_days {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #56677e;
    width: calc(100% / 7);
    padding: 10px;
    box-sizing: border-box;
}

/* 캘린더 바디 */

.calendar_body_inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.calendar_body_box {
    border: solid 1px #efefef;
    width: calc(100% / 7);
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 5.45;
    letter-spacing: normal;
    text-align: center;
    color: #2e384b;
}

/* 버튼 */

.button_box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    cursor: pointer;
    border: 0;
    width: 25%;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
    border-radius: 10px;
    background-color: #2e384b;
    padding: 20px;
    box-sizing: border-box;
}