// 공통

.category_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text,
.category_text_b {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
  margin-left: 10px;
}

.category_text_b {
  font-weight: 500;
  color: #ff5360;
}

.category_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 0;
}

// 스토어 리스트

.selected_store_inner {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #efefef;
}

.selected_header_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.store_title_box {
  display: flex;
  align-items: center;
}

.store_text,
.store_text_b {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-left: 10px;
  color: #565656;
}

.store_text_b {
  color: #ff5360;
}

.store_select_box {
  display: flex;
  align-items: center;
}

.all_button {
  width: 150px;
  margin-right: 20px;
}

.store_hide_check_box {
  display: none;
}

.store_check_box_label {
  cursor: pointer;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px;
  box-sizing: border-box;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store_check_box {
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  position: relative;
}

.store_check_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0c1522;
}

.store_check_box_checked {
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.store_hide_check_box:checked + .store_check_box_label {
  border: 1px solid #ff5360;
}

.store_hide_check_box:checked + .store_check_box_label .store_check_text {
  color: #ff5360;
}

.store_hide_check_box:checked + .store_check_box_label .store_check_box {
  background-color: #ff5360;
  border: 1px solid #ff5360;
}

.cancel_button {
  cursor: pointer;
  width: 150px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cancel_button:hover {
  border: 1px solid #ff5360;
}

.cancel_check_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

// 리스트 부분

.selected_list_inner {
  display: flex;
  gap: 20px 10px;
  flex-wrap: wrap;
}

.selected_list_box,
.not_selected_list_box {
  cursor: pointer;
  width: calc((100% - 40px) / 4);
  display: flex;
  flex-direction: column;
}

.not_selected_list_box {
  cursor: initial;
  opacity: 0.5;
}

.selected_store_item {
  position: relative;
  z-index: -1;
  width: calc(100% - 6px);
  height: calc(150px - 6px);
  border: solid 3px transparent;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.active_selected_store_item {
  position: relative;
  z-index: -1;
  width: calc(100% - 6px);
  height: calc(120px - 6px);
  border: solid 3px #ff5360;
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.acive_selected_img {
  position: absolute;
  top: 10px;
  right: 10px;
}

.selected_store_info_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selected_store_info_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
  margin-bottom: 15px;
}

.selected_store_info_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.selected_store_info_avg_box {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_store_info_avg_box {
  display: flex;
  align-items: center;
}

.selected_store_info_avg_text,
.select_store_info_avg_text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

.select_store_info_avg_text {
  margin-right: 10px;
}

.selected_store_info_avg_text_b {
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

.selected_store_info_avg_text_hr {
  width: 1px;
  height: 15px;
  background-color: #dfdfdf;
  margin: 0 20px;
}

.selected_store_info_text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #969696;
}

.selected_store_info_text_box {
  display: flex;
  align-items: center;
}

.selected_store_info_rest {
  width: 2px;
  height: 2px;
  margin: 0 8px;
  background-color: #969696;
}

// 선택 리스트 없을 경우

.selected_list_text_box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected_list_text {
  text-align: center;
  color: #dcdcdc;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  // 공통

  .category_inner {
    margin: 0 auto 50px;
  }

  .category_title_box {
    margin-bottom: 30px;
  }

  .category_title {
    font-size: 24px;
    line-height: 1.7;
  }

  .category_text,
  .category_text_b {
    display: block;
    margin-left: 0px;
  }

  .category_text_b {
    font-weight: 500;
    color: #ff5360;
  }

  // 스토어 리스트

  .selected_store_inner {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    border: solid 1px #efefef;
  }

  .selected_header_box {
    display: flex;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .store_title_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .mobile_category_title {
    font-size: 15px;
  }

  .store_text,
  .store_text_b {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-left: 10px;
    color: #565656;
  }

  .store_text_b {
    color: #ff5360;
  }

  .store_select_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .store_check_box_label {
    cursor: pointer;
    width: 150px;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    padding: 20px;
    box-sizing: border-box;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .store_check_text {
    font-size: 15px;
  }

  .cancel_check_text {
    font-size: 15px;
  }

  // 리스트 부분

  .selected_list_inner {
    display: flex;
    gap: 20px 10px;
    flex-wrap: wrap;
  }

  .selected_list_box,
  .not_selected_list_box {
    cursor: pointer;
    width: calc((100% - 40px) / 4);
  }

  // 선택 리스트 없을 경우

  .selected_list_text_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .selected_list_text {
    text-align: center;
    color: #dcdcdc;
    font-size: 20px;
    font-weight: bold;
  }
}

@media screen and (max-width: 900px) {
  .selected_list_box,
  .not_selected_list_box {
    cursor: pointer;
    width: calc((100% - 40px) / 3);
  }
}

@media screen and (max-width: 700px) {
  .selected_list_inner {
    justify-content: space-between;
  }

  .selected_list_box,
  .not_selected_list_box {
    cursor: pointer;
    width: calc((100% - 10px) / 2);
  }

  .selected_store_item {
    width: calc(100% - 6px);
    height: calc(150px - 6px);
  }

  .active_selected_store_item {
    width: calc(100% - 6px);
    height: calc(150px - 6px);
  }
}

@media screen and (max-width: 500px) {
  .selected_store_info_avg_text_hr {
    margin: 0;
  }

  .store_select_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .all_button {
    width: 50%;
  }

  .store_check_box_label {
    padding: 10px;
  }

  .cancel_button {
    padding: 10px;
    width: 50%;
  }
}

@media screen and (max-width: 460px) {
  .selected_list_box,
  .not_selected_list_box {
    cursor: pointer;
    width: 100%;
  }
}
