// 공통

.category_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text_b {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
  margin-left: 10px;
}

.category_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 0;
}

// 희망 스토어

.store_category {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.user_info_week_button,
.active_user_info_week_button {
  cursor: pointer;
  border-radius: 10px;
  background-color: #efefef;
  padding: 15px 30px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.user_info_week_button:hover {
  background-color: #ff5360;
  color: #fff;
}

.active_user_info_week_button {
  background-color: #ff5360;
  color: #fff;
}

// 반응형

@media screen and (max-width: 1200px) {
  .category_inner {
    margin: 0 auto 50px;
  }

  .category_title_box {
    margin-bottom: 30px;
  }

  .category_title {
    font-size: 24px;
    line-height: 1.7;
  }

  .category_text_b {
    display: block;
    font-size: 14px;
    margin-left: 0px;
  }

  // 희망 스토어

  .store_category {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}
