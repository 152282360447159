// 전체

.sub_container {
  position: relative;
}

.container {
  width: 100%;
  height: 100%;
  padding: 71.5px 20px 0;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

// 헤더 제목 박스

.inner_header {
  padding: 55px 0;
  box-sizing: border-box;
  background-color: #fff;
}

.title_box {
  display: flex;
  align-items: center;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #101e32;
}

.basket_number {
  margin-left: 15px;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #ff5360;
}

// 전체 체크박스

.sub_header_box {
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

// 왼쪽 전체 선택 박스

.all_input_box {
  display: flex;
  align-items: center;
}

.hide_check_box {
  display: none;
}

.checkbox_label {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #2e384b;
  display: flex;
  align-items: center;
}

.check_box {
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
}

.check_box_checked {
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hide_check_box.checked + .checkbox_label .check_box {
  background-color: #32c4ff;
  border: 1px solid #32c4ff;
}

// 오른쪽 선택 삭제 박스

.select_delete_button {
  cursor: pointer;
  outline: none;
  padding: 12px 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #2e384b;
}

.select_delete_img {
  margin-right: 10px;
}

// 장바구니 리스트 박스

.list_container {
  background-color: #f5f5f5;
  padding: 40px 20px;
  box-sizing: border-box;
}

.list_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;
  box-sizing: border-box;
  height: 600px;
  overflow-y: scroll;
}

.list_inner.no_data {
  text-align: center;
  margin-top: 64px;
  color: #333;
}

.list_item_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  border-radius: 20px;
  background-color: #fff;
  padding: 0 0 0 30px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }
}

.list_item_box {
  width: 92%;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  box-sizing: border-box;
}

.artist_info_box {
  width: 83%;
  display: flex;
  align-items: center;
}

.list_img_box {
  position: relative;
  margin-left: 15px;
  width: 210px;
  height: 120px;
  border-radius: 10px;
}

.like_img {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.list_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.artist_info {
  margin-left: 30px;
}

.artist_title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #2e384b;
  margin-bottom: 10px;
}

.artist_name {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #696969;
}

.payment_box {
  width: 17%;
  box-sizing: border-box;
  border-left: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #ff5360;
}

// 삭제 버튼

.delete_button_box {
  width: 7%;
  border-left: 1px solid #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete_button {
  cursor: pointer;
}

// 총 합계

.total_payment_inner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  opacity: 0.9;
  background-color: #29b3eb;
}

.total_payment_sub_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.total_box {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_number_box {
  display: flex;
  align-items: center;
}

.payment_sub_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fff;
  margin-right: 20px;
}

.payment_text {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
}

.total_payment {
  display: flex;
  align-items: center;
}

.button {
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 70px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ff5360;
}

// 모바일

.mobile_delete_button,
.mobile_br {
  display: none;
}

.error_text {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
}

.list_sub_box {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  // 헤더 제목 박스

  .title {
    font-size: 30px;
    line-height: 1.33;
  }

  .basket_number {
    font-size: 18px;
    line-height: 1.67;
  }

  // 장바구니 리스트 박스

  .list_inner {
    height: 800px;
    padding-bottom: 150px;
  }

  .list_item_inner {
    justify-content: initial;
    flex-direction: column;
    padding: 0;
  }

  .list_item_box {
    width: 100%;
    display: flex;
    justify-content: initial;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
  }

  .artist_info_box {
    width: 100%;
    display: flex;
    align-items: initial;
    flex-direction: column;
  }

  .mobile_checkbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dfdfdf;
  }

  .mobile_delete_button {
    cursor: pointer;
    display: block;
  }

  .list_img_box {
    margin-left: 0;
    width: 330px;
    height: 220px;
    padding: 20px;
    box-sizing: border-box;
  }

  .like_img {
    top: 30px;
    right: 30px;
  }

  .list_img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .artist_info {
    margin-left: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .artist_title {
    margin-bottom: 5px;
  }

  .payment_box {
    margin-top: 20px;
    width: 100%;
    border-left: 0;
    border-top: 1px solid #efefef;
    justify-content: flex-end;
    padding: 20px;
    box-sizing: border-box;
  }

  .payment {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    color: #ff5360;
  }

  // 삭제 버튼

  .delete_button_box {
    display: none;
  }

  // 총 합계

  .total_payment_sub_inner {
    justify-content: initial;
    flex-direction: column;
  }

  .total_box {
    width: 100%;
    margin-bottom: 20px;
  }

  .select_number_box {
    display: flex;
    align-items: center;
  }

  .payment_sub_text {
    font-size: 11px;
    line-height: 2.73;
    margin-right: 10px;
  }

  .payment_text {
    font-size: 15px;
    line-height: 2;
  }

  .mobile_payment_sub_text {
    margin-right: 0;
  }

  .total_payment {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .payment_total_text {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }

  .button_box {
    width: 100%;
  }

  .button {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    font-size: 18px;
  }
}

@media screen and (max-width: 500px) {
  .list_img_box {
    width: 100%;
  }

  .error_text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.7;
  }

  .list_sub_box {
    flex-direction: column;
    align-items: flex-start;
  }

  .artist_title {
    font-size: 16px;
  }

  .artist_name {
    font-size: 12px;
  }

  .mobile_br {
    display: block;
  }
}
