// 전체

.conatiner {
  width: 100%;
  height: 100%;
  padding: 71.5px 20px 40px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 95px auto 0;
  position: relative;
}

// 뒤로가기

.back_box {
  cursor: pointer;
  position: absolute;
  top: -50px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

// 탭 버튼

.tab_inner {
  margin: 40px 0;
}

.tab_button_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
  position: relative;
}

.tab_vertical {
  position: absolute;
  width: 1px;
  height: 34px;
  background-color: #ddd;
  left: 67%;
  transform: translateX(-50%);
}

.tab_button,
.active_tab_button {
  cursor: pointer;
  outline: none;
  border: 0;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #d1d5db;
}

.active_tab_button {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #101e32;
}

.tab_content_box {
  margin-top: 55px;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: -10px;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    width: 100%;
    max-width: 1200px;
    margin: 95px auto 0;
  }

  // 탭 버튼

  .tab_inner {
    margin: 40px 0;
  }

  .tab_button_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    position: relative;
  }

  .tab_vertical {
    position: absolute;
    width: 1px;
    height: 23px;
    background-color: #ddd;
    left: 64%;
    transform: translateX(-50%);
  }

  .tab_button,
  .active_tab_button {
    font-size: 18px;
    line-height: 3.33;
  }

  .active_tab_button {
    font-size: 20px;
    font-weight: bold;
    line-height: 3;
  }

  .tab_content_box {
    margin-top: 30px;
  }
}
