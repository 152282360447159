.sub_container {
  padding: 71.5px 20px 100px;
  box-sizing: border-box;
}

// 뒤로가기

.back_inner {
  max-width: 1200px;
  position: relative;
  margin: 60px auto 0;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.back_box {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: -50px;
}

.back_img_box {
  margin-right: 10px;
  cursor: pointer;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

// 사인하는 박스

.inner {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  // min-height: 100vh;
  position: relative;
  background-color: #f6f6f6;
  margin-bottom: 50px;
}

.sign_box {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  margin-bottom: 50px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.box_pdf {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.box_pdf canvas {
  box-sizing: border-box;
  border: 1px solid #000;
}

.sign_move_box {
  width: 210px;
  height: 90px;
  border-radius: 10px;
  border: solid 4px #ff5360;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 80px;
  right: 80px;
  cursor: move;
}

.sign_img {
  width: 100%;
  height: 100%;
}

.sign_confirm_box {
  width: 210px;
  height: 90px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 80px;
  right: 80px;
  border: solid 4px transparent;
}

.sign_move {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: -20px;
  right: -20px;
}

.img_sign {
  width: 100%;
  height: 100%;
}

// check box

.check_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.check_box {
  display: none;
}

input[id='agree']:checked:checked ~ .check_box_label {
  background-color: #32c4ff;
  border-radius: 5px;
  border: solid 1px #32c4ff;
}

.check_box_label::after {
  content: '';
  position: absolute;
  display: none;
}

input[id='agree']:checked ~ .check_box_label:after {
  display: block;
}

input[id='agree']:checked + label::after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[id='agree'] + label {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 0;
  border-radius: 5px;
  border: solid 1px #efefef;
  position: relative;
}

.check_label {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
  cursor: pointer;
}

.check_label_b {
  font-weight: bold;
  color: #ff5360;
  cursor: pointer;
}

// 버튼

.button_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_box {
  padding-right: 25px;
  box-sizing: border-box;
}

.button_box:last-child {
  padding-right: 0;
  padding-left: 25px;
}

.button {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px 50px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.button:hover {
  border: solid 1px #2e384b;
  background-color: #2e384b;
  color: #ffffff;
}

.mobile_br {
  display: none;
}

/* 버튼 */

.confirm_button_box {
  transition: all 0.3s ease-in-out;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
}

.prev_button_box {
  margin-right: 50px;
}

.prev_button,
.next_button {
  display: inline-block;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  padding: 20px 55px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #2e384b;
  background-color: #2e384b;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  cursor: pointer;
  opacity: 0.5;
}

.next_button.active {
  opacity: 1;
}

.prev_button {
  border: solid 1px #dfdfdf;
  background-color: #fff;
  color: #d1d5db;
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .back_inner {
    margin: 15px auto 0;
    padding-bottom: 60px;
  }

  .back_box {
    left: 0px;
  }

  .back_text {
    font-size: 15px;
  }

  // 사인하는 박스

  .sign_box {
    padding: 20px;
  }

  .sign_move_box {
    width: 120px;
    height: 50px;
    border: solid 3px #ff5360;
  }

  .sign_confirm_box {
    width: 120px;
    height: 50px;
    border: solid 3px transparent;
  }

  .sign_move {
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
  }

  // check box

  .check_inner {
    align-items: initial;
  }

  .check_label {
    font-size: 14px;
    line-height: 1.71;
  }

  // 버튼

  .button_box {
    width: 50%;
    padding-right: 10px;
  }

  .button_box:last-child {
    padding-right: 0;
    padding-left: 10px;
  }

  .button {
    width: 100%;
    padding: 20px 0;
    font-size: 15px;
  }

  .sub_button_box {
    margin-top: 0;
    margin-bottom: 100px;
  }

  .sub_button {
    width: 100%;
    padding: 20px 0;
    font-size: 14px;
  }

  .confirm_button_box {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
  }

  .prev_button_box,
  .next_button_box {
    margin-right: 0;
    width: 50%;
  }

  .next_button,
  .prev_button {
    display: block;
    width: 100%;
    padding: 20px 0;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 2;
  }

  .mobile_br {
    display: block;
  }
}
