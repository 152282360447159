// 공통

.category_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text,
.category_text_b {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
  margin-left: 10px;
}

.category_text_b {
  font-weight: 500;
  color: #ff5360;
}

.category_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 0;
}

// 광고 기간 선택

.advertisement_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.advertisement_inner {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  width: 70%;
}

.advertisement_box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.advertisement_text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: normal;
  color: #101e32;
}

.advertisement_select {
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

.advertisement_b {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0c1522;
  margin: 30px 20px 0;
}

.advertisement_select {
  position: relative;
  &::-webkit-clear-button,
  &::-webkit-inner-spin-button {
    display: none;
  }
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: transparent;
    z-index: 1;
    cursor: pointer;
  }
}
input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  z-index: 1;
}

// 검색 버튼

.button_box {
  margin-top: 30px;
}

.button {
  cursor: pointer;
  outline: none;
  padding: 20px 30px;
  box-sizing: border-box;
  border: 1px solid #ff5360;
  border-radius: 10px;
  background-color: #ff5360;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  // 공통

  .category_inner {
    margin: 0 auto 50px;
  }

  .category_title_box {
    margin-bottom: 30px;
  }

  .category_title {
    font-size: 24px;
    line-height: 1.7;
  }

  .category_text,
  .category_text_b {
    display: block;
    margin-left: 0px;
  }

  // 광고 기간 선택

  .advertisement_container {
    flex-direction: column;
  }

  .advertisement_inner {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    width: 100%;
  }

  .advertisement_box {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    &:last-child {
      margin-top: 10px;
    }
  }

  .advertisement_box:first-child {
    margin-top: 20px;
  }

  .advertisement_text {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.31;
    letter-spacing: normal;
    color: #101e32;
  }

  .advertisement_b {
    display: none;
  }

  .calendar_box {
    position: relative;
  }

  // 검색 버튼

  .button_box {
    width: 100%;
  }

  .button {
    width: 100%;
    padding: 20px 0;
  }
}
