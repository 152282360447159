// 전체

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 71.5px 20px 80px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  margin-top: 70px;
}

// 타이틀

.title_box {
  display: flex;
  align-items: center;
  margin-bottom: 100px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.text {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

// 업로드 버튼

.upload_inner {
  margin: 0 auto;
  width: 80%;
  padding: 70px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #dfdfdf;
  border-radius: 20px;
  margin-bottom: 150px;
  flex-direction: column;
}

.upload_prev_box {
  cursor: pointer;
  width: 500px;
  height: 280px;
  border-radius: 20px;
  background-color: #56677e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload_file_input {
  display: none;
}

// 영상 업로드 후

.upload_after_box {
  position: relative;
  background-color: transparent;
  width: 500px;
  height: 280px;
  border: 1px solid #dfdfdf;
  border-radius: 20px;
}

.upload_after_img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.file_upload_delete_img {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
}

// 버튼 박스

.upload_button_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.registration_button,
.payment_button {
  cursor: pointer;
  padding: 20px 80px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2e384b;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.payment_button {
  border-radius: 10px;
  background-color: #ff5360;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  // 타이틀 박스

  .title_box {
    display: flex;
    align-items: initial;
    flex-direction: column;
    margin-bottom: 100px;
  }

  .title {
    font-size: 30px;
    margin-bottom: 10px;
  }

  .text {
    margin-left: 0;
    font-size: 15px;
  }

  // 업로드 박스

  .upload_inner {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    border-radius: 0;
    margin-bottom: 100px;
  }

  .upload_prev_box,
  .upload_after_box {
    width: 100%;
    height: 320px;
  }

  // 버튼 박스

  .registration_button,
  .payment_button {
    width: 50%;
    margin-right: 10px;
    padding: 20px 0;
    text-align: center;
  }

  .payment_button {
    margin-right: 0;
    margin-left: 10px;
  }
}

@media screen and (max-width: 360px) {
  .upload_prev_box,
  .upload_after_box {
    width: 100%;
    height: 200px;
  }
}

// 추가

.danger_text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}
