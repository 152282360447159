.sub_container {
  transition: all 0.3s ease-in-out;
  padding: 50px 20px 150px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.inner_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 150px;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 80px;
}

.title {
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.text {
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.process_inner {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.process_img,
.process_arrow {
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 50px 20px 80px;
  }

  .inner_box {
    margin-bottom: 80px;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
  }

  .process_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .process_arrow_box {
    transition: all 0.3s ease-in-out;
    margin: 30px 0;
  }

  .process_arrow {
    transform: rotate(90deg);
  }
}

@media screen and (max-width: 400px) {
  .mobile_br {
    display: block;
  }
}
