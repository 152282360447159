// 전체

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 71.5px 20px 50px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  margin-top: 60px;
}

// 타이틀

.title_box {
  display: flex;
  align-items: center;
  margin-bottom: 65px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.text {
  margin-left: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
}

// 테이블

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead {
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #f9f9f9;
}

.td {
  padding: 12px 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
  text-align: center;
}

.tbody_td {
  padding: 12px 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
  box-shadow: inset 0 -1px 0 0 #efefef;
  text-align: center;
}

.tbody_tr_img {
  width: 100px;
  height: 56px;
  border-radius: 4px;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 30px 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

// 결제 방식

.selection_inner {
  margin-bottom: 80px;
}

.selection_title_box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.selection_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #101e32;
}

.selection_text {
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #565656;
}

// 결제 방식 선택

.selection_way_inner {
  margin-bottom: 80px;
}

.selection_way_box {
  display: flex;
  align-items: center;
}

.way_button_box {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
    margin-left: 10px;
  }
}

.way_button_img {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.way_button,
.active_way_button {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 20px 65px 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0c1522;
}

.active_way_button {
  background-color: #ff5360;
  color: #fff;
}

// 세금계산서

.tax_inner {
  margin-bottom: 30px;
}

.tax_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #101e32;
  display: flex;
  align-items: center;
}

.tax_dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #101e32;
  border-radius: 50%;
  margin: 0 5px;
}

.tax_application_inner {
  display: flex;
  align-items: center;
}

.tax_application_box {
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
    margin-left: 30px;
  }
}

.hidden_radio_input {
  display: none;
}

.radio_label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio_cricle {
  margin-right: 15px;
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 50%;
}

.radio_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.hidden_radio_input:checked + .radio_label .radio_text {
  color: #65bcfd;
}

.hidden_radio_input:checked + .radio_label .radio_cricle {
  width: 14px;
  height: 14px;
  border: 5px solid #65bcfd;
}

// 세금 계산서 인풋창

.tax_input_inner {
  margin-top: 30px;
}

.input_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.input_box {
  width: calc((100% - 20px) / 2);
}

.input_number_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: normal;
  color: #101e32;
}

.input_text,
.upload_input,
.input_number {
  outline: none;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  color: #000;
  &::placeholder {
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #969696;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.input_number {
  width: calc(100% - 140px);
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.upload_input {
  width: calc(100% - 100px);
}

.certification_button {
  cursor: pointer;
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #d1d5db;
  background-color: #d1d5db;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #ff5360;
    background-color: #ff5360;
  }
}

.certification_button.active{
  border: 1px solid #2e384b;
  background-color: #2e384b;
}

.certification_button.success{
  border: 1px solid #ff5360;
  background-color: #ff5360;
}

.fail_box {
  display: flex;
  align-items: center;
}

.fail_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.fail_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.upload_button_box,
.upload_img_box {
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid 1px #d1d5db;
}

.upload_img_box {
  cursor: initial;
  position: relative;
}

.upload_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.delete_button {
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 3px;
  right: 3px;
}

// 총 결제 금액

.total_container {
  width: 100%;
  background-color: rgba(41, 179, 235, 0.9);
}

.total_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media_total_inner {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media_total_sub_inner {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media_total_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.media_sub_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fff;
}

.media_text,
.media_payment_text {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
}

.media_payment_text {
  margin-left: 20px;
}

.media_total_payment_inner {
  display: flex;
  align-items: center;
}

.media_total_payment_box {
  display: flex;
  align-items: center;
}

.payment_button {
  cursor: pointer;
  outline: none;
  margin-left: 50px;
  border: 0;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 70px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

// 모바일 디스플레이 none
.mobile_tbody_td {
  display: none;
}

@media screen and (max-width: 1024px) {
  // 전체

  .container {
    padding: 71.5px 20px 0;
  }

  .inner {
    margin-top: 50px;
  }

  // 타이틀

  .title_box {
    display: flex;
    align-items: initial;
    flex-direction: column;
    margin-bottom: 45px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
  }

  .text {
    margin-left: 0;
    font-size: 15px;
    line-height: 2;
  }

  // 테이블

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .thead {
    display: none;
  }

  .tbody_tr {
    box-shadow: inset 0 -1px 0 0 #efefef;
    display: flex;
    flex-wrap: wrap;
  }

  .tbody_td {
    padding: 20px 10px;
    line-height: 1.83;
    font-size: 12px;
    box-shadow: initial;
    text-align: initial;
    display: flex;
    flex-direction: column;
    &:first-child {
      padding: 10px;
      width: 100%;
    }
  }

  .tbody_tr_img {
    width: 180px;
    height: 110px;
    border-radius: 4px;
  }

  .mobile_tbody_td {
    display: block;
  }

  /* 페이지네이션 */

  .pagination_box {
    margin: 30px 0 50px;
  }

  .pagination_number_inner {
    margin: 0 15px;
  }

  .pagination_number_box {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  .pagination_number {
    font-size: 13px;
  }

  .active_pagination_number {
    font-size: 13px;
  }

  // 결제 방식

  .selection_inner {
    margin-bottom: 50px;
  }

  .selection_title_box {
    flex-direction: column;
    align-items: initial;
  }

  .selection_text {
    margin-left: 0;
  }

  // 결제 방식 선택

  .selection_way_inner {
    margin-bottom: 50px;
  }

  .selection_way_box {
    align-items: center;
    flex-direction: column;
  }

  .way_button_box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .way_button_img {
    margin-right: 10px;
  }

  .way_button,
  .active_way_button {
    cursor: pointer;
    width: 100%;
    padding: 15px 0;
  }

  .active_way_button {
    background-color: #ff5360;
    color: #fff;
  }

  // 세금계산서

  .tax_inner {
    margin-bottom: 30px;
  }

  // 세금 계산서 인풋창

  .tax_input_inner {
    margin-top: 30px;
  }

  .input_inner {
    display: flex;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 0;
  }

  .input_box {
    width: 100%;
    margin-bottom: 30px;
  }

  // 총 결제 금액

  .total_inner {
    padding: 15px 20px;
    flex-direction: column;
  }

  .media_total_inner {
    width: 100%;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
  }

  .media_total_sub_inner {
    width: 100%;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
  }

  .media_total_box {
    display: flex;
    justify-content: initial;
    flex-direction: initial;
    margin-bottom: 20px;
  }

  .media_sub_text {
    font-size: 11px;
  }

  .media_text,
  .media_payment_text {
    font-size: 15px;
    margin-left: 20px;
  }

  .media_total_payment_inner {
    width: 100%;
    align-items: initial;
    flex-direction: column;
  }

  .media_total_payment_box {
    width: 100%;
    justify-content: flex-end;
  }

  .payment_button {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
    padding: 10px 0;
    font-size: 18px;
  }

  .certification_button {
    padding: 25px 40px;
  }

  .input_text,
  .upload_input,
  .input_number {
    padding: 25px 20px;
    &::placeholder {
      font-size: 14px;
    }
  }
}
