//전체

.container {
  padding: 71.5px 20px 50px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 100px auto 0;
}

// 상단 타이틀

.title_box {
  margin-bottom: 50px;
}

.text_box {
  margin-bottom: 40px;
}

.text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #0c1522;
}

// 옥외광고 박스

.advertiser_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.advertiser_box {
  width: 290px;
  padding: 66px 0;
  box-sizing: border-box;
  border-radius: 80px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.15);
  background-image: linear-gradient(to bottom, #fff, #f6f6f6 76%);
}

.advertiser_text {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #0c1522;
}

.outdoor_box {
  width: 464px;
  padding: 50px 0;
  box-sizing: border-box;
  border-radius: 80px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.16);
  background-color: #ff5360;
}

.outdoor_text {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

// 광고 송출 박스

.send_out_inner {
  display: flex;
  align-items: center;
  justify-content: baseline;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
}

.send_out_text_box {
  margin: 60px 0;
}

.send_out_img_box {
  position: absolute;
  z-index: -1;
}

.send_out_item_inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px 0;
}

.send_out_item_box {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  border: dashed 2px #e2e6eb;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.send_out_item_text {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101e32;
}

.next_img_box {
  width: 100%;
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 이미지 비교 사진

.img_compare_inner {
  display: flex;
  &:hover .beam_img_box {
    width: 66.6%;
  }
  &:hover .initial_img_box {
    width: 33.3%;
  }
  &:hover .initial_img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

.beam_img_box,
.initial_img_box {
  position: relative;
  width: 50%;
  height: 1080px;
  transition: all 0.3s ease-in-out;
}

.compare_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
  color: #ff5360;
}

.beam_img,
.initial_img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

// 택스트 비교

.sub_container {
  padding: 0 20px;
  box-sizing: border-box;
}

.sub_inner {
  margin: 150px auto;
  width: 100%;
  max-width: 1200px;
}

.compare_text_inner {
  width: 100%;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.compare_initial_box {
  width: 48%;
  height: 270px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  background-image: linear-gradient(to right, #fff, #f5f7f8);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.initial_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
  margin-bottom: 20px;
}

.initial_text {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #9e9e9e;
}

.compare_beam_box {
  width: calc(48% - 4px);
  height: calc(270px - 4px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 20px;
  border: solid 3px #ff5360;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    width: 51.5%;
    height: 290px;
    box-shadow: 0 3px 30px 0 rgba(255, 83, 96, 0.3);
    background-color: #ff5360;
  }
  &:hover .beam_title {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  &:hover .beam_text {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.beam_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
  margin-bottom: 20px;
}

.beam_text {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #101e32;
}

// 방문 프로세스 박스

.process_inner {
  margin-top: 150px;
}

.process_box {
  margin-bottom: 100px;
}

.process_title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0c1522;
  margin-bottom: 40px;
}

.process_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #696969;
}

// 방문 프로세스 플로우 박스

.process_item_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 100px;
}

.process_item_box {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.process_item_number {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
  margin: 10px 0 25px;
}

.process_item_text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

// 마지막 푸터

.service_inner {
  box-sizing: border-box;
  background-color: #f9fafb;
  margin-bottom: 50px;
  padding: 120px 0;
  box-sizing: border-box;
}

.service_box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.service_title_box {
  margin-bottom: 50px;
}

.service_title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

.service_button {
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 22px;
  padding: 12px 50px 12px 30px;
  box-sizing: border-box;
  background-color: #ff5360;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  position: relative;
}

.service_button:hover {
  padding-right: 75px;
}

.service_button_arrow {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  right: 18%;
}

.service_button_arrow::before,
.service_button_arrow::after {
  content: '';
  position: absolute;
}

.service_button_arrow::before {
  width: 6px;
  height: 6px;
  border: 2px solid #fff;
  border-right: 0px;
  border-bottom: 0px;
  transform: rotate(135deg);
}

.service_button_arrow_hr {
  background-color: #fff;
  width: 15px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: -8px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  z-index: 0;
}

.service_button:hover .service_button_arrow_hr {
  left: -18px;
  width: 25px;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  //전체

  .container {
    overflow: hidden;
  }

  .inner {
    margin: 60px auto 0;
  }

  // 상단 타이틀

  .title_box {
    margin-bottom: 60px;
  }

  .text_box {
    margin-bottom: 30px;
  }

  .text {
    font-size: 15px;
    font-weight: 500;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.46;
  }

  .mobile_br {
    display: block;
  }

  // 옥외광고 박스

  .advertiser_box {
    width: 200px;
    padding: 45px 0;
  }

  .advertiser_text {
    font-size: 18px;
  }

  .outdoor_box {
    width: 320px;
    padding: 35px 0;
  }

  .outdoor_text {
    font-size: 18px;
    line-height: 1.28;
  }

  // 광고 송출 박스

  .send_out_inner {
    margin-bottom: 60px;
  }

  .send_out_text_box {
    margin: 60px 0 40px;
  }

  .send_out_item_inner {
    width: 100%;
    justify-content: initial;
    gap: 20px 30px;
  }

  .send_out_item_box {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }

  .send_out_item_text {
    margin-top: 13px;
    font-size: 15px;
  }

  .next_img_box {
    display: none;
  }

  // 이미지 비교 사진

  .img_compare_inner {
    flex-direction: column;
    &:hover .beam_img_box {
      width: 100%;
    }
    &:hover .initial_img_box {
      width: 100%;
    }
  }

  .beam_img_box,
  .initial_img_box {
    width: 100%;
    height: 360px;
  }

  .compare_text {
    font-size: 20px;
  }

  .initial_img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  // 택스트 비교

  .sub_inner {
    margin: 50px auto 100px;
  }

  .compare_text_inner {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .compare_initial_box {
    width: 50%;
    height: 290px;
  }

  .initial_title {
    font-size: 18px;
  }

  .initial_text {
    font-size: 20px;
    line-height: 1.5;
  }

  .compare_beam_box {
    width: 51.5%;
    height: calc(290px - 4px);
    height: 290px;
    box-shadow: 0 3px 30px 0 rgba(255, 83, 96, 0.3);
    background-color: #ff5360;
    &:hover {
      width: 51.5%;
      height: 290px;
      box-shadow: 0 3px 30px 0 rgba(255, 83, 96, 0.3);
      background-color: #ff5360;
    }
    &:hover .beam_title {
      font-size: 18px;
      color: #fff;
    }

    &:hover .beam_text {
      font-size: 20px;
      line-height: 1.5;
    }
  }

  .beam_title {
    font-size: 18px;
    color: #fff;
  }

  .beam_text {
    font-size: 20px;
    line-height: 1.5;
    color: #fff;
  }

  // 방문 프로세스 박스

  .process_inner {
    margin-top: 100px;
  }

  .process_box {
    margin-bottom: 60px;
  }

  .process_title {
    font-size: 30px;
    line-height: 1.33;
    text-align: center;
  }

  .process_text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
  }

  // 방문 프로세스 플로우 박스

  .process_item_inner {
    flex-direction: column;
    margin-top: 60px;
  }

  .servie_arrow {
    margin: 30px 0;
    transform: rotate(90deg);
  }

  // 마지막 푸터

  .service_inner {
    margin-bottom: 20px;
    border-radius: 20px;
    padding: 80px 0;
  }

  .service_title_box {
    margin-bottom: 30px;
  }

  .service_title {
    font-size: 24px;
    line-height: 1.42;
  }
}

@media screen and (max-width: 500px) {
  .send_out_item_inner {
    justify-content: center;
    gap: 20px 20px;
  }

  .send_out_item_box {
    width: 125px;
    height: 125px;
    border-radius: 50%;
  }

  .compare_initial_box {
    width: 100%;
    height: 270px;
  }

  .compare_beam_box {
    width: 100%;
    height: 270px;
    box-shadow: 0 3px 30px 0 rgba(255, 83, 96, 0.3);
    background-color: #ff5360;
    &:hover {
      width: 100%;
      height: 270px;
      box-shadow: 0 3px 30px 0 rgba(255, 83, 96, 0.3);
      background-color: #ff5360;
    }
  }
}
