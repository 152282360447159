/* content */

* {
  transition: all 0.3s ease-in-out;
}

.title_inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 41px;
  margin-bottom: 50px;
}

.title_box,
.active {
  position: relative;
  cursor: pointer;
  width: 200px;
}

.led_title_box,
.led_active {
  cursor: pointer;
  box-sizing: border-box;
  width: 200px;
  padding-left: 40px;
}

.led_active .title {
  cursor: pointer;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
  line-height: 1;
}

.active .title {
  cursor: pointer;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1;
  color: #101e32;
}

.title {
  cursor: pointer;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d1d5db;
  line-height: 1.2;
}

.hr {
  width: 1px;
  height: 36px;
  margin: 0;
  background-color: #ddd;
  position: absolute;
  top: 0;
  right: 15px;
}

.main_img_box {
  margin-bottom: 50px;
}

.main_img {
  width: 100%;
}

.list_img_inner,
.sub_list_img_inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.list_img_box {
  width: 31%;
}

.list_img {
  width: 100%;
}

.list_title_inner {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.list_title_box {
  margin-left: 10px;
}

.list_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.list_title.black {
  color: #2e384b;
}

.list_img_item_box {
  width: 22%;
}

.list_img_item_text_box {
  margin-top: 15px;
}

.list_img_item_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

/* 아래 컨텐츠 */

.caution_inner {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  margin-bottom: 40px;
  align-items: center;
}

.caution_text_box {
  margin-left: 10px;
}

.caution_text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.caution_text:last-child {
  line-height: 1.56;
}

@media screen and (max-width: 1024px) {
  .title_inner {
    margin-bottom: 30px;
  }

  .title_box,
  .active {
    width: 100px;
  }

  .led_title_box,
  .led_active {
    width: 100px;
    padding-left: 40px;
  }

  .hr {
    height: 23px;
    right: -5px;
  }

  .active .title,
  .led_active .title {
    font-size: 20px;
    line-height: 1;
  }

  .title {
    font-size: 18px;
    line-height: 1.2;
  }

  .main_img_box {
    margin-bottom: 10px;
  }

  .main_img {
    width: 100%;
  }

  .list_img_inner {
    margin-bottom: 40px;
  }

  .list_img_box {
    width: 32%;
  }

  .list_img {
    width: 100%;
  }

  .list_title {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .sub_list_img_inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
  }

  .list_img_item_box {
    width: 50%;
    padding-right: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }

  .list_img_item_box:nth-child(2),
  .list_img_item_box:nth-child(4) {
    padding-left: 10px;
    padding-right: 0;
  }

  .list_img {
    width: 100%;
  }

  .list_img_item_text_box {
    margin-top: 10px;
  }

  .list_img_item_text {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  /* 아래 컨텐츠 */

  .caution_text:last-child {
    line-height: 1.13;
  }

  .caution_inner {
    padding: 10px;
  }

  .caution_text_box {
    margin-left: 10px;
  }

  .caution_text {
    font-size: 14px;
    line-height: 1.13;
  }
}
