.sub_container {
  padding-top: 71.5px;
  box-sizing: border-box;
}

.container_bg {
  transition: all 0.3s ease-in-out;
  padding: 175px 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.inner_box {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left_box {
  transition: all 0.3s ease-in-out;
  width: 50%;
}

.sub_title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 40px;
}

.sub_title {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 80px;
}

.title {
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.text {
  transition: all 0.3s ease-in-out;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.right_box {
  transition: all 0.3s ease-in-out;
  width: 48%;
}

.right_box_img {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .container_bg {
    padding: 60px 20px;
  }

  .inner_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .left_box {
    width: 100%;
  }

  .sub_title_box {
    margin-bottom: 20px;
  }

  .sub_title {
    font-size: 15px;
    text-align: center;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
    text-align: center;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
  }

  .right_box {
    margin-top: 50px;
    width: 100%;
  }

  .right_box_img {
    width: 100%;
  }
}
