

.file_upload_preview_box {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: solid 1px #d1d5db;
  position: relative;
}

.file_upload_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.file_upload_file {
  border-radius: 10px;
  box-sizing: border-box;
  padding: 2px;
  width: 100%;
  max-width: 90%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.file_upload_preview_box a {
  color: #000;
}


@media screen and (max-width: 1024px) {
  .file_upload_preview_box {
    margin: 0;
  }
}

@media screen and (max-width: 499px) {
  .file_upload_preview_box {
    width: 70px;
    height: 70px;
  }
}
