.sub_container {
  padding: 71.5px 20px 50px;
  box-sizing: border-box;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.inner_box {
  margin-top: 80px;
}

.title_box {
  margin-bottom: 50px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #101e32;
}

.tab_inner {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 50px;
}

.tab_box {
  width: 250px;
  cursor: pointer;
  text-decoration: none;
}

.tab_hr {
  border: 0;
  position: absolute;
  left: 230px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 36px;
  background-color: #ddd;
}

.tab {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.sub_tab {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d1d5db;
}

.input_inner {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_box {
  width: 30%;
  cursor: pointer;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

.select_box::-ms-expand {
  display: none;
  /* 화살표 없애기 for IE10, 11*/
}

.search_input_box {
  width: 67.5%;
  position: relative;
}

.search_input {
  outline: none;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.search_input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.search_input:focus::placeholder {
  color: transparent;
}

.search_input_img {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
}

.data_inner {
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.data_inner:last-child {
  margin-bottom: 0;
}

.data_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0c1522;
  transition: all 0.3s ease-in-out;
}

.hr {
  display: none;
  margin: 0;
  border: 0.5px solid #dfdfdf;
}

.data_text_box {
  display: none;
}

.data_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
}

.list_cross_box {
  position: absolute;
  right: 40px;
  top: 40px;
}

.list_cross_x,
.list_cross_y {
  width: 24px;
  height: 2px;
  background-color: #dfdfdf;
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.list_cross_y {
  width: 2px;
  height: 24px;
}

.list_cross_x {
  transition: all 0.8s ease-in-out;
}

/* active */

.acive_data_inner {
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.acive_data_inner:last-child {
  margin-bottom: 0;
}

.acive_data_inner .data_title_box {
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
}

.acive_data_inner .data_title {
  color: #ff5360;
  transition: all 0.3s ease-in-out;
}

.acive_data_inner .hr {
  display: block;
  margin: 0;
  border: 0.5px solid #dfdfdf;
}

.acive_data_inner .data_text_box {
  display: block;
  margin-top: 30px;
}

.acive_data_inner .list_cross_x {
  background-color: #ff5360;
  transform: translateX(-12px) translateY(-1px) rotate(225deg);
}

.acive_data_inner .list_cross_y {
  background-color: #ff5360;
  transform: translateX(-1px) translateY(-12px) rotate(405deg);
}

// 문의 하기 버튼

.empty_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty_text_box {
  text-align: center;
  margin-bottom: 50px;
}

.empty_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  color: #969696;
}

.button {
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #101e32;
  background-color: transparent;
  padding: 20px 50px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #101e32;
}

.button:hover {
  background-color: #ff5360;
  border: 1px solid #ff5360;
  color: #ffffff;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    min-height: 600px;
  }

  .inner_box {
    margin-top: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
    text-align: left;
  }

  .tab_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .tab_box {
    width: 150px;
  }

  .tab_box:last-child {
    text-align: right;
  }

  .tab_hr {
    left: 50%;
    top: 50%;
    transform: translateX(-50%, -50%);
    height: 23px;
  }

  .tab {
    font-size: 20px;
    line-height: 3;
  }

  .sub_tab {
    font-size: 18px;
    line-height: 3.33;
  }

  .input_inner {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .select_box {
    width: 100%;
    padding: 16px 20px;
    font-size: 15px;
  }

  .search_input_box {
    margin-top: 20px;
    width: 100%;
    position: relative;
  }

  .search_input {
    padding: 16px 20px;
    font-size: 15px;
  }

  .search_input::placeholder {
    font-size: 15px;
  }

  .data_inner {
    padding: 15px 20px;
  }

  .data_title_box {
    width: 95%;
  }

  .data_title {
    font-size: 15px;
  }

  .data_text {
    font-size: 13px;
    line-height: 1.54;
  }

  .list_cross_box {
    right: 20px;
    top: 22px;
  }

  .list_cross_x,
  .list_cross_y {
    width: 14px;
    height: 2px;
  }

  .list_cross_y {
    width: 2px;
    height: 14px;
  }

  /* active */

  .acive_data_inner {
    padding: 15px 20px;
  }

  .acive_data_inner .data_title_box {
    margin-bottom: 20px;
  }

  .acive_data_inner .list_cross_x {
    transform: translateX(-12px) translateY(1px) rotate(225deg);
  }

  .acive_data_inner .list_cross_y {
    transform: translateX(-6px) translateY(-5px) rotate(405deg);
  }

  // 문의 데어터

  .empty_text {
    font-size: 13px;
  }

  .button_box {
    width: 100%;
  }

  .button {
    width: 100%;
    padding: 20px 0;
    box-sizing: border-box;
    font-size: 16px;
  }
}
