.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.header_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 55px;
}

.title_inner {
  display: flex;
  align-items: center;
}

.account_title_box,
.active_account_title_box {
  width: 170px;
  position: relative;
  cursor: pointer;
}

.installment_title_box,
.active_installment_title_box {
  position: relative;
  width: 170px;
  cursor: pointer;
}

.hr {
  position: absolute;
  border: 0.5px solid #ddd;
  height: 36px;
  margin: 0;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.active_account_title_box .title,
.active_installment_title_box .title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #d1d5db;
}

.text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 4;
  letter-spacing: normal;
  text-align: left;
  color: #32c4ff;
}

/* 테이블 */

.table_inner {
  width: 100%;
}

.table_header {
  padding: 12px 50px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_header_title,
.installment_header_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

/* 결제내역 컨텐츠 */

.table_box {
  width: 15%;
  text-align: center;
}

.table_box:last-child {
  width: 38%;
  text-align: right;
}

.table_body {
  margin-bottom: 30px;
}

.table_data_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 22px 50px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-color: #fff;
}

.table_data_box {
  width: 15%;
  text-align: center;
}

.table_data_box:last-child {
  width: 38%;
  text-align: right;
}

.table_data {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #969696;
}

.table_history_data {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #ff5360;
}

/* 할부 컨텐츠 */

.installment_box {
  width: 15%;
  text-align: center;
}

.installment_box:last-child {
  width: 25%;
  text-align: right;
}

.installment_history_box {
  width: 37%;
  text-align: center;
}

.table_body {
  margin-bottom: 30px;
}

.table_data_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 22px 50px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-color: #fff;
}

.installment_data_box {
  width: 15%;
  text-align: center;
}

.installment_data_box:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
}

.installment_history_data_box {
  width: 37%;
  text-align: left;
}

.installment_data {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #969696;
}

.installment_history_data {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2e384b;
}

.installment_detaile_box {
  cursor: pointer;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 7px 17px;
  box-sizing: border-box;
}

.installment_detaile_button {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #56677e;
  outline: none;
  border: 0;
  background-color: transparent;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  padding: 3px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.53;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.53;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 71.5px 0 0;
  }

  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_inner {
    display: flex;
    justify-content: initial;
    align-items: initial;
    margin-bottom: 20px;
    flex-direction: column;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .title_inner {
    display: flex;
    align-items: center;
  }

  .account_title_box,
  .active_account_title_box {
    width: 100px;
  }

  .installment_title_box,
  .active_installment_title_box {
    width: 100px;
  }

  .hr {
    position: absolute;
    border: 0.5px solid #ddd;
    height: 23px;
    margin: 0;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .active_account_title_box .title,
  .active_installment_title_box .title {
    font-size: 20px;
    line-height: 3;
  }

  .title {
    font-size: 18px;
    line-height: 3.33;
  }

  .text_box {
    width: 100%;
  }

  .text {
    font-size: 14px;
    line-height: 4.29;
    text-align: center;
  }

  /* 테이블 */

  .table_inner {
    width: 100%;
  }

  .table_header {
    display: none;
  }

  /* 결제내역 컨텐츠 */

  .table_data_inner {
    flex-wrap: wrap;
    width: 100%;
    padding: 0 20px 20px 20px;
  }

  .table_data_box {
    width: 60%;
    text-align: left;
    padding: 20px 0;
    box-sizing: border-box;
  }

  .table_data_box:nth-child(1),
  .table_data_box:nth-child(2) {
    border-bottom: 1px solid #efefef;
  }

  .table_data_box:nth-child(2),
  .table_data_box:nth-child(4) {
    width: 40%;
    text-align: right;
  }

  .table_data_box:last-child {
    width: 100%;
    text-align: right;
    padding: 0;
  }

  /* 할부 컨텐츠 */

  .installment_data_box {
    width: 60%;
    text-align: left;
    padding: 20px 0;
    box-sizing: border-box;
  }

  .installment_data_box:nth-child(1),
  .installment_data_box:nth-child(2) {
    border-bottom: 1px solid #efefef;
  }

  .installment_data_box:nth-child(2) {
    width: 40%;
    text-align: right;
  }

  .installment_data_box:last-child {
    width: 100%;
    text-align: right;
    padding: 0;
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .installment_history_data_box {
    padding: 20px 0;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
  }

  .installment_data {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #969696;
  }

  .installment_history_data {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #101e32;
  }

  .installment_detaile_box {
    width: 100%;
    padding: 14px 0;
    text-align: center;
    margin-top: 20px;
  }

  .installment_detaile_button {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0;
    letter-spacing: normal;
    color: #56677e;
  }
}
