.inner_box {
  margin-bottom: 50px;
}

.title_box {
  margin-bottom: 10px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.text_box {
  margin-bottom: 20px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #656565;
}

.img {
  width: 100%;
}

/* 아래 컨텐츠 */

.caution_inner {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  margin-bottom: 40px;
  align-items: center;
}

.caution_text_box {
  margin-left: 10px;
}

.caution_text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
  line-height: 1.56;
}

.caution_text_b {
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .title_box {
    margin-bottom: 15px;
  }

  .title {
    font-size: 18px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }

  .img {
    width: 100%;
  }

  /* 아래 컨텐츠 */

  .caution_text:last-child {
    line-height: 1.13;
  }

  .caution_inner {
    padding: 10px;
  }

  .caution_text_box {
    margin-left: 10px;
  }

  .caution_text {
    font-size: 14px;
    line-height: 1.13;
  }
}
