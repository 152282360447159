/* text content */

.sub_conatiner {
  position: relative;
  min-width: 100vw;
  min-height: 100vh;
}

.inner {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  box-sizing: border-box;
  top: 250px;
  transition: all 0.3s ease-in-out;
}

.inner_box {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}

.sub_text_box {
  margin-bottom: 20px;
}

.sub_text {
  opacity: 0.5;
  font-family: NEXONLv2Gothic;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #fff;
}

.title_text_box {
  margin-bottom: 40px;
}

.title_text {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #fff;
}

.text_box {
  margin-bottom: 70px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #fff;
}

.button_box {
  transition: all 0.5s ease-in-out;
  margin-bottom: 150px;
}

.button {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #fff;
  padding: 12px 55px 12px 30px;
  box-sizing: border-box;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: #ff5360;
  border-radius: 22px;
  position: relative;
}

.button:hover {
  padding-right: 75px;
}

.button_arrow {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 38%;
  transform: translateY(-50%);
  right: 18%;
}

.button_arrow::before,
.button_arrow::after {
  content: '';
  position: absolute;
}

.button_arrow::before {
  width: 6px;
  height: 6px;
  border: 2px solid #fff;
  border-right: 0px;
  border-bottom: 0px;
  transform: rotate(135deg);
}

.button_arrow_hr {
  background-color: #fff;
  width: 15px;
  height: 2px;
  position: absolute;
  top: 3px;
  left: -8px;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  z-index: 0;
}

.button:hover .button_arrow_hr {
  left: -18px;
  width: 25px;
}

/* media content */

.media_theme_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.media_theme_text {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #fff;
}

/* media button */

.media_theme_button_box {
  display: flex;
}

.media_theme_left_button_box {
  margin-right: 10px;
}

.media_theme_left_button,
.media_theme_right_button {
  cursor: pointer;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

.hover_media_left_button,
.hover_media_right_button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: none;
}

.media_theme_left_button_box:hover .media_theme_left_button,
.media_theme_right_button_box:hover .media_theme_right_button {
  display: none;
}

.media_theme_left_button_box:hover .hover_media_left_button,
.media_theme_right_button_box:hover .hover_media_right_button {
  display: block;
}

/* media progress bar */

.media_progress_box {
  position: relative;
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background-color: rgba($color: #fff, $alpha: 0.2);
}

.media_progress_bar {
  border-radius: 1px;
  width: 10%;
  height: 2px;
  background-color: #fff;
}

/* media theme list */

.media_box {
  width: 100%;
}

.media_theme_list_box {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

.media_theme_list_box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.media_theme_list_box::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.media_theme_img_box {
  flex: 0 0 auto;
  width: 220px;
  height: 128px;
  margin-right: 20px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.media_theme_img_box:last-child {
  margin-right: 0;
}

.media_play_button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.media_theme_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.media_theme_hover_bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.media_theme_img_box:hover .media_theme_hover_bg {
  opacity: 1;
}

.media_theme_img_box:hover .media_play_button {
  opacity: 1;
}

.media_theme_img_box.active .media_theme_hover_bg,
.media_theme_img_box.active .media_play_button {
  opacity: 1;
}

.mobile_br {
  display: none;
}

.video_bg {
  min-width: 100vw;
  min-height: 100vh;
}

@media screen and (max-width: 1024px) {
  .sub_conatiner {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: auto;
    padding: 200px 20px 0;
    box-sizing: border-box;
  }

  .video_bg {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    margin-top: 150px;
    border-radius: 20px;
  }

  .inner {
    top: 120px;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .inner_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .content_text_box {
    width: 100%;
    height: 100%;
  }

  .sub_text {
    opacity: 1;
    font-size: 13px;
    line-height: normal;
    color: #696969;
  }

  .title_text_box {
    margin-bottom: 30px;
  }

  .title_text {
    font-size: 24px;
    line-height: 1.42;
    color: #2e384b;
  }

  .text_box {
    margin-bottom: 0;
  }

  .text {
    font-size: 12px;
    line-height: 1.67;
    color: #656565;
  }

  .button_box {
    position: absolute;
    z-index: 1;
    line-height: normal;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 5%;
    margin-bottom: 0;
  }

  /* media content */

  .media_theme_box {
    margin-bottom: 20px;
  }

  .media_theme_text {
    font-size: 16px;
    color: #2e384b;
  }

  /* media button */

  .media_theme_button_box,
  .media_progress_box {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .pc_br {
    display: none;
  }
}
