.sub_container {
  transition: all 0.3s ease-in-out;
  padding: 200px 20px 180px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 80px;
}

.title {
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.text_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 150px;
}

.text {
  transition: all 0.3s ease-in-out;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.media_interior_inner {
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.media_interior_box {
  transition: all 0.3s ease-in-out;
  width: 28%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  margin-bottom: 30px;
}

.media_interior_title_box {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.media_interior_title {
  transition: all 0.3s ease-in-out;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.media_interior_text {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 135px 20px;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
    text-align: center;
  }

  .text_box {
    margin-bottom: 80px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
  }

  .media_interior_inner {
    flex-wrap: initial;
    justify-content: initial;
    flex-direction: column;
    align-items: center;
  }

  .media_interior_box {
    width: 100%;
    margin-bottom: 20px;
  }

  .media_interior_title {
    font-size: 18px;
    line-height: 1.56;
  }

  .media_interior_text {
    font-size: 14px;
    line-height: 1.71;
  }

  .mobile_br {
    display: block;
  }
}
