.container {
  // max-width: 1920px;
  height: 100vh;
  padding: 0 20px;
}

.sub_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  padding: 30px;
  box-sizing: border-box;
  background-color: rgba($color: #000000, $alpha: 0.2);
  backdrop-filter: blur(5px);
  overflow-y: scroll;
}

.inner {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

/* 헤더 */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e384b;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.close_button_box {
  cursor: pointer;
}

/* 컨텐트 */

.content_inner {
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  padding: 50px;
  box-sizing: border-box;
}

/* 버튼 */

.button_inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.prev_button,
.next_button {
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 15px 35px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d1d5db;
}

.next_button {
  color: #fff;
  background-color: #2e384b;
}

.step_number {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #969696;
}

.step_number_b {
  font-weight: bold;
  color: #ff5360;
}

.step_number_box {
  margin: 0 50px;
}

.skip_button_box {
  cursor: pointer;
  position: absolute;
  right: 0;
}

.skip_button {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #d1d5db;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 80px 0 0 0;
    backdrop-filter: blur(5px);
    background-color: rgba($color: #000000, $alpha: 0.6);
  }

  .inner {
    max-width: 100%;
  }

  .header {
    padding: 20px 10px;
  }

  .title {
    font-size: 18px;
    line-height: 1.33;
  }

  .close_button_box {
    cursor: pointer;
  }

  .content_inner {
    overflow: hidden;
    padding: 20px;
  }

  .button_inner {
    position: initial;
    justify-content: space-between;
  }

  .prev_button,
  .next_button {
    border: solid 1px #dfdfdf;
    background-color: #fff;
    padding: 15px 20px;
    font-size: 15px;
  }

  .next_button {
    color: #fff;
    background-color: #2e384b;
  }

  .step_number {
    font-size: 18px;
  }

  .step_number_b {
    font-weight: bold;
    color: #ff5360;
  }

  .step_number_box {
    margin: 0;
  }

  .skip_button_box {
    position: fixed;
    top: 35px;
    right: 20px;
  }

  .skip_button {
    font-size: 15px;
  }
}
