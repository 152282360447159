// 공통

.category_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text,
.category_text_b {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
  margin-left: 10px;
}

.category_text_b {
  font-weight: 500;
  color: #ff5360;
}

.category_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 0;
}

// 산출 내역

.calculation_inner {
  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #efefef;
}

.calculation_box {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.calculation_text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #56677e;
  margin-bottom: 5px;
  text-align: center;
}

.calculation_number {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #2e384b;
  text-align: center;
}

.calculation_hr {
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}

.calculation_total_money_box {
  margin-top: 30px;
}

.calculation_total_money {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
  margin-bottom: 10px;
}

.calculation_total_sale_money {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
  text-decoration-line: line-through;
}

// 광고 업로드 버튼

.upload_button_box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
}

.upload_button {
  cursor: pointer;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px 74px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .calculation_box {
    width: 100%;
    margin-bottom: 30px;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
  }

  .calculation_item {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .calculation_number {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #2e384b;
    text-align: center;
  }

  .upload_button {
    width: 100%;
    padding: 20px 0;
    font-size: 15px;
  }
}
