.sub_container {
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 200px 0 220px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}

.inner_box {
  margin-bottom: 100px;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  transition: all 0.3s ease-in-out;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
  transition: all 0.3s ease-in-out;
}

/* 도형 css */

.dotted_img_box {
  position: absolute;
  top: 20px;
  right: -178px;
}

.circle_img_box {
  position: absolute;
  top: 350px;
  left: -100px;
}

.troangle_img_box {
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
}

/* theme */

.theme_inner {
  width: 100%;
}

.top_theme_box,
.bottom_theme_box {
  display: flex;
  margin-bottom: 50px;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
}

.top_theme_box,
.bottom_theme_box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.top_theme_box::-webkit-scrollbar,
.bottom_theme_box::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

// .bottom_theme_box {
//     padding: 0 100px;
//     box-sizing: border-box;
// }

.theme_img_box {
  flex: 0 0 auto;
  width: 340px;
  height: 240px;
  margin-right: 100px;
  transition: all 0.3s ease-in-out;
}

.theme_img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 150px 0 110px;
  }

  .title_box {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .title {
    font-size: 30px;
    line-height: 1.32;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
  }

  .top_theme_box,
  .bottom_theme_box {
    margin-bottom: 24px;
  }

  .theme_img_box {
    width: 37%;
    margin-right: 49px;
  }
}

@media screen and (max-width: 960px) {
  .theme_img_box {
    width: 162px;
    height: 115px;
  }
}

@media screen and (max-width: 715px) {
  .mobile_br {
    display: block;
  }
}
