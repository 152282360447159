.payment_button {
  cursor: pointer;
  outline: none;
  margin-left: 50px;
  border: 0;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 70px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1024px) {
  .payment_button {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
    padding: 10px 0;
    font-size: 18px;
  }
}
