.sub_container {
  padding: 180px 20px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_box {
  width: 30%;
  position: relative;
}

.login_text_box {
  margin-bottom: 30px;
}

.login_text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.text_box {
  margin-bottom: 80px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.input_box {
  width: 100%;
  margin-bottom: 50px;
}

.input {
  width: 100%;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px;
  box-sizing: border-box;
  outline: 0;
  font-family: NEXONLv2Gothic;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
}

.input::placeholder {
  font-family: NEXONLv2Gothic;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.input:focus::placeholder {
  color: transparent;
}

.button_box {
  width: 100%;
}

.button {
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  background-color: #2e384b;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 110px 20px;
  }

  .inner_box {
    width: 100%;
  }

  .login_text {
    font-size: 15px;
    font-weight: bold;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
  }

  .text_box {
    margin-bottom: 80px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }

  .input_box {
    margin-bottom: 0;
  }

  .input {
    font-size: 14px;
    line-height: 2.14;
  }

  .input::placeholder {
    font-size: 14px;
    line-height: 2.14;
  }

  .button_box {
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .button {
    padding: 17px;
    font-size: 15px;
    line-height: 2;
  }
}
