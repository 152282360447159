.container {
  margin-top: 100px;
}

// 타이틀 박스

.title_box {
  margin-bottom: 50px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

// 필터 헤더

.header_inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.active_basket_img_box img {
  width: 24px;
}

// 카테고리 검색

.category_input_inner {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category_input_box {
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
  &:last-child {
    padding-right: 0;
    padding-left: 10px;
  }
}

.select_box {
  width: 100%;
  cursor: pointer;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 95% 50%;
  background-repeat: no-repeat;
  &::-ms-expand {
    display: none;
    /* 화살표 없애기 for IE10, 11*/
  }
}

// 검색 박스

.search_input_box {
  width: 67%;
  position: relative;
}

.search_input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  &::placeholder {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #969696;
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.search_button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

// 리스트 박스

.suggestion_list_inner {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.suggestion_box {
  width: calc((100% - 60px) / 4);
}

.suggestion_img_box {
  cursor: pointer;
  width: 100%;
  height: 180px;
  border-radius: 10px;
  position: relative;
}

.suggestion_img,
.active_suggestion_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  border: 3px solid transparent;
}

.active_suggestion_img {
  border: 3px solid #ff5360;
}

.detail_img_box,
.basket_img_box,
.active_basket_img_box {
  cursor: pointer;
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #000;
  backdrop-filter: blur(15px);
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 56px;
}

.basket_img_box {
  right: 10px;
}

.active_basket_img_box {
  right: 10px;
  background-color: rgba($color: #ff5360, $alpha: 0.8);
}

.quality_box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 11.5px;
  width: 40px;
  padding: 4px 0;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.quality_text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.suggestion_info_inner {
  margin-top: 15px;
}

.suggestion_info_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.suggestion_info_title_box {
  width: calc(100% - 50px);
}

.suggestion_info_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2e384b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestion_info_name {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #696969;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestion_info_img_box {
  cursor: pointer;
}

.suggestion_payment_box {
  margin-top: 10px;
}

.suggestion_payment {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #ff5360;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1024px) {
  .container {
    margin-top: 50px;
  }

  .title {
    font-size: 20px;
  }

  // 필터 헤더

  .header_inner {
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 50px;
  }

  // 카테고리 검색

  .category_input_inner {
    width: 100%;
    margin-bottom: 30px;
  }

  .select_box {
    font-size: 15px;
  }

  // 검색 박스

  .search_input_box {
    width: 100%;
    position: relative;
  }

  .search_input {
    width: 100%;
    font-size: 15px;
    &::placeholder {
      font-size: 15px;
    }
  }

  // 리스트 박스

  .suggestion_box {
    width: calc((100% - 60px) / 3);
  }
}

@media screen and (max-width: 650px) {
  .suggestion_box {
    width: 100%;
  }
  .suggestion_img_box {
    height: 200px;
  }
}
