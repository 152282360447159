.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.title_box {
  margin-bottom: 110px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #101e32;
}

.proccess_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.proccess_box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.select_process_box {
  width: 11%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #ff5360;
  background-color: #fff;
}

.select_process_img_box {
  margin-right: 10px;
}

.select_process_title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.select_process_bar {
  width: 4%;
  height: 2px;
  background-color: #ff5360;
}

.process_box {
  width: 11%;
  height: 50px;
  padding: 16px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #efefef;
  background-color: #fff;
}

.process_title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #d1d5db;
}

.process_bar {
  width: 4%;
  height: 2px;
  background-color: #efefef;
}

.selected_process_box {
  width: 11%;
  height: 50px;
  padding: 16px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #ecedef;
  background-color: #ecedef;
}

.selected_process_title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c9c9c9;
}

.selected_process_bar {
  width: 4%;
  height: 2px;
  background-color: #ecedef;
}

.process_step_box {
  cursor: pointer;
  border-radius: 25px;
  background-color: #ff5360;
  padding: 18px 14px;
  box-sizing: border-box;
  margin-bottom: 80px;
}

.process_step {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.button_box {
  width: 100%;
  max-width: 450px;
  text-decoration: none;
}

.button {
  width: 100%;
  cursor: pointer;
  outline: none;
  border: 0;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2e384b;
}

.mobile_process_step_box {
  display: none;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 2;
  }

  .proccess_box {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0;
  }

  .select_process_box {
    width: 100%;
  }

  .select_process_bar {
    width: 2px;
    height: 30px;
  }

  .process_box {
    width: 100%;
  }

  .process_bar {
    width: 2px;
    height: 30px;
  }

  .selected_process_box {
    width: 100%;
  }

  .selected_process_title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #c9c9c9;
  }

  .selected_process_bar {
    width: 2px;
    height: 30px;
  }

  .process_step_box {
    display: none;
  }

  .mobile_process_step_box {
    display: block;
    cursor: pointer;
    width: 100%;
    padding: 17px 0;
    box-sizing: border-box;
    border-radius: 25px;
    background-color: #ff5360;
    margin-top: 10px;
  }

  .process_step {
    text-align: center;
  }

  .button_box {
    max-width: 100%;
    margin-top: 50px;
    text-decoration: none;
  }

  .button {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 16px 0;
  }
}
