.sub_container {
  padding: 0 20px 150px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.title_box {
  margin-bottom: 150px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.service_solution_inner {
  display: flex;
  justify-content: space-between;
}

/* 왼쪽 컨테이너 */

.left_solution_box {
  width: 48%;
}

.hardware_box,
.interior_box,
.software_box,
.Advertising_box {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
}

.hardware_box {
  margin-bottom: 450px;
}

.software_box {
  margin: 200px 0 275px;
}

.solution_img_box {
  width: 100%;
  margin-bottom: 50px;
}

.solution_img {
  width: 100%;
  border-radius: 10px;
}

.detail_text_inner {
  display: flex;
  justify-content: space-between;
}

.detail_title {
  transition: all 0.3s ease-in-out;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.detail_text_box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.detail_text_box:last-child {
  margin-bottom: 0;
}

.detail_text_img_box {
  margin-right: 10px;
}

.detail_text {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

/* 오른쪽 컨테이너 */

.right_solution_box {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.detail_title_b {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.detail_select_text_box {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}

.Advertisingv_detail_text {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.detail_select_text {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.mobile_service_solution_inner {
  display: none;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1300px) {
  .detail_title {
    font-size: 26px;
  }

  .detail_text {
    font-size: 16px;
  }

  .detail_title_b {
    font-size: 16px;
  }

  .Advertisingv_detail_text {
    font-size: 16px;
    line-height: 1.5;
  }

  .detail_select_text {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 0 20px 50px;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #0c1522;
  }

  .service_solution_inner {
    display: none;
  }

  .mobile_service_solution_inner {
    display: block;
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: column;
  }

  .left_solution_box {
    width: 100%;
  }

  .software_box {
    margin: 0;
  }

  .hardware_box,
  .interior_box,
  .software_box,
  .Advertising_box {
    width: 100%;
    padding: 0;
    border: none;
    margin-bottom: 50px;
  }

  .solution_img_box {
    margin-bottom: 30px;
  }

  .detail_text_inner {
    display: flex;
    justify-content: initial;
    flex-direction: column;
  }

  .detail_title_box {
    margin-bottom: 30px;
  }

  .detail_title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
  }

  .detail_text_box {
    width: 100%;
    margin-bottom: 0;
  }

  .detail_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.13;
    letter-spacing: normal;
  }

  /* 오른쪽 컨테이너 */

  .right_solution_box {
    width: 100%;
  }

  .detail_title_b {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
  }

  .detail_select_text_box {
    display: flex;
    margin-bottom: 0;
  }

  .Advertisingv_detail_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.88;
    letter-spacing: normal;
  }

  .detail_select_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.13;
    letter-spacing: normal;
  }

  .mobile_br {
    display: block;
  }
}
