.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 450px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* input */

.title_box {
  margin-bottom: 80px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #101e32;
}

.input_box {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
}

.input_box:last-child {
  margin-bottom: 0;
}

.input_label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.all_input,
.one_third_input,
.half_input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 0;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
  outline: 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
}

.all_input::placeholder,
.one_third_input::placeholder,
.half_input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.all_input:focus::placeholder,
.one_third_input:focus::placeholder,
.half_input:focus::placeholder {
  color: transparent;
}

.half_box,
.birth_half_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.one_third_input {
  width: 70%;
}

.input_button {
  cursor: pointer;
  padding: 20px 40px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #d1d5db;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: 0;
  outline: none;
}

.input_button.active {
  background-color: #2e384b;
}

.input_button.success {
  background-color: #ff5360;
}

.half_input,
.half_select_box {
  width: 48%;
}

.half_button_box {
  display: flex;
  justify-content: end;
  align-items: center;
}

.checkbox {
  display: none;
}

.half_button {
  cursor: pointer;
  margin-right: 10px;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
}

.half_button:last-child {
  margin-right: 0;
}

.half_button_active {
  cursor: pointer;
  margin-right: 10px;
  border-radius: 10px;
  border: solid 1px #ff5360;
  background-color: #ff5360;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.half_button_active:last-child {
  margin-right: 0;
}

.hr {
  height: 1px;
  background-color: #efefef;
  margin: 40px 0;
}

.select_box {
  width: 100%;
  cursor: pointer;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.select_box::-ms-expand {
  display: none;
  /* 화살표 없애기 for IE10, 11*/
}

.bottom_input_box {
  margin-top: 20px;
}

.button_box {
  margin-top: 50px;
}

.button {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2e384b;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

.button.disabled {
  opacity: 0.5;
  cursor: default;
}

.other_fail_box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 105px;
  left: 15px;
}

.other_fail_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.other_fail_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.code_box {
  margin-top: 30px;
}

.txt_error {
  margin-left: 15px;
  margin-top: 10px;
  font-size: 15px;
  text-align: left;
  color: #ff5360;
  margin-bottom: 0;
}

.edit_btn {
  position: absolute;
  top: 24px;
  width: 100%;
  height: 80px;
  z-index: 1;
  background: transparent;
  border: none;
  cursor: pointer;
}

.btn_search {
  position: absolute;
  top: 24px;
  width: 100%;
  height: 80px;
  z-index: 1;
  background: transparent;
  border: none;
  cursor: pointer;
}

.small {
  font-size: 8px;
}

// 추가 및 변경 css

.user_info_input_inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.user_info_label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: normal;
  color: #101e32;
}

.user_info_txt {
  font-size: 8px;
  margin-left: 8px;
}

.user_info_input_box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user_info_week_button {
  cursor: pointer;
  padding: 15px;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.active_user_info_week_button {
  cursor: pointer;
  padding: 15px;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  background-color: #ff5360;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.user_info_input_inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
}

.ueser_info_half_select_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ueser_info_select {
  cursor: pointer;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

.ueser_info_half_select {
  cursor: pointer;
  outline: none;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 98% 50%;
  background-repeat: no-repeat;
}

.ueser_info_half_select_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #0c1522;
  margin: 0 5px;
}

.buisness_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* input */

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 2;
  }

  .input_box {
    margin-bottom: 30px;
  }

  .all_input,
  .one_third_input,
  .half_input {
    font-size: 14px;
    padding: 16px 20px;
  }

  .all_input::placeholder,
  .one_third_input::placeholder,
  .half_input::placeholder {
    font-size: 14px;
  }

  .one_third_input {
    width: 70%;
  }

  .input_button {
    cursor: pointer;
    padding: 16px 20px;
    font-size: 15px;
  }

  .birth_half_box {
    width: 100%;
    flex-direction: column;
  }

  .half_input,
  .half_select_box {
    width: 100%;
  }

  .half_select_box {
    padding-left: 20px;
    box-sizing: border-box;
  }

  .half_button_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .half_button {
    margin-top: 20px;
    width: 48%;
    margin-right: 0;
    padding: 16px 0;
    font-size: 15px;
    line-height: 2;
  }

  .half_button_active {
    margin-top: 20px;
    width: 48%;
    margin-right: 0;
    padding: 16px 0;
    font-size: 15px;
    line-height: 2;
  }

  .hr {
    margin: 30px 0;
  }

  .select_box {
    padding: 16px 20px;
    font-size: 15px;
    line-height: 2;
  }

  .button_box {
    margin-top: 50px;
  }

  .button {
    padding: 16px;
    font-size: 15px;
    font-weight: bold;
  }

  .other_fail_box {
    top: 90px;
    left: 15px;
  }

  .other_sub_container {
    padding: 110px 20px 80px;
  }

  .text_box {
    margin-bottom: 50px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }

  .text_b {
    font-size: 14px;
    line-height: 1.71;
  }

  .all_input,
  .one_third_input,
  .half_input {
    font-size: 14px;
    padding: 16px 20px;
  }

  .all_input::placeholder,
  .one_third_input::placeholder,
  .half_input::placeholder {
    font-size: 14px;
  }

  .one_third_input {
    width: 70%;
  }

  .input_button {
    cursor: pointer;
    padding: 16px 20px;
    font-size: 15px;
  }

  .birth_half_box {
    width: 100%;
    flex-direction: column;
  }

  .half_input,
  .half_select_box {
    width: 100%;
  }

  .half_button_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .other_checkbox {
    display: none;
  }

  .half_button {
    margin-top: 20px;
    width: 48%;
    margin-right: 0;
    padding: 16px 0;
    font-size: 15px;
    line-height: 2;
  }

  .half_button_active {
    margin-top: 20px;
    width: 48%;
    margin-right: 0;
    padding: 16px 0;
    font-size: 15px;
    line-height: 2;
  }

  .other_hr {
    margin: 30px 0;
  }

  .select_box {
    padding: 16px 20px;
    font-size: 15px;
    line-height: 2;
  }

  .bottom_input_box {
    margin-top: 20px;
  }

  .other_fail_text {
    font-size: 13px;
    line-height: 2.31;
  }

  .txt_error {
    font-size: 13px;
    line-height: 2.31;
  }

  .edit_btn {
    position: absolute;
    top: 24px;
    width: 100%;
    height: 80px;
    z-index: 1;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .btn_search {
    position: absolute;
    top: 24px;
    width: 100%;
    height: 80px;
    z-index: 1;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
