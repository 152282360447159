.sub_container {
  padding: 0 20px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.inner_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text_inner {
  width: 30%;
}

.sub_title_box {
  margin-bottom: 30px;
}

.sub_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.title_box {
  margin-bottom: 100px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.input_box {
  width: 100%;
  margin-bottom: 50px;
}

.email_box {
  margin-bottom: 20px;
  width: 100%;
}

.email_input,
.password_input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  color: #000;
  font-size: 20px;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  outline: none;
}

.email_input.error,
.password_input.error {
  border: solid 1px #ff5360;
}

.email_input::placeholder,
.password_input::placeholder {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
  transition: all 0.3s ease-in-out;
}

.email_input:focus::placeholder,
.password_input:focus::placeholder {
  color: transparent;
}

.password_box {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}

.password_visibilit_img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
}

.password_input {
  width: 100%;
}

.password_error_box {
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
}

.password_error_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.password_error_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.login_box {
  margin-bottom: 50px;
}

.login_button {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 10px;
  background-color: #2e384b;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.signup_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signup_text {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.password_lose_text {
  text-decoration: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #32c4ff;
}

.btn_lose {
  cursor: pointer;
}

.lose_b {
  margin-left: 12px;
  text-decoration: none;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  color: #32c4ff;
  text-decoration: underline;
}

.password_lose_b {
  text-decoration: underline;
}

/* right inner */

.img_inner {
  margin-top: 120px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  width: 70%;
  height: 950px;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;
}

.img_inner {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.img_inner::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.left_img_box,
.center_img_box,
.right_img_box {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}

.left_img_box,
.right_img_box {
  padding: 105px 0 25px;
  box-sizing: border-box;
}

.right_img_box {
  margin-right: 0;
}

.img_box {
  flex: 0 0 auto;
  width: 210px;
  height: 270px;
  border-radius: 20px;
  margin-bottom: 40px;
}

.img_box:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .text_inner {
    margin-top: 120px;
    width: 100%;
  }

  .sub_title {
    font-size: 15px;
  }

  .title_box {
    margin-bottom: 120px;
  }

  .title {
    font-size: 30px;
  }

  .email_input,
  .password_input {
    font-size: 14px;
  }

  .email_input::placeholder,
  .password_input::placeholder {
    font-size: 14px;
  }

  .password_error_text {
    font-size: 13px;
    line-height: 2.31;
  }

  .login_button {
    padding: 16px 0;
    font-size: 15px;
    line-height: 2;
  }

  .signup_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .signup_text {
    font-size: 15px;
    line-height: 2;
  }

  .password_lose_text {
    font-size: 15px;
    line-height: 2;
  }

  .img_inner {
    display: none;
  }
}
