.inner {
  max-width: 1520px;
  margin: 0 auto;
  padding: 160px 0 132px;
  box-sizing: border-box;
}

.bg {
  width: 100%;
  height: 100%;
  position: relative;
}

.box_img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.box_img img {
  width: 100%;
  height: 100%;
}

.inner_box {
  text-align: center;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

/* button */
.button {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 12px 55px 12px 30px;
  box-sizing: border-box;
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background-color: #ff5360;
  border-radius: 22px;
  position: relative;
}

.button_arrow {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15%;
}

.hover_button_arrow {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10%;
  display: none;
}

.button:hover {
  padding-right: 75px;
}

.button:hover .button_arrow {
  display: none;
}

.button:hover .hover_button_arrow {
  display: block;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .mobile_br {
    display: block;
  }

  /* button */
  .button {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    padding: 12px 55px 12px 30px;
    box-sizing: border-box;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    background-color: #ff5360;
    border-radius: 22px;
    position: relative;
  }
}
