// 전체

.container {
  padding: 71.5px 20px 0;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 150px auto 0;
  position: relative;
}

// 뒤로가기

.back_box {
  cursor: pointer;
  position: absolute;
  top: -50px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

// 구매 주문 번호 박스

.order_box {
  padding: 30px 0 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.order_text,
.order_number {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  font-size: 24px;
  color: #56677e;
}

.order_number {
  margin-left: 15px;
  font-size: 36px;
  font-weight: bold;
  color: #101e32;
}

// 광고 정보

.order_inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

// 왼쪽

.media_box {
  width: 62%;
  height: auto;
  position: relative;
}

.artist_media_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.media_play_button_box {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media_play_button_img {
  padding-left: 5px;
  box-sizing: border-box;
}

// 오른쪽

.info_box {
  width: 33%;
  position: relative;
}

.play_info_box {
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #dfdfdf;
}

.play_info {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #2e384b;
}

.select_info_box {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_info {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2e384b;
}

.txt_reason {
  margin-top: 8px;
}

.select_info_price {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #ff5360;
}

.refuse_date {
  font-size: 14px;
  color: #333;
  margin-left: 4px;
}

.refuse_info_box {
  margin-top: 30px;
}

.refuse_info {
  margin-top: 8px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #696969;
}

.button_box {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.button {
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border: 0;
  border-radius: 10px;
  background-color: #ff5360;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

  // 결제 대기
  .mobile_tbody_td_wating {
    color: #969696;
  }

  // 광고 거절
  .mobile_tbody_td_refuse {
    color: #ff5360;
  }

  // 결제완료
  .mobile_tbody_td_completion {
    color: #32c4ff;
  }

  // 광고 진행 중
  .mobile_tbody_td_rogress {
    color: #56677e;
  }

  // 광고 완료
  .mobile_tbody_td_advertisement {
    color: #dedede;
  }

@media screen and (max-width: 1325px) {
  .back_box {
    left: -10px;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    margin: 100px auto 0;
  }

  // 구매 주문 번호 박스

  .order_text,
  .order_number {
    font-size: 10px;
    line-height: 3;
  }

  .order_number {
    margin-left: 15px;
    font-size: 20px;
  }

  // 광고 정보

  .order_inner {
    justify-content: initial;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  // 왼쪽

  .media_box {
    width: 580px;
    height: 300px;
    margin-bottom: 45px;
  }

  // 오른쪽

  .info_box {
    width: 580px;
    position: initial;
  }

  .play_info_box {
    padding-bottom: 20px;
  }

  .play_info {
    font-size: 18px;
    line-height: 1.22;
  }

  .select_info_box {
    margin-top: 20px;
  }

  .refuse_info_box {
    margin-top: 20px;
  }

  .button_box {
    margin-top: 20px;
    position: initial;
    width: 100%;
  }
}

@media screen and (max-width: 620px) {
  // 왼쪽

  .media_box {
    width: 100%;
    height: auto;
  }

  // 오른쪽

  .info_box {
    width: 100%;
  }
}
