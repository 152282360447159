.container {
  position: relative;
}

.img {
  width: 100%;
}

.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1520px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.text_inner {
  transition: all 0.5s ease-in-out;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #0c1522;
}

.title_b {
  display: none;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #555;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1500px) {
  .text_inner {
    width: 40%;
  }

  .mobile_br {
    display: block;
  }

  .pc_br {
    display: none;
  }

  .title_b {
    display: inline;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title_box {
    margin-bottom: 20px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #0c1522;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #696969;
  }
}

@media screen and (max-width: 324px) {
  .title {
    font-size: 20px;
  }

  .text {
    font-size: 12px;
  }
}
