.sub_container {
  padding: 71.5px 20px 50px;
  box-sizing: border-box;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.inner_box {
  margin-top: 80px;
}

.title_box {
  margin-bottom: 50px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #101e32;
}

.tab_inner {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  position: relative;
}

.tab_box {
  width: 250px;
  cursor: pointer;
  text-decoration: none;
}

.tab_hr {
  border: 0;
  position: absolute;
  left: 230px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 36px;
  background-color: #ddd;
}

.tab {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.sub_tab {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d1d5db;
}

.content_text_box {
  margin-bottom: 30px;
}

.content_text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.content_sub_text_box {
  margin-bottom: 50px;
}

.content_sub_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
}

.hr {
  margin: 0;
  border: 0.5px solid #dfdfdf;
}

.input_box {
  margin-top: 50px;
}

.label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: normal;
  color: #101e32;
}

.input {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
}

.input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.input:focus::placeholder,
.textarea:focus::placeholder {
  color: transparent;
}

.textarea_box {
  margin-top: 40px;
}

.textarea {
  outline: none;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  height: 300px;
  resize: none;
}

.textarea::placeholder {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.upload_box {
  margin-top: 40px;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  width: 100%;
  padding: 12px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.upload_label {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #56677e;
  padding: 10px 24px;
  box-sizing: border-box;
  border-radius: 18px;
  border: solid 1px #dfdfdf;
  background-color: #f6f6f6;
  cursor: pointer;
}

.list_upload {
  display: flex;
  flex-direction: column;
}

.download_file {
  display: flex;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
  text-decoration: none;
  align-items: center;
}

.btn_remove {
  display: inline-block;
  background-image: url('/images/ic_photo_del.png');
  width: 20px;
  height: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 20px;
  cursor: pointer;
}

.download_file + .download_file {
  margin-top: 16px;
}

.upload {
  display: none;
}

.button_box {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 25%;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .inner_box {
    margin-top: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
    text-align: left;
  }

  .tab_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  .tab_box {
    width: 150px;
  }

  .tab_box:last-child {
    text-align: right;
  }

  .tab_hr {
    left: 50%;
    top: 50%;
    transform: translateX(-50%, -50%);
    height: 23px;
  }

  .tab {
    font-size: 20px;
    line-height: 3;
  }

  .sub_tab {
    font-size: 18px;
    line-height: 3.33;
  }

  .content_text_box {
    margin-bottom: 20px;
  }

  .content_text {
    font-size: 24px;
  }

  .content_sub_text_box {
    margin-bottom: 30px;
  }

  .content_sub_text {
    font-size: 14px;
    line-height: 1.71;
  }

  .input_box {
    margin-top: 30px;
  }

  .input {
    font-size: 14px;
    line-height: 2.14;
    padding: 16px 20px;
  }

  .input::placeholder {
    font-size: 14px;
    line-height: 2.14;
  }

  .textarea_box {
    margin-top: 50px;
  }

  .textarea {
    font-size: 14px;
    line-height: 1.71;
  }

  .textarea::placeholder {
    font-size: 14px;
    line-height: 1.71;
  }

  .upload_box {
    margin-top: 30px;
    padding: 16px 20px;
  }

  .upload_text {
    font-size: 14px;
  }

  .upload_label {
    font-size: 13px;
  }

  .button_box {
    margin-top: 30px;
  }

  .button {
    width: 100%;
    padding: 16px 0;
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
  .upload_label {
    margin-left: 10px;
    width: 70%;
  }
}
