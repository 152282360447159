/* common */
.sub_container {
  padding: 180px 20px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.inner_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* email */

.email_box {
  width: 100%;
  max-width: 450px;
}

.step_box {
  margin-bottom: 30px;
}

.step_text {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.step_text_b {
  font-weight: bold;
  color: #ff5360;
}

.title_box {
  margin-bottom: 80px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.email_input_box {
  width: 100%;
  margin-bottom: 60px;
  position: relative;
}

.email_input {
  width: 70%;
  height: 62px;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
}

.email_input:focus::placeholder {
  color: transparent;
}

.email_input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.email_success_box {
  top: 70px;
  left: 15px;
  display: flex;
  align-items: center;
  position: absolute;
}

.email_success_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.email_success_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #32c4ff;
}

.all_checkbox {
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #efefef;
  display: flex;
  align-items: center;
}

.checkbox {
  display: none;
}

input[id='all'].checked ~ .checklabel,
input[id='vim_check'].checked ~ .checklabel,
input[id='infor_check'].checked ~ .checklabel,
input[id='event_check'].checked ~ .checklabel {
  background-color: #32c4ff;
  border-radius: 5px;
  border: solid 1px #32c4ff;
}

.checklabel::after {
  content: '';
  position: absolute;
  display: none;
}

input[id='all'].checked ~ .checklabel:after,
input[id='vim_check'].checked ~ .checklabel:after,
input[id='infor_check'].checked ~ .checklabel:after,
input[id='event_check'].checked ~ .checklabel:after {
  display: block;
}

input[id='all'].checked + label::after,
input[id='vim_check'].checked + label::after,
input[id='infor_check'].checked + label::after,
input[id='event_check'].checked + label::after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[id='all'] + label,
input[id='vim_check'] + label,
input[id='infor_check'] + label,
input[id='event_check'] + label {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  border: 0;
  border-radius: 5px;
  border: solid 1px #efefef;
  position: relative;
}

input[id='event_check'] + label {
  border: solid 1px #32c4ff;
}

.agree_text {
  cursor: pointer;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.agree_text_b {
  color: #ff5360;
}

.agree_checkbox_inner {
  margin-bottom: 50px;
}

.agree_checkbox {
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.agree_checkbox:last-child {
  margin-bottom: 0;
}

.button_box {
  width: 100%;
}

.button {
  cursor: pointer;
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #2e384b;
}

.button.disabled {
  opacity: 0.5;
  cursor: default;
}

.email_fail_box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 15px;
}

.email_fail_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.email_fail_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.half_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.one_third_input {
  width: 70%;
}

.input_button {
  cursor: pointer;
  padding: 20px 40px;
  height: 62px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #d1d5db;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border: 0;
  outline: none;
  transition: 0.3s all ease-in-out;
}

.active .input_button {
  background-color: #2e384b;
}

.success .input_button {
  background-color: #ff5360;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 110px 20px;
    box-sizing: border-box;
  }

  .inner {
    max-width: 1520px;
    margin: 0 auto;
  }

  .inner_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /* email */
  .email_box {
    max-width: 100%;
  }

  .step_text {
    font-size: 15px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }

  .email_input_box {
    margin-bottom: 50px;
  }

  .email_input {
    font-size: 14px;
    // line-height: 2.14;
  }

  .email_input::placeholder {
    font-size: 14px;
    // line-height: 2.14;
  }

  .email_success_img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }

  .email_success_text {
    font-size: 13px;
    line-height: 2.31;
  }

  .all_checkbox {
    margin-bottom: 20px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #efefef;
    display: flex;
    align-items: center;
  }

  .agree_text {
    font-size: 12px;
    line-height: 2.14;
  }

  .agree_checkbox_inner {
    margin-bottom: 30px;
  }

  .agree_checkbox {
    padding: 0 0 0 20px;
    width: 100%;
  }

  .agree_checkbox:nth-child(2) {
    align-items: initial;
  }

  .button {
    font-size: 15px;
    font-weight: bold;
    line-height: 2;
    padding: 16px 0;
  }

  .email_fail_text {
    font-size: 13px;
    line-height: 2.31;
  }

  .input_button {
    padding: 20px;
    font-size: 15px;
  }
}

@media screen and (max-width: 324px) {
  .agree_text {
    font-size: 11px;
    line-height: 2.14;
  }
}
