// 전체

.container {
  padding: 71.5px 20px 0;
  box-sizing: border-box;
  min-height: calc(100vh - 240px);
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 100px auto 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_box {
  width: 100%;
  max-width: 450px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title_box,
.img_box,
.text_box {
  margin-bottom: 50px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #101e32;
}

.text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #656565;
}

.button_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  text-decoration: none;
  width: 240px;
  padding: 20px 0;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2e384b;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .inner {
    margin: 60px auto 30px;
  }

  .title {
    font-size: 24px;
  }
}
