.sub_container {
  position: relative;
}

.container_bg {
  display: block;
  width: 100%;
  height: 1080px;
  object-fit: cover;
}

.inner {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.inner_box {
  height: 100%;
  display: flex;
  align-items: center;
}

.sub_title_box {
  margin-bottom: 20px;
}

.sub_title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba($color: #ffffff, $alpha: 0.7);
}

.title_box {
  margin-bottom: 80px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #fff;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .container_bg {
    height: 760px;
  }

  .inner_box {
    justify-content: center;
    text-align: center;
  }

  .sub_title {
    font-size: 15px;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }
}

@media screen and (max-width: 400px) {
  .pc_br {
    display: none;
  }

  .mobile_br {
    display: block;
  }
}
