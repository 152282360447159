.container {
  // max-width: 1920px;
  opacity: 0;
  visibility: hidden;
  height: 0;
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container.active {
  opacity: 1;
  visibility: visible;
  height: 100vh;
  transition: 0.3s all ease-in-out;
  display: block;
}

.sub_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: rgba($color: #000000, $alpha: 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.inner_box {
  padding: 0 20px;
  box-sizing: border-box;
}

/* 헤더 */

.header {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.title_box {
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.close_button_box {
  cursor: pointer;
}

.content_inner {
  width: 100%;
}

.content_media_box {
  position: relative;
}

.content_media {
  cursor: pointer;
  width: 100%;
}

.media_title_inner {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 100%;
  display: flex;
  align-items: center;
}

.media_sub_title_box {
  padding: 3px 10px;
  box-sizing: border-box;
  border-radius: 15px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: solid 1px #fff;
  background-color: #000;
}

.media_sub_title {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.media_title_box {
  margin-left: 20px;
}

.media_title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.media_start_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media_play_box {
  cursor: pointer;
  outline: none;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .inner {
    max-width: 100%;
  }

  /* 헤더 */

  .header {
    padding: 10px;
  }

  .title {
    font-size: 18px;
  }

  /* 컨텐트 */

  .media_title_inner {
    top: 0;
    left: 0;
    bottom: -40px;
    align-items: flex-end;
  }

  .media_title {
    font-size: 15px;
    line-height: 2;
  }
}
