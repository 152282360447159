.sub_container {
  position: relative;
  margin-bottom: 200px;
}

.container_bg {
  height: 1080px;
  width: 100%;
  display: block;
}

.inner {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_box {
  padding: 100px;
  box-sizing: border-box;
  border-radius: 20px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba($color: #000000, $alpha: 0.5);
}

.title_box {
  margin-bottom: 50px;
}

.title {
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    margin-bottom: 100px;
  }

  .container_bg {
    height: 700px;
  }

  .content_box {
    padding: 30px;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 28px;
    line-height: 1.33;
  }

  .text {
    font-size: 14px;
    line-height: 1.67;
  }
}

@media screen and (max-width: 400px) {
  .pc_br {
    display: none;
  }

  .mobile_br {
    display: block;
  }
}
