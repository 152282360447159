.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.img_profile {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #efefef;
  box-sizing: border-box;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.header_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 86px;
}

.title_inner {
  display: flex;
  align-items: center;
  position: relative;
}

.title_box {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  width: 280px;
}

.sub_title_box {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  width: 280px;
}

.hr {
  position: absolute;
  right: 300px;
  top: 10px;
  margin: 0;
  border: 0.5px solid #ddd;
  height: 36px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.sub_title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.004;
  letter-spacing: normal;
  text-align: left;
  color: #d1d5db;
}

.change_box {
  text-decoration: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px 25px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.change_button {
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #56677e;
}

.change_box:hover {
  border: solid 1px #ff5360;
}

.change_box:hover .change_button {
  color: #ff5360;
}

.content_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.engineer_box {
  width: 30%;
  padding: 50px 30px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.engineer_charge_box {
  margin-bottom: 50px;
}

.engineer_charge {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.engineer_profile_box {
  margin-bottom: 30px;
}

.engineer_name_box {
  margin-bottom: 15px;
}

.engineer_name {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.engineer_tell_box {
  margin-bottom: 60px;
}

.engineer_tell {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #32c4ff;
}

.engineer_visit_box {
  border-radius: 30px;
  border: solid 1px #ff5360;
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
}

.engineer_visit {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
  text-align: center;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .title_inner {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  .title_box {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    width: 50%;
  }

  .sub_title_box {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    width: 50%;
  }

  .hr {
    right: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 23px;
  }

  .title {
    font-size: 20px;
    line-height: 3;
  }

  .sub_title {
    font-size: 18px;
    line-height: 3.33;
    text-align: right;
  }

  .change_box {
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }

  .change_button {
    font-size: 15px;
    line-height: 2;
  }

  .content_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  .engineer_box {
    width: 100%;
    padding: 40px 30px 30px;
    margin-bottom: 50px;
  }

  .engineer_charge_box {
    margin-bottom: 30px;
  }

  .engineer_profile_box {
    margin-bottom: 20px;
  }

  .engineer_name {
    font-size: 15px;
    font-weight: bold;
    line-height: 0.87;
  }

  .engineer_tell_box {
    margin-bottom: 50px;
  }

  .engineer_tell {
    font-size: 15px;
    line-height: 1.27;
  }

  .engineer_visit_box {
    padding: 16px 0;
  }

  .engineer_visit {
    font-size: 15px;
  }
}

// 추가

.text {
  font-size: 18px;
  font-weight: 700;
}
