.sub_container {
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 140px 20px 50%;
  box-sizing: border-box;
  position: relative;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.sub_inner {
  width: 100%;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 40px;
}

.title {
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0c1522;
}

.text {
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #696969;
}

.img_right_box {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 12%;
  right: 7%;
  display: flex;
  justify-content: flex-end;
}

.right_img {
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.top_img_inner {
  transition: all 0.3s ease-in-out;
  position: absolute;
  display: flex;
  z-index: 1;
  top: 30%;
  left: 3%;
  width: 100%;
}

.img_box {
  transition: all 0.3s ease-in-out;
  width: 17.7%;
  height: 240px;
  margin-right: 100px;
}

.img_box:last-child {
  margin-right: 0;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.bottom_img_inner {
  transition: all 0.3s ease-in-out;
  position: absolute;
  display: flex;
  z-index: 1;
  top: 55%;
  left: -14%;
  width: 100%;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1750px) {
  .right_img {
    width: 90%;
  }

  .img_box {
    height: 200px;
  }
}

@media screen and (max-width: 1550px) {
  .img_box {
    margin-right: 50px;
  }
}

@media screen and (max-width: 1350px) {
  .title {
    font-size: 38px;
  }

  .text {
    font-size: 16px;
    line-height: 1.56;
  }

  .img_right_box {
    top: 18%;
  }

  .right_img {
    width: 70%;
  }

  .top_img_inner {
    top: 33%;
  }

  .bottom_img_inner {
    top: 58%;
  }

  .img_box {
    height: 170px;
  }
}

@media screen and (max-width: 1150px) {
  .img_right_box {
    top: 23%;
    right: 20px;
  }

  .right_img {
    width: 55%;
  }

  .top_img_inner {
    top: 35%;
  }

  .bottom_img_inner {
    top: 60%;
  }

  .img_box {
    height: 150px;
  }
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 80px 20px 60%;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0c1522;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
  }

  .img_right_box {
    top: 250px;
  }

  .right_img {
    width: 55%;
  }

  .top_img_inner {
    top: 300px;
  }

  .bottom_img_inner {
    top: 480px;
  }

  .img_box {
    width: 190px;
    height: 150px;
  }
}

@media screen and (max-width: 930px) {
  .sub_container {
    padding: 80px 20px 65%;
  }

  .img_right_box {
    top: 260px;
  }

  .right_img {
    width: 45%;
  }

  .img_box {
    margin-right: 30px;
    width: 170px;
    height: 130px;
  }
}

@media screen and (max-width: 790px) {
  .sub_container {
    padding: 80px 20px 70%;
  }

  .right_img {
    width: 35%;
  }

  .top_img_inner {
    top: 300px;
  }

  .bottom_img_inner {
    top: 420px;
  }

  .img_box {
    width: 140px;
    height: 100px;
  }

  .mobile_br {
    display: block;
  }
}

@media screen and (max-width: 650px) {
  .img_right_box {
    top: 280px;
  }

  .right_img {
    width: 30%;
  }

  .top_img_inner {
    top: 310px;
  }

  .bottom_img_inner {
    top: 410px;
  }

  .img_box {
    margin-right: 20px;
    width: 110px;
    height: 70px;
  }

  .img {
    border-radius: 10px;
  }
}

@media screen and (max-width: 500px) {
  .img_right_box {
    top: 300px;
    width: 120px;
    height: 147px;
  }

  .right_img {
    width: 100%;
  }

  .top_img_inner {
    top: 320px;
  }

  .bottom_img_inner {
    top: 380px;
  }

  .img_box {
    margin-right: 15px;
    width: 64px;
    height: 45px;
  }
}
