/* content */

.title_inner {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.fitness_title_box,
.fitness_active {
  width: 200px;
  cursor: pointer;
  position: relative;
  transition: all 0s ease-in-out;
}

.bar_title_box,
.bar_active_box {
  width: 200px;
  cursor: pointer;
  position: relative;
  transition: all 0s ease-in-out;
}

.cafe_title_box,
.cafe_active_box {
  width: 200px;
  cursor: pointer;
  position: relative;
  transition: all 0s ease-in-out;
}

.cafe_active_box .title,
.bar_active_box .title,
.fitness_active .title {
  cursor: pointer;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
  line-height: 1;
  transition: all 0s ease-in-out;
}

.title {
  transition: all 0s ease-in-out;
  cursor: pointer;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #d1d5db;
  line-height: 1.2;
}

.hr {
  width: 1px;
  height: 36px;
  margin: 0;
  background-color: #ddd;
  position: absolute;
  top: 0;
  right: 30px;
}

.content_inner {
  margin-bottom: 20px;
}

.content_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px 0;
}

.img_box {
  width: 48%;
  height: 240px;
}

.img {
  border-radius: 25px;
  width: 100%;
  height: 100%;
}

/* 아래 텍스트 */

.caution_inner {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  margin-bottom: 40px;
  align-items: center;
}

.caution_text_box {
  margin-left: 10px;
}

.caution_text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.caution_text:last-child {
  line-height: 1.56;
}

@media screen and (max-width: 1024px) {
  .title_inner {
    margin-bottom: 30px;
  }

  .fitness_title_box,
  .fitness_active {
    width: 100px;
    cursor: pointer;
    position: relative;
  }

  .bar_title_box,
  .bar_active_box {
    width: 100px;
    cursor: pointer;
    position: relative;
  }

  .cafe_title_box,
  .cafe_active_box {
    width: 70px;
    cursor: pointer;
    position: relative;
  }

  .cafe_active_box .title,
  .bar_active_box .title,
  .fitness_active .title {
    font-size: 20px;
    line-height: 1;
  }

  .title {
    font-size: 18px;
    line-height: 1.2;
  }

  .hr {
    height: 22px;
    right: 10px;
  }

  .content_inner {
    margin-bottom: 30px;
  }

  .content_box {
    gap: 10px 0;
  }

  /* 아래 컨텐츠 */

  .caution_text:last-child {
    line-height: 1.13;
  }

  .caution_inner {
    padding: 10px;
  }

  .caution_text_box {
    margin-left: 10px;
  }

  .caution_text {
    font-size: 14px;
    line-height: 1.13;
  }
}

@media screen and (max-width: 500px) {
  .img_box {
    height: 160px;
  }
}

@media screen and (max-width: 360px) {
  .img_box {
    height: 120px;
  }
}
