.sub_container {
  padding: 71.5px 20px 0;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
  padding: 50px 0 75px;
  box-sizing: border-box;
}

.inner_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub_inner_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden_input {
  display: none;
}

.profile_inner {
  cursor: pointer;
  position: relative;
}

.profile_change_img_box {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
}

.nick_name_box {
  margin: 20px 0 40px;
}

.img_profile {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.nick_name {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.usage_status_box {
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  padding: 16px 30px;
  box-sizing: border-box;
  border-radius: 26.5px;
  background-color: #ff5360;
}

.usage_status {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.account_inner {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.account_info_box {
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  width: 100%;
}

.account_info_title_box {
  padding: 20px;
  box-sizing: border-box;
}

.account_info_title {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.account_info_nick_name_box {
  padding: 20px 40px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
}

.account_info_nick_name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.account_info_change_box,
.account_info_password_change_box {
  display: flex;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  padding: 20px 40px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-repeat: no-repeat;
  background-position: 95%;
}

.account_info_password_change_box {
  border-radius: 0 0 10px 10px;
  box-shadow: none;
}

.account_info_change_box:hover,
.account_info_password_change_box:hover {
  background-color: #f9f9f9;
}

.account_info_change_button {
  outline: none;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.secession_info_box {
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
}

.secession_change_box {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #ffffff;
  padding: 20px 40px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-repeat: no-repeat;
  background-position: 95%;
}

.secession_change_box {
  border-radius: 10px;
  box-shadow: none;
}

.secession_change_box:hover {
  background-color: #f9f9f9;
}

.secession_list_button {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.secession_change_button {
  outline: none;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

// 추가 내용

.basket_count {
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  border-radius: 10px;
  background-color: #ff5360;
  padding: 2px 5px 1px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.69;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 50px 0;
  }

  .nick_name_box {
    margin: 20px 0 30px;
  }

  .nick_name {
    font-size: 20px;
    font-weight: 500;
  }

  .usage_status_box {
    margin-bottom: 60px;
    padding: 14px 20px;
  }

  .usage_status {
    font-size: 15px;
    font-weight: 500;
  }

  .account_inner {
    margin-bottom: 0;
  }

  .account_info_box {
    margin-bottom: 20px;
  }

  .account_info_nick_name_box {
    padding: 20px;
  }

  .account_info_change_box,
  .account_info_password_change_box,
  .secession_change_box {
    padding: 20px;
  }

  .account_info_change_button {
    padding: 0;
  }

  .secession_change_button {
    outline: none;
    border: 0;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #969696;
  }
}
