.container {
  background-color: #f9f9f9;
}

.sub_container {
  display: flex;
  padding: 40px 0 40px 50px;
  transition: all 0.3s ease-in-out;
}

.inner {
  max-width: 1520px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.inner_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logo_box {
  margin-right: 128px;
}

.info_menu_box {
  display: flex;
  margin-bottom: 40px;
}

.info_menu_item {
  margin-right: 80px;
  transition: all 0.3s ease-in-out;
}

.info_menu_item:last-child {
  margin-right: 0;
}

.info_menu_item_text {
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #656565;
}

.info_text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.77;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.icon_inner {
  width: 20%;
  display: flex;
}

.icon_box {
  height: 0;
  cursor: pointer;
  margin-right: 30px;
}

.icon_box:last-child {
  margin-right: 0;
}

.mobile_br {
  display: none;
}

.mobile_b {
  font-weight: 400;
}

@media screen and (max-width: 1250px) {
  .logo_box {
    margin-right: 80px;
  }

  .info_menu_item {
    margin-right: 40px;
  }

  .info_menu_item:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .sub_container {
    flex-direction: column;
    padding: 40px 20px;
  }

  .inner_box {
    flex-direction: column;
  }

  .logo_box {
    margin-right: 0;
    margin-bottom: 50px;
  }

  .info_menu_box {
    flex-wrap: wrap;
  }

  .mobile_b {
    display: none;
  }

  .mobile_br {
    display: block;
  }

  .info_box {
    margin-bottom: 35px;
  }

  .icon_inner {
    width: 100%;
    justify-content: flex-end;
  }
}
