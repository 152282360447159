.sub_container {
  padding: 0 20px;
  box-sizing: border-box;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.inner_box {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checklist_button {
  cursor: pointer;
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 30px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  background-color: #32c4ff;
  box-shadow: 0 3px 10px 0 rgba(50, 196, 255, 0.3);
  border-radius: 35px;
}

.checklist_button:hover {
  padding-right: 130px;
}

.checklist_icon_box {
  height: 64px;
}

.hide_text_box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
}

.hide_text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: all 1s ease-in-out;
  opacity: 0;
  white-space: nowrap;
}

.checklist_button:hover .hide_text_box {
  visibility: visible;
  opacity: 1;
}

.checklist_button:hover .hide_text {
  opacity: 1;
}

.img_box {
  margin-bottom: 80px;
}

.title_box {
  margin-bottom: 60px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.text_box {
  margin-bottom: 80px;
}

.text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #656565;
  text-align: center;
}

.button {
  cursor: pointer;
  text-decoration: none;
  padding: 20px 56px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #2e384b;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.mobile_header_inner {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header_inner,
  .content_step_inner {
    display: none;
  }

  .mobile_header_inner {
    display: block;
  }

  .mobile_header_progressbar_box {
    margin-bottom: 25px;
    width: 100%;
    height: 4px;
    background-color: rgba($color: #2e384b, $alpha: 0.2);
  }

  .mobile_header_progressbar {
    width: 56%;
    height: 4px;
    background-color: #ff5360;
  }

  .select_process_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: initial;
    align-items: inherit;
    flex-direction: column;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .mobile_select_text_box {
    margin-bottom: 10px;
  }

  .mobile_select_text {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .mobile_select_process_title_box {
    display: flex;
    align-items: center;
  }

  .select_process_img_box {
    margin-right: 0;
    margin-left: 10px;
  }

  .select_process_title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5360;
  }

  .checklist_button {
    bottom: 65px;
  }

  .checklist_button:hover {
    padding-right: 110px;
  }

  .hide_text_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    white-space: nowrap;
  }

  .hide_text {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    opacity: 0;
    white-space: nowrap;
  }

  .title_box {
    margin-bottom: 30px;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .text_box {
    margin-bottom: 80px;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
  }

  .button_box {
    position: fixed;
    bottom: 30px;
  }

  .button {
    padding: 15px 40px;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
  }
}
