.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

:global(#ad-container > iframe) {
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  // transition: opacity ease-in-out 5s;
}

:global(#ad-container > iframe.active) {
  opacity: 1;
  visibility: visible;
}
