// 전체

.container {
  width: 100%;
  height: 100%;
}

// 테이블

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.thead_tr {
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #f9f9f9;
}

.thead_td {
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #56677e;
  &:last-child {
    text-align: right;
    padding-right: 40px;
  }
}

// 테이블 바디

.tbody_tr {
  cursor: pointer;
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #e9e9e9;
  }
}

.tbody_td,
.tbody_td_payment {
  width: 16%;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
  padding: 12px 0;
  box-sizing: border-box;
}

.tbody_td_payment {
  padding: 12px 40px;
  text-align: right;
}

// 상태에 따른 값

.tbody_td_advertisement,
.tbody_td_wating,
.tbody_td_refuse,
.tbody_td_completion,
.tbody_td_rogress {
  width: 16%;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  padding: 12px 0;
  box-sizing: border-box;
}

// 결제 대기
.tbody_td_wating {
  color: #969696;
}

// 광고 거절
.tbody_td_refuse {
  color: #ff5360;
}

// 결제완료
.tbody_td_completion {
  color: #32c4ff;
}

// 광고 진행 중
.tbody_td_rogress {
  color: #56677e;
}

// 광고 완료
.tbody_td_advertisement {
  color: #dedede;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 30px 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.mobile_tbody_td {
  display: none;
}

.empty_box {
  min-height: calc(100vh - 610px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty_text {
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  // 테이블

  .thead_tr {
    display: none;
  }

  // 테이블 바디

  .tbody_td,
  .tbody_td_payment {
    display: none;
  }

  // 상태에 따른 값

  .tbody_td_advertisement,
  .tbody_td_wating,
  .tbody_td_refuse,
  .tbody_td_completion,
  .tbody_td_rogress {
    display: none;
  }

  .mobile_tbody_td_advertisement,
  .mobile_tbody_td_wating,
  .mobile_tbody_td_refuse,
  .mobile_tbody_td_completion,
  .mobile_tbody_td_rogress {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: initial;
  }

  // 결제 대기
  .mobile_tbody_td_wating {
    color: #969696;
  }

  // 광고 거절
  .mobile_tbody_td_refuse {
    color: #ff5360;
  }

  // 결제완료
  .mobile_tbody_td_completion {
    color: #32c4ff;
  }

  // 광고 진행 중
  .mobile_tbody_td_rogress {
    color: #56677e;
  }

  // 광고 완료
  .mobile_tbody_td_advertisement {
    color: #dedede;
  }

  .mobile_tbody_td {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 15px;
    box-sizing: border-box;
    &:last-child {
      padding-bottom: 0;
    }
  }

  .mobile_tbody_sub_td {
    padding-top: 15px;
    box-sizing: border-box;
  }

  .mobile_tbody_text {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #56677e;
  }

  .mobile_tbody_number_text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: #2e384b;
  }

  .mobile_tbody_price_text {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #2e384b;
  }

  .mobile_tbody_date_text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    color: #969696;
  }
}
