.sub_container {
  padding: 71.5px 20px 150px;
  box-sizing: border-box;
}

// 뒤로가기

.back_inner {
  max-width: 1200px;
  position: relative;
  margin: 60px auto 0;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.back_box {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: -50px;
}

.back_img_box {
  margin-right: 10px;
  cursor: pointer;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

// 사인하는 박스

.inner {
  max-width: 1200px;
  margin: 0 auto;
  height: 100vh;
}

.sign_box {
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  margin-bottom: 50px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

// 버튼

.button_inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_box {
  width: 20%;
  padding-right: 25px;
  box-sizing: border-box;
}

.button_box:last-child {
  padding-right: 0;
  padding-left: 25px;
}

.reset_button {
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #d1d5db;
}

.complete_button {
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  border: solid 1px #2e384b;
  background-color: #2e384b;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .back_inner {
    margin: 15px auto 0;
    padding-bottom: 60px;
  }

  .back_box {
    left: 0px;
  }

  .back_text {
    font-size: 15px;
  }

  // 사인하는 박스

  .sign_box {
    padding: 20px;
  }

  .sign_move_box {
    width: 120px;
    height: 50px;
    border: solid 3px #ff5360;
  }

  .sign_move {
    width: 30px;
    height: 30px;
    top: -15px;
    right: -15px;
  }

  // check box

  .check_inner {
    align-items: initial;
  }

  .check_label {
    font-size: 14px;
    line-height: 1.71;
  }

  // 버튼

  .button_box {
    width: 50%;
    padding-right: 10px;
  }

  .button_box:last-child {
    padding-right: 0;
    padding-left: 10px;
  }

  .button {
    width: 100%;
    padding: 20px 0;
    font-size: 15px;
  }

  .mobile_br {
    display: block;
  }
}
