.sub_container {
  transition: all 0.3s ease-in-out;
  margin-bottom: 150px;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.content_box {
  margin-bottom: 130px;
}

.sub_title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 40px;
}

.sub_title {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 80px;
}

.title {
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.list_top_box {
  margin-bottom: 30px;
}

.list_box,
.bottom_list_box {
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

.list_box,
.bottom_list_box {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.list_box::-webkit-scrollbar,
.bottom_list_box::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.list_img_box {
  margin-right: 20px;
}

.list_img_box:last-child {
  margin-right: 0;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    margin-bottom: 100px;
  }

  .sub_title_box {
    margin-bottom: 20px;
  }

  .sub_title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.87;
    text-align: center;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
    text-align: center;
  }

  .mobile_br {
    display: block;
  }
}
