.file_upload_button {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: solid 1px #d1d5db;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file_upload_preview_box {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  border: solid 1px #d1d5db;
  position: relative;
}

.file_upload_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.file_upload_file {
  border-radius: 10px;
  box-sizing: border-box;
  padding: 2px;
  width: 100%;
  max-width: 90%;
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.file_upload_delete_img {
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 5px;
  right: 5px;
}

.hidden_file_input {
  display: none;
}

@media screen and (max-width: 1024px) {
  .file_upload_button,
  .file_upload_preview_box {
    margin: 0;
  }
}

@media screen and (max-width: 499px) {
  .file_upload_button,
  .file_upload_preview_box {
    width: 70px;
    height: 70px;
  }
}
