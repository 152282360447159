.sub_container {
  transition: all 0.3s ease-in-out;
  padding: 150px 20px 120px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.inner_box {
  text-align: center;
  margin-bottom: 100px;
}

.title_box {
  margin-bottom: 75px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0c1522;
}

.text {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #696969;
}

.select_inner {
  display: flex;
  justify-content: space-evenly;
}

.select_box {
  width: 37%;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 50px 118px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select_box:hover {
  border: solid 1px #ff5360;
}

.select_img_box {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 35px;
}

.select_img {
  width: 100%;
}

.select_title_box {
  margin-bottom: 30px;
}

.select_title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.78;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

.select_sub_title_box {
  margin-bottom: 40px;
}

.select_sub_title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  color: #696969;
}

.select_hr {
  margin: 0;
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
}

.select_list_inner {
  width: 100%;
  text-align: left;
  margin: 40px 0 30px;
}

.select_list_item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.sub_select_list_item {
  display: flex;
}

.select_list_item_img_box {
  margin-right: 10px;
}

.select_list_item_text,
.sub_select_list_item_text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.denger_text_box {
  margin-top: 25px;
  display: flex;
}

.denger_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: left;
  color: #ff5360;
}

.denger_text:first-child {
  margin-right: 5px;
}

.button_box {
  width: 100%;
  padding: 20px 15px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  background-color: #fff;
}

.button_sub_box {
  display: flex;
  align-items: center;
}

.button_img_box {
  margin-right: 10px;
}

.button_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.button_bottom_text_box {
  margin-top: 18px;
}

.button_bottom_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #656565;
  text-indent: 25px;
}

.mobile_slider_inner,
.mobile_br {
  display: none;
}

@media screen and (max-width: 1480px) {
  .select_box {
    padding: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 80px 20px 50px;
  }

  .inner_box {
    margin-bottom: 50px;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
  }

  .select_inner {
    display: none;
  }

  .mobile_slider_inner {
    overflow: hidden;
    display: block;
  }

  .mobile_slider_box {
    width: 100%;
    height: 100%;
    display: flex;
    min-height: 840px;
  }

  .select_box {
    width: 100%;
    padding: 50px 20px;
  }

  .select_img_box {
    width: 50%;
    margin-bottom: 30px;
  }

  .select_title_box {
    margin-bottom: 30px;
  }

  .select_title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
  }

  .select_sub_title_box {
    margin-bottom: 20px;
  }

  .select_sub_title {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .select_list_inner {
    margin: 30px 0 30px;
  }

  .select_list_item {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .select_list_item_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    // height: 42px;
  }

  .button_text {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
  }

  .button_bottom_text_box {
    margin-top: 15px;
  }

  .button_bottom_text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.81;
    letter-spacing: normal;
  }

  .mobile_Dots_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .mobile_Dots,
  .active_mobile_Dots {
    cursor: pointer;
    margin-right: 20px;
  }

  // .mobile_Dots:last-child,
  // .active_mobile_Dots {
  //     margin-right: 0;
  // }

  .mobile_Dot {
    background-color: #d1d5db;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .active_mobile_Dots .mobile_Dot {
    background-color: #ff5360;
    transition: all 0.2s ease-in-out;
  }

  .mobile_br {
    display: block;
  }

  .denger_text_box {
    margin-top: 15px;
  }

  .denger_text {
    font-size: 14px;
  }
}
