.content_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.content {
  width: 410px;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
}

.title_bg_box {
  display: flex;
  justify-content: center;
  position: relative;
}

.title_bg {
  height: 220px;
  border-radius: 10px;
}

.led_icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 145px;
  width: 150px;
  height: 150px;
}

.list_inner {
  padding: 105px 25px 50px;
  box-sizing: border-box;
}

.list_title_box {
  margin-bottom: 20px;
}

.list_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

.list_text_box {
  margin-bottom: 40px;
}

.list_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #656565;
}

.list_hr {
  border: 0.5px solid #dfdfdf;
}

.list_item_inner {
  margin-top: 40px;
  padding: 0 45px;
  box-sizing: border-box;
}

.list_item_box {
  display: flex;
  align-items: center;
}

.sub_list_item_box {
  margin-top: 10px;
  display: flex;
}

.list_item_text_box {
  margin-left: 10px;
}

.list_item_text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.sub_list_item_text {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.denger_text_box {
  margin-top: 25px;
  display: flex;
}

.denger_text_box + .denger_text_box {
  margin-top: 12px;
}

.denger_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.5;
  text-align: left;
  color: #ff5360;
}

.denger_text:first-child {
  margin-right: 5px;
}

.caution_inner {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 13px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  margin-bottom: 40px;
}

.caution_text_box {
  margin-left: 10px;
}

.caution_text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1024px) {
  .content_box {
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: column;
    margin-bottom: 0;
  }

  .content {
    width: 100%;
    border-radius: 10px;
    border: solid 1px #dfdfdf;
    margin-bottom: 30px;
  }

  .title_bg {
    border-radius: 10px;
  }

  .led_icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
  }

  .list_title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .list_text_box {
    margin-bottom: 30px;
  }

  .list_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .list_item_inner {
    margin-top: 30px;
    padding: 0 0 0 20px;
    box-sizing: border-box;
  }

  .list_item_box {
    display: flex;
    align-items: center;
  }

  .list_item_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.67;
    letter-spacing: normal;
    text-align: left;
    color: #696969;
  }

  .sub_list_item_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    text-align: left;
    color: #696969;
  }

  .caution_inner {
    padding: 10px;
    align-items: initial;
  }

  .caution_text_box {
    margin-left: 10px;
  }

  .caution_text {
    font-size: 14px;
    line-height: 1.13;
  }

  .denger_text_box {
    margin-top: 15px;
  }

  .denger_text_box + .denger_text_box {
    margin-top: 8px;
  }

  .denger_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .title_bg {
    width: 100%;
    height: 170px;
    border-radius: 10px;
  }

  .led_icon {
    width: 100px;
    height: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 120px;
  }
}
