// 공통

.category_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text,
.category_text_b {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
  margin-left: 10px;
}

.category_text_b {
  font-weight: 500;
  color: #ff5360;
}

.category_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 0;
}

// 선택된 스토어

.selected_store_inner {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #efefef;
}

.selected_header_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.store_title_box {
  display: flex;
  align-items: center;
}

.store_text,
.store_text_b {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin-left: 10px;
  color: #565656;
}

.store_text_b {
  color: #ff5360;
}

.store_select_box {
  display: flex;
  align-items: center;
}

.all_button {
  width: 150px;
}

.store_hide_check_box {
  display: none;
}

.store_check_box_label {
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px;
  box-sizing: border-box;
  display: block;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.store_check_box {
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  position: relative;
}

.store_check_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0c1522;
}

.store_check_box_checked {
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.store_hide_check_box:checked + .store_check_box_label {
  border: 1px solid #ff5360;
}

.store_hide_check_box:checked + .store_check_box_label .store_check_text {
  color: #ff5360;
}

.store_hide_check_box:checked + .store_check_box_label .store_check_box {
  background-color: #ff5360;
  border: 1px solid #ff5360;
}

.selected_list_inner {
  display: flex;
  gap: 20px 10px;
  flex-wrap: wrap;
}

.selected_list_box,
.not_selected_list_box {
  cursor: pointer;
  width: calc((100% - 40px) / 4);
  display: flex;
  flex-direction: column;
}

.not_selected_list_box {
  cursor: initial;
  opacity: 0.5;
}

.selected_store_item,
.active_selected_store_item {
  position: relative;
  z-index: -1;
  width: calc(100% - 6px);
  height: calc(150px - 6px);
  border: solid 3px transparent;
  border-radius: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.store_img {
  width: 100%;
  height: 100%;
}

.active_selected_store_item {
  border: solid 3px #ff5360;
  border-radius: 10px;
}

.acive_selected_img {
  position: absolute;
  top: 10px;
  right: 10px;
}

.selected_store_info_inner {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selected_store_info_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
  margin-bottom: 15px;
}

.selected_store_info_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.selected_store_info_avg_box {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select_store_info_avg_box {
  display: flex;
  align-items: center;
}

.selected_store_info_avg_text,
.select_store_info_avg_text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

.select_store_info_avg_text {
  margin-right: 10px;
}

.selected_store_info_avg_text_b {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

.selected_store_info_avg_text_hr {
  width: 1px;
  height: 15px;
  background-color: #dfdfdf;
  margin: 0 20px;
}

.selected_store_info_text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  color: #969696;
}

.selected_store_info_text_box {
  display: flex;
  align-items: center;
}

.selected_store_info_rest {
  width: 2px;
  height: 2px;
  margin: 0 8px;
  background-color: #969696;
}

// 전체 스토어

.select_text_box {
  margin: 30px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.select_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.67;
  color: #2e384b;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1024px) {
  .category_inner {
    margin: 0 auto 50px;
  }

  .mobile_category_title {
    font-size: 15px;
  }

  .store_text,
  .store_text_b {
    font-size: 14px;
  }

  .selected_store_inner {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    border: solid 1px #efefef;
  }

  .selected_header_box {
    display: flex;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 20px;
  }

  .store_title_box {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .store_select_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .store_check_box {
    width: 22px;
    height: 22px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    position: relative;
  }

  .store_check_text {
    font-size: 15px;
  }
}

@media screen and (max-width: 890px) {
  // 선택된 스토어

  .selected_list_box,
  .not_selected_list_box {
    width: calc((100% - 40px) / 3);
  }
}

@media screen and (max-width: 700px) {
  // 선택된 스토어

  .selected_list_box,
  .not_selected_list_box {
    width: calc((100% - 10px) / 2);
  }
}

@media screen and (max-width: 500px) {
  .store_select_box {
    justify-content: flex-start;
  }

  .store_check_box_label {
    padding: 10px;
  }

  /* 페이지네이션 */

  .pagination_button {
    width: 25px;
    height: 25px;
  }

  .pagination_number_inner {
    margin: 0 20px;
  }

  .pagination_number_box {
    margin-right: 20px;
  }

  .pagination_number_box:last-child {
    margin-right: 0;
  }

  .pagination_number {
    font-size: 14px;
    font-weight: 500;
  }

  .active_pagination_number {
    font-size: 14px;
  }
}

@media screen and (max-width: 460px) {
  .selected_list_box,
  .not_selected_list_box {
    width: 100%;
  }
}
