.sub_container {
  position: relative;
  display: flex;
  overflow-y: hidden;
}

/* side_bar */

.side_container {
  width: 300px;
  margin-bottom: -5000px;
  padding-bottom: 5000px;
}

.side_inner {
  height: 100%;
  padding-top: 71.5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.menu_list_box {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.active .menu_list_item_box {
  background-color: #f9f9f9;
}

.menu_list_item_box {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 20px 50px;
  box-sizing: border-box;
}

.menu_list_item_box:hover .list_text {
  color: #ff5360;
}

.menu_list_item_box:hover .img_off {
  display: none;
}

.menu_list_item_box:hover .img_on {
  display: block;
}

.active .list_text {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.active .img_on {
  display: block;
}

.active .img_off {
  display: none;
}

.img_on {
  display: none;
}

.img_off {
  display: block;
}

.list_text {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.select_media_box {
  padding: 30px;
  box-sizing: border-box;
  border-top: 1px solid #dfdfdf;
  height: 100%;
}

.select_text_box {
  margin-bottom: 20px;
}

.select_text {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.play_list_item .select_sub_text {
  color: #ff5360;
}

.select_item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.select_item:hover:after {
  content: 'X';
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 30px;
  background-color: rgba(255, 83, 96, 0.3);
}

.select_item.play_list_item:hover:after {
  content: '플레이리스트에 담긴 영상은\A리셋으로 초기화하실 수 있으십니다.';
  font-size: 8px;
  white-space: pre;
  text-align: center;
  line-height: 1.2;
}

.select_item + .select_item {
  margin-top: 20px;
}

.select_img_box {
  width: 80px;
  height: 45px;
  margin-right: 15px;
}

.select_img {
  width: 80px;
  height: 45px;
  border-radius: 4px;
}

.select_sub_text_box {
  overflow: hidden;
}

.select_sub_text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.bottom_select_inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.select_number_box {
  padding: 25px 30px;
  box-sizing: border-box;
  border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_number_text {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.selecet_count_number {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #969696;
}

.selecet_count_number_b {
  font-weight: bold;
  color: #ff5360;
}

.select_keep_box {
  padding: 30px;
  box-sizing: border-box;
}

.select_keep_text_box {
  margin-bottom: 20px;
}

.select_keep_button {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 100%;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.select_keep_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
}

/* play-list-box */

.inner {
  width: calc(100% - 300px);
  padding-top: 71.5px;
  box-sizing: border-box;
}

.inner_box {
  background-color: #f6f6f6;
  height: 500px;
  overflow-y: scroll;
}

.media_list_inner {
  position: relative;
  width: 100%;
  // overflow-x: scroll;
  margin-bottom: 80px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  &:last-child {
    margin-bottom: 0;
  }
}

.media_list_inner::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.media_list_title_box {
  margin-bottom: 20px;
}

.media_list_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.media_list_box {
  overflow-x: scroll;
  display: flex;
  gap: 0 30px;
}

.media_list_item {
  cursor: pointer;
  width: 286px;
  height: 160px;
  border-radius: 12px;
  border: 3px solid transparent;
  position: relative;
}

.media_list_item.media_list_item_active {
  cursor: pointer;
  width: 286px;
  height: 160px;
  border-radius: 12px;
  border: 3px solid #ff5360;
  position: relative;
}

.media_list_item_img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.media_list_add_button_box {
  width: 38px;
  height: 38px;
  padding: 10px 10px 8px 10px;
  box-sizing: border-box;
  border-radius: 50%;
  backdrop-filter: blur(15px);
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.3s ease-in-out;
}

.media_list_item_active .media_list_add_button_box {
  background-color: rgba($color: #ff5360, $alpha: 0.8);
}

.media_quality_box {
  position: absolute;
  left: 10px;
  bottom: 10px;
  backdrop-filter: blur(5px);
  background-color: rgba($color: #000000, $alpha: 0.3);
  padding: 3px 10px;
  box-sizing: border-box;
  border-radius: 12px;
}

.media_quality {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.media_list_item_title_box {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.media_list_item_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.media_list_item_like_off,
.media_list_item_like_on {
  cursor: pointer;
}

.mobile_footer_inner {
  display: none;
}

// 데이터 없을 떄

.no_data_inner {
  width: 100%;
  height: calc(100vh - 440px);
  min-height: 650px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_data_box {
  display: flex;
  flex-direction: column;
}

.no_data_title_box {
  margin-bottom: 30px;
}

.no_data_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.no_data_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

// 데이터 하나 일 때
.no_single_data_title_box {
  margin: 15px 0;
}

.no_single_data_title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.no_single_data_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.no_single_data_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2e384b;
}

// 추가
.media_left_button_box,
.media_right_button_box {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
  border-radius: 50%;
  background-color: #fff;
}

.media_right_button_box {
  left: initial;
  right: 0;
}

@media screen and (max-width: 1024px) {
  .side_container {
    display: none;
  }

  .inner_box {
    padding-right: 5px;
    box-sizing: border-box;
  }

  .inner {
    width: 100%;
    padding-top: 71.5px;
    box-sizing: border-box;
  }

  .media_list_inner {
    width: 100%;
    overflow-x: initial;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .media_list_title_box {
    margin-bottom: 20px;
  }

  .media_list_title {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #101e32;
  }

  .media_list_box {
    overflow-x: initial;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 20px;
  }

  .media_list_item_box {
    width: calc((100% - 40px) / 3);
  }

  .media_list_item,
  .media_list_item.media_list_item_active {
    width: 100%;
    height: 180px;
  }

  .mobile_footer_inner {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
  }

  .mobile_footer_box {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .mobile_footer_header_box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    box-sizing: border-box;
    width: 100%;
    background-color: #ff5360;
  }

  .mobile_footer_header_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    box-sizing: border-box;
    width: 100%;
    background-color: #ff5360;
  }

  .mobile_footer_select_box {
    position: absolute;
    border-radius: 14px;
    background-color: #2e384b;
    padding: 8px 12px;
    box-sizing: border-box;
    top: -14px;
    right: 20px;
  }

  .mobile_footer_select {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_footer_select_b {
    font-weight: bold;
  }

  .mobile_footer_header_img_box {
    margin-right: 10px;
  }

  .mobile_footer_header_title {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_footer_menu_inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: inset 0 1px 0 0 #dfdfdf;
    background-color: #fff;
  }

  .menu_list_item_box {
    flex-direction: column;
    padding: 10px;
  }

  .list_text {
    margin-left: 0;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .active .menu_list_item_box {
    background-color: #ffffff;
  }

  .active .list_text {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5360;
  }

  //누르면 나오는 메뉴

  .mobile_hide_select_box {
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    z-index: -1;
    animation: close 0.5s;
  }

  @keyframes close {
    from {
      top: -380px;
    }

    to {
      top: 0;
    }
  }

  .active_mobile_hide_select_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    z-index: -1;
    top: -380px;
    transition: all 0.3s ease-in-out;
    animation: open 0.5s;
  }

  @keyframes open {
    from {
      top: 0;
    }

    to {
      top: -380px;
    }
  }

  .mobile_hide_button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
  }

  .mobile_hide_text_box {
    margin-bottom: 24px;
  }

  .mobile_hide_text {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .mobile_hide_menu_item_inner {
    height: 230px;
    overflow-y: scroll;
  }

  .mobile_hide_menu_item_box {
    padding: 12px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .btn_remove_play {
    margin-left: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    width: 24px;
    height: 24px;
    font-weight: bold;
    background-color: #101e32;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .mobile_hide_img_box {
    margin-right: 15px;
  }

  .mobile_hide_img {
    border-radius: 4px;
    width: 80px;
    height: 45px;
  }

  .mobile_hide_menu_item_text_box {
    overflow: hidden;
  }

  .play_list_item .mobile_hide_menu_item_text {
    color: #ff5360;
  }

  .mobile_hide_menu_item_text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #2e384b;
  }

  .mobile_hide_menu_item_button_box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_hide_menu_item_button {
    width: 100%;
    border: 0;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #fff;
    padding: 16px 0;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #101e32;
  }

  // 데이터 없을 떄

  .no_data_inner {
    height: calc(100vh - 500px);
    min-height: 450px;
  }

  .no_data_title {
    font-size: 18px;
  }

  .no_data_text {
    font-size: 14px;
  }

  // 데이터 하나 일 때

  .no_single_data_title {
    font-size: 20px;
    text-align: center;
  }

  .no_single_data_text {
    font-size: 14px;
    text-align: center;
  }

  .media_left_button_box,
  .media_right_button_box {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .mobile_hide_menu_item_inner {
    height: 240px;
    overflow-y: scroll;
  }

  .media_list_box {
    gap: initial;
    justify-content: initial;
  }

  .media_list_item_box {
    width: calc(100% - 6px);
    margin-bottom: 20px;
  }

  .media_list_item,
  .media_list_item.media_list_item_active {
    height: 220px;
  }

  .mobile_hide_menu_item_button {
    font-size: 14px;
    padding: 12px 0;
  }
}
