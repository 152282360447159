.sub_container {
  padding-top: 71.5px;
  box-sizing: border-box;
}

.inner_bg {
  transition: all 0.3s ease-in-out;
  padding: 80px 20px 50px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.sub_title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
}

.sub_title {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.title_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 50px;
}

.title {
  transition: all 0.3s ease-in-out;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.text_box {
  transition: all 0.3s ease-in-out;
  margin-bottom: 80px;
}

.text {
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.select_inner {
  transition: all 0.3s ease-in-out;
  display: flex;
  width: 100%;
  align-items: center;
}

.select_sub_inner {
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}

.recent_select_box {
  width: 48%;
  margin-right: 20px;
}

.select_box {
  transition: all 0.3s ease-in-out;
  width: 100%;
  cursor: pointer;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.select_box::-ms-expand {
  display: none;
  /* 화살표 없애기 for IE10, 11*/
}

.category_select_box {
  transition: all 0.3s ease-in-out;
  width: 100%;
  margin-right: 20px;
}

.search_box {
  transition: all 0.3s ease-in-out;
  width: 100%;
  position: relative;
}

.search_input {
  transition: all 0.3s ease-in-out;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
  outline: none;
  border: solid 1px #dfdfdf;
  border-radius: 10px;
}

.search_input::placeholder {
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.search_input:focus::placeholder {
  color: transparent;
}

.search_button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

/* bottom_container */

.bottom_container {
  padding: 0 20px;
  box-sizing: border-box;
}

.sub_inner {
  transition: all 0.3s ease-in-out;
  padding: 80px 0 140px;
  box-sizing: border-box;
}

.media_inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  transition: all 0.3s ease-in-out;
}

.media_box {
  transition: all 0.3s ease-in-out;
  width: 31.5%;
  cursor: pointer;
  margin: 0;
}

.media_sub_box {
  position: relative;
}

.media_play_button_box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba($color: #000000, $alpha: 0.5);
  padding: 18px 18px 18px 22px;
  box-sizing: border-box;
  border-radius: 50%;
}

.media_play_img {
  width: 100%;
  // max-width: 478px;
  // max-height: 269px;
  height: 100%;
  border-radius: 10px;
}

.media_infor_box {
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.media_infor_icon_box {
  display: flex;
  align-items: center;
}

.media_infor_icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.media_infor_text_box {
  margin-left: 10px;
}

.media_infor_text {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #393939;
}

.media_infor_vim_box,
.media_infor_LED_box {
  transition: all 0.3s ease-in-out;
  padding: 10px 20px 9px;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #ff5360;
}

.media_infor_LED_box {
  background-color: #6c63ff;
}

.media_infor_vim_text,
.media_infor_LED_box {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

@media screen and (max-width: 1130px) {
  .media_inner {
    gap: 20px;
  }

  .sub_inner {
    padding: 50px 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner_bg {
    padding: 50px 20px;
  }

  .sub_title_box {
    margin-bottom: 20px;
  }

  .sub_title {
    font-size: 15px;
    font-weight: 500;
  }

  .title_box {
    margin-bottom: 40px;
  }

  .title {
    font-size: 24px;
  }

  .text_box {
    margin-bottom: 50px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }

  .select_inner {
    display: flex;
    align-items: initial;
    flex-direction: column;
  }

  .select_sub_inner {
    width: 100%;
    margin-bottom: 20px;
  }

  .recent_select_box {
    margin-right: 0;
  }

  .select_box {
    padding: 16px 20px;
    font-size: 15px;
    line-height: 2;
  }

  .category_select_box {
    margin-right: 0;
  }

  .search_box {
    width: 100%;
  }

  .search_input {
    padding: 16px 20px;
    font-size: 15px;
    line-height: 2;
  }

  .search_input::placeholder {
    font-size: 15px;
    line-height: 2;
  }

  .media_box {
    margin-top: 30px;
  }

  .media_sub_box {
    position: relative;
  }

  .media_play_button_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba($color: #000000, $alpha: 0.5);
    padding: 18px 18px 18px 22px;
    box-sizing: border-box;
    border-radius: 50%;
  }

  .media_infor_box {
    margin-top: 10px;
  }

  .media_infor_vim_box,
  .media_infor_LED_box {
    padding: 5px 15px;
  }

  .media_infor_vim_text,
  .media_infor_LED_box {
    font-size: 12px;
    font-weight: 500;
    line-height: 2.33;
  }
}

@media screen and (max-width: 900px) {
  .media_box {
    width: 310px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 700px) {
  .media_inner {
    flex-wrap: initial;
    align-items: center;
    flex-direction: column;
  }

  .media_infor_text {
    font-size: 12px;
  }
}
