.sub_conatiner {
  margin-bottom: 180px;
}

.content_box {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 80px;
}

.inner {
  width: 100%;
  max-width: 1520px;
  padding: 0 20px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sub_inner {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #0c1522;
}

.content_bg_inner {
  position: relative;
  width: 100%;
}

.content_bg {
  width: 100%;
  height: 480px;
  display: block;
}

.text_box {
  margin-bottom: 40px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.sub_title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.right_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.sub_right_title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.sub_content_box {
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  margin: 200px 0 80px;
}

.sub_content_inner {
  padding: 0 20px;
  box-sizing: border-box;
}

.sub_content_img {
  width: 100%;
  height: 480px;
}

.sub_content_menu_inner {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.sub_content_menu_box {
  width: 32%;
  padding: 40px 30px 60px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #efefef;
  display: flex;
  flex-direction: column;
}

.sub_content_menu_text_box {
  margin: 30px 0;
}

.sub_content_menu_text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #101e32;
}

.sub_content_menu_sub_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .content_box {
    margin-bottom: 50px;
  }

  .content_bg {
    height: 240px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #0c1522;
  }

  .text_box {
    margin-bottom: 30px;
  }

  .text,
  .right_text {
    line-height: 3.78;
    text-align: center;
  }

  .sub_title,
  .sub_right_title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.25;
    text-align: center;
  }

  .sub_content_box {
    margin: 100px 0 50px;
  }

  .sub_content_img {
    height: 240px;
  }

  .sub_content_menu_inner {
    justify-content: initial;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }

  .sub_content_menu_box {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
  }

  .sub_content_menu_box:last-child {
    margin-bottom: 0;
  }

  .sub_content_menu_text {
    font-size: 24px;
    line-height: 1.42;
  }

  .pc_br {
    display: none;
  }

  .mobile_br {
    display: block;
  }
}
