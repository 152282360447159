// 공통

.category_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 80px;
  padding: 0 20px;
  box-sizing: border-box;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text,
.category_text_b {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
  margin-left: 10px;
}

.category_text_b {
  font-weight: 500;
  color: #ff5360;
}

// 지역 카테고리

.region_category_inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.region_category_box {
  border: 1px solid #dfdfdf;
  border-right: 0;
  width: calc((100% - 10px) / 4);
  &:last-child {
    border-right: 1px solid #dfdfdf;
  }
}

.region_category_header_box {
  border-bottom: 1px solid #dfdfdf;
  background-color: #efefef;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.region_category_header {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.region_list_box {
  padding: 20px;
  box-sizing: border-box;
  list-style: none;
}

.region_list_itme {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.region_list_itme:last-child {
  margin-bottom: 0;
}

.hide_check_box {
  display: none;
}

.check_box_label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.check_box {
  width: 22px;
  height: 22px;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  margin-right: 10px;
  position: relative;
}

.check_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.check_box_checked {
  position: absolute;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: 1px solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.hide_check_box.active + .check_box_label .check_text {
  color: #ff5360;
}

.hide_check_box.active + .check_box_label .check_box {
  background-color: #ff5360;
  border: 1px solid #ff5360;
}

.check_number {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: rgba(46, 56, 75, 0.5);
}

@media screen and (max-width: 1024px) {
  .category_inner {
    margin: 0 auto 50px;
  }

  .category_title_box {
    margin-bottom: 30px;
  }

  .category_title {
    font-size: 24px;
    line-height: 1.7;
  }

  .category_text,
  .category_text_b {
    display: block;
    margin-left: 0px;
  }

  .category_text_b {
    font-weight: 500;
    color: #ff5360;
  }

  // 지역 카테고리

  .region_list_itme {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .region_category_box {
    width: calc((100% - 10px) / 2);
    &:nth-child(2n) {
      border-right: 1px solid #dfdfdf;
    }
  }

  .check_box {
    width: 18px;
    height: 18px;
  }

  .check_text {
    font-size: 14px;
  }

  .check_box_checked {
    left: 5px;
    top: 0;
  }

  .check_number {
    font-size: 12px;
  }
}
