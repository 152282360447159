.inner {
  max-width: 1520px;
  margin: 0 auto 250px auto;
  padding: 0 20px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}

.text_inner {
  margin-bottom: 100px;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
}

.media_inner {
  width: 100%;
  display: flex;
  overflow-x: scroll;
}

.media_wrapper {
  width: 100%;
  position: relative;
}

.media_theme_left_button_box {
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-140%);
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
  border-radius: 50%;
  background-color: #fff;
}

.media_theme_right_button_box {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-140%);
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  box-sizing: border-box;
  box-shadow: 3px 3px 7px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
  border-radius: 50%;
  background-color: #fff;
}

// .media_theme_left_button,
// .media_theme_right_button {
//     cursor: pointer;
//     visibility: visible;
//     transition: all 0.3s ease-in-out;
// }

// .hover_media_left_button,
// .hover_media_right_button {
//     cursor: pointer;
//     transition: all 0.3s ease-in-out;
//     display: none;
// }

// .media_theme_left_button_box:hover .media_theme_left_button,
// .media_theme_right_button_box:hover .media_theme_right_button {
//     display: none;
// }

// .media_theme_left_button_box:hover .hover_media_left_button,
// .media_theme_right_button_box:hover .hover_media_right_button {
//     display: block;
// }

.media_inner {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.media_inner::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.media_box {
  width: 480px;
  cursor: pointer;
  flex: 0 0 auto;
  margin-right: 50px;
}

.media_box:last-child {
  margin-right: 0;
}

.media_sub_box {
  position: relative;
}

.media_play_button_box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba($color: #000000, $alpha: 0.5);
  padding: 18px 18px 18px 22px;
  box-sizing: border-box;
  border-radius: 50%;
}

.media_play_img {
  width: 100%;
  max-width: 480px;
  max-height: 270px;
  border-radius: 10px;
}

.media_infor_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.media_infor_icon_box {
  display: flex;
  align-items: center;
}

.media_infor_icon {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.media_infor_text_box {
  margin-left: 10px;
}

.media_infor_text {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #393939;
}

.media_infor_vim_box,
.media_infor_LED_box {
  padding: 10px 20px 9px;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #ff5360;
}

.media_infor_LED_box {
  background-color: #6c63ff;
}

.media_infor_vim_text,
.media_infor_LED_box {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #fff;
}

@media screen and (max-width: 1024px) {
  .media_theme_left_button_box,
  .media_theme_right_button_box {
    display: none;
  }

  .inner {
    margin: 0 auto 80px auto;
  }

  .text_inner {
    margin-bottom: 80px;
  }

  .title_box {
    margin-bottom: 40px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #0c1522;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #696969;
  }

  .media_inner {
    width: 100%;
    height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: initial;
    overflow-y: scroll;
  }

  .media_box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  .media_box:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }

  .media_play_img {
    max-width: 100%;
    max-height: 270px;
  }

  .media_infor_box {
    margin-top: 10px;
  }

  .media_infor_text_box {
    margin-left: 10px;
  }

  .media_infor_vim_box,
  .media_infor_LED_box {
    padding: 10px 20px;
    border-radius: 30px;
  }

  .media_infor_vim_text,
  .media_infor_LED_box {
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }
}

@media screen and (max-width: 500px) {
  .media_play_img {
    max-height: 180px;
  }
}
