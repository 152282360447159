/* Header 전체 */

.sub_container {
  position: relative;
  z-index: 1;
}

.inner {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.inner_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px;
  box-sizing: border-box;
}

/* header 왼쪽 */

.left_inner {
  display: flex;
}

.menu_list_box {
  margin-left: 130px;
  display: flex;
}

.menu_item_box {
  position: relative;
  display: flex;
  margin-right: 80px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0s ease;
}

.menu_item_box:hover .menu_list_img {
  display: none;
  opacity: 0;
}

.menu_item_box:hover .menu_list_hover_img {
  display: block;
  opacity: 1;
}

.menu_list_img,
.menu_list_hover_img,
.mobile_menu_list_img,
.white_menu_list_img {
  width: 18px;
  height: auto;
}

.menu_list_partner_img,
.menu_list_partner_hover_img {
  width: 22px;
}

.menu_item_box:hover .menu_text {
  color: rgba($color: #fff, $alpha: 1);
}

.menu_item_box:last-child {
  margin-right: 0;
}

.menu_item_img_box {
  margin-right: 5px;
}

.menu_list_hover_img {
  opacity: 0;
  display: none;
  // padding-bottom: 3px;
  // box-sizing: border-box;
}

.menu_text {
  transition: all 0s ease;
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: rgba($color: #fff, $alpha: 0.3);
}

.menu_list_hover_box {
  cursor: pointer;
  width: 140px;
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.3s ease-in-out;
  list-style: none;
  visibility: hidden;
  opacity: 0;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.menu_item_box:hover .menu_list_hover_box {
  visibility: visible;
  opacity: 1;
}

.menu_item {
  padding-bottom: 20px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
}

.menu_item:last-child {
  padding-bottom: 0;
}

.menu_item a {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999;
  text-decoration: none;
}

.menu_item a:hover {
  color: #ff5360;
}

.menu_item .active {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
  text-decoration: none;
}

/* header 오른쪽 */

.button_inner {
  display: flex;
}

.button_box {
  padding: 12px 0;
  box-sizing: border-box;
}

.signup_button,
.signin_button {
  outline: none;
  border-radius: 22px;
  border: solid 1px rgba($color: #dfdfdf, $alpha: 0.5);
  background-color: transparent;
  padding: 13px 30px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  margin-right: 20px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.signup_button:hover {
  background-color: #fff;
  color: #101e32;
}

.signin_button {
  background-color: #fff;
  margin-right: 0;
  color: #101e32;
}

.signin_button:hover {
  background-color: #ff5360;
  border: 1px solid #ff5360;
  color: #fff;
}

/* white header */

.white_inner {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.menu_item_box:hover .white_menu_list_img {
  display: none;
  opacity: 0;
}

.menu_item_box:hover .white_menu_list_hover_img {
  display: block;
  opacity: 1;
}

.menu_item_box:hover .white_menu_text {
  color: #ff5360;
}

.menu_item_box:last-child {
  margin-right: 0;
}

.menu_item_img_box {
  margin-right: 5px;
}

.white_menu_list_hover_img {
  opacity: 0;
  display: none;
  // padding-bottom: 3px;
  // box-sizing: border-box;
}

.white_menu_text {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #333;
  transition: all 0s ease;
}

.white_menu_list_hover_box {
  cursor: pointer;
  width: 140px;
  position: absolute;
  z-index: 1;
  top: 25px;
  left: 50%;
  transform: translate(-50%);
  transition: all 0.3s ease-in-out;
  list-style: none;
  visibility: hidden;
  opacity: 0;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.white_menu_item_box:hover .white_menu_list_hover_box {
  visibility: visible;
  opacity: 1;
}

.white_menu_item {
  padding-bottom: 20px;
  box-sizing: border-box;
  text-decoration: none;
}

.white_menu_item:last-child {
  padding-bottom: 0;
}

.white_menu_item a {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999;
  text-decoration: none;
}

.white_menu_item a:hover {
  color: #0c1522;
}

.white_menu_item .active {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
  text-decoration: none;
}

/* header 오른쪽 */

.white_signup_button,
.white_signin_button {
  outline: none;
  border-radius: 22px;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  padding: 12px 30px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  margin-right: 20px;
  transition: 0.3s all ease-in-out;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-decoration: none;
}

.white_signup_button:hover {
  background-color: #e9e9e9;
}

.white_signin_button {
  background-color: #ff5360;
  border: 1px solid #ff5360;
  margin-right: 0;
  color: #fff;
}

.white_signin_button:hover {
  background-color: #e64854;
  border: 1px solid #e64854;
}

/* login box */

.login_inner {
  display: flex;
  align-items: center;
}

.login_img_box {
  margin-right: 15px;
}

.login_img {
  width: 44px;
  height: 44px;
}

.login_nick_name_box {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.login_nick_name,
.black_login_nick_name {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.black_login_nick_name {
  color: #fff;
}

.logout_box {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: solid 1px #dfdfdf;
  background-color: transparent;
  margin: 0 20px;
  transition: all 0.3s ease-in-out;
}

.black_logout_box {
  border: solid 1px rgb(61, 62, 91);
}

.black_logout_box:hover {
  border: solid 1px #fff;
  background-color: #fff;
}

.basket_box {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  border: 0;
  background-color: #ff5360;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basket_number_box {
  position: absolute;
  top: -10px;
  right: -15px;
  border-radius: 15px;
  border: solid 2px #ff5360;
  background-color: #fff;
  padding: 2px 5px;
  box-sizing: border-box;
}

.basket_number {
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.hide_login_inner {
  position: absolute;
  width: 150px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.hide_login_list_item_box {
  margin-bottom: 20px;
}

.hide_login_list_item_box:last-child {
  margin-bottom: 0;
}

.hide_login_list_item {
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.login_nick_name_box:hover .hide_login_inner {
  visibility: visible;
  opacity: 1;
}

.hide_login_list_item:hover {
  font-weight: bold;
  color: #ff5360;
}

.mobile_hamburger_bar {
  display: none;
}

.mobile_menu_inner,
.active_mobile_menu_inner {
  display: none;
}

.img_profile {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

// 추가 내용

@media screen and (max-width: 1200px) {
  .menu_list_box {
    margin-left: 50px;
  }

  .menu_item_box {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .inner_box {
    padding: 20px;
  }

  .menu_list_box,
  .login_inner,
  .button_inner {
    display: none;
  }

  .mobile_hamburger_bar {
    display: block;
    cursor: pointer;
  }

  .mobile_menu_inner,
  .active_mobile_menu_inner {
    display: block;
    position: fixed;
    overflow-y: scroll;
    z-index: 10;
    top: 0;
    right: -1204px;
    width: 100%;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }

  .active_mobile_menu_inner {
    right: 0;
  }

  .mobile_header_inner {
    padding: 20px;
    box-sizing: border-box;
    background-color: #ff5360;
  }

  .mobile_header_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  .mobile_menu_inner_close {
    cursor: pointer;
  }

  .mobile_login_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_login_profile_box {
    display: flex;
    align-items: center;
  }

  .mobile_menu_list_box {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  .mobile_menu_item_inner {
    background-color: #fff;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: inset 0 -1px 0 0 #efefef;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
  }

  .mobile_menu_item_box {
    display: flex;
    align-items: center;
  }

  .mobile_menu_item_img_box {
    margin-right: 15px;
  }

  .mobile_menu_text {
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #333;
  }

  .mobile_menu_list_hover_box {
    position: relative;
    z-index: 3;
    padding: 30px 50px;
    box-sizing: border-box;
    box-shadow: inset 0 -1px 0 0 #efefef;
    background-color: #f6f6f6;
    animation: list-hover ease-in-out 0.3s;
  }

  @keyframes list-hover {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .mobile_menu_item {
    cursor: pointer;
    padding-bottom: 20px;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .mobile_basket_number {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ff5360;
    margin-left: 10px;
  }

  .mobile_menu_item:last-child {
    padding-bottom: 0;
  }

  .mobile_menu_item a,
  .mobile_menu_item button {
    text-decoration: none;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #787878;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  .mobile_menu_item:hover a,
  .mobile_menu_item:hover button {
    font-weight: bold;
    color: #ff5360;
  }

  .active_mobile_menu_hide_box_img {
    transition: all 0.3s ease-in-out;
    transform: rotateZ(180deg);
  }

  .mobile_menu_hide_box_img {
    transition: all 0.3s ease-in-out;
    transform: rotateZ(0deg);
  }

  /* 모바일 footer */
  .mobile_menu_footer_inner {
    position: fixed;
    width: 100%;
    bottom: 0;
  }

  .mobile_menu_footer_icon_box,
  .mobile_menu_footer_box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0;
    box-sizing: border-box;
  }

  .mobile_menu_footer_menu_box {
    width: 33%;
    text-align: center;
  }

  .mobile_menu_footer_box {
    box-shadow: inset 0 1px 0 0 #efefef;
  }

  .mobile_menu_footer_text {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: normal;
    color: #969696;
  }

  // 모바일 회원가입 버튼

  .mobile_signup_button {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 22px;
    border: solid 1px rgba($color: #ffffff, $alpha: 0.3);
    padding: 10px;
    box-sizing: border-box;
  }

  .mobile_button_box .mobile_signup_button:hover {
    color: #fff;
  }

  .login_nick_name_box {
    cursor: pointer;
    margin-right: 0;
    display: flex;
    align-items: center;
  }

  .login_nick_name {
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
}
