.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.header_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 86px;
}

.title_inner {
  display: flex;
  align-items: center;
}

.title_box {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  width: 280px;
}

.sub_title_box {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  width: 280px;
}

.hr {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  border: 0.5px solid #ddd;
  height: 36px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.sub_title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #d1d5db;
}

.change_box {
  text-decoration: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px 25px;
  box-sizing: border-box;
}

.change_button {
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #56677e;
}

.change_box:hover {
  border: solid 1px #ff5360;
}

.change_box:hover .change_button {
  color: #ff5360;
}

.content_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.engineer_box {
  width: 30%;
  padding: 50px 30px 30px;
  box-sizing: border-box;
  border-radius: 20px;
  border: solid 1px #dfdfdf;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.engineer_charge_box {
  margin-bottom: 50px;
}

.engineer_charge {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.engineer_profile_box {
  margin-bottom: 140px;
}

.engineer_visit_box {
  border-radius: 30px;
  border: solid 1px #ff5360;
  padding: 20px 0;
  box-sizing: border-box;
  width: 100%;
}

.engineer_visit {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
  text-align: center;
}

.place_box {
  width: 65%;
}

.place_text_box {
  margin-bottom: 20px;
}

.place_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.place_photo_add_sub_inner {
  display: flex;
  margin-bottom: 50px;
}

.place_photo_add_box {
  border-radius: 10px;
  border: solid 2px #d1d5db;
  width: 114px;
  height: 114px;
  padding: 45px;
  box-sizing: border-box;
  cursor: pointer;
}

.place_photo_select_inner {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
}

.place_photo_select_box {
  position: relative;
  border-radius: 10px;
  background-repeat: no-repeat;
  width: 110px;
  height: 110px;
  margin: 0 0 20px 20px;
}

.cancel_button {
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 2px;
}

.detail_box {
  width: 100%;
  border-radius: 10px;
  border: solid 1px #efefef;
  background-color: #f6f6f6;
  padding: 20px;
  box-sizing: border-box;
}

.detail_text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.85;
  letter-spacing: normal;
  color: #656565;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .title_inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .title_box {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    width: 50%;
  }

  .sub_title_box {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    width: 50%;
  }

  .hr {
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    height: 23px;
  }

  .title {
    font-size: 20px;
    line-height: 3;
  }

  .sub_title {
    font-size: 18px;
    line-height: 3.33;
    text-align: right;
  }

  .change_box {
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }

  .change_button {
    font-size: 15px;
    line-height: 2;
  }

  .content_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
  }

  .engineer_box {
    width: 100%;
    padding: 40px 30px 30px;
    margin-bottom: 50px;
  }

  .engineer_charge_box {
    margin-bottom: 30px;
  }

  .engineer_profile_box {
    margin-bottom: 20px;
  }

  .engineer_name {
    font-size: 15px;
    font-weight: bold;
    line-height: 0.87;
  }

  .engineer_tell_box {
    margin-bottom: 50px;
  }

  .engineer_tell {
    font-size: 15px;
    line-height: 1.27;
  }

  .engineer_visit_box {
    padding: 16px 0;
  }

  .engineer_visit {
    font-size: 15px;
  }

  .place_box {
    width: 100%;
  }

  .place_photo_add_sub_inner {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 20px;
  }

  .place_photo_add_box {
    border: solid 1px #d1d5db;
  }

  .place_photo_select_inner {
    margin-left: 20px;
    width: 1500px;
    flex-wrap: initial;
  }

  .place_photo_select_box {
    margin: 0 20px 0 0;
  }

  .cancel_button {
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px;
  }
}
