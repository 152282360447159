* {
  transition: all 0.3s ease-in-out;
}

.img_box {
  width: 100%;
  margin-bottom: 30px;
}

.img {
  width: 100%;
}

.text_box {
  margin-bottom: 80px;
}

.text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.title_inner {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.title_box {
  margin-left: 10px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.content_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.list_img_box {
  width: 48%;
  margin-bottom: 30px;
}

.list_img {
  width: 100%;
}

.list_title_inner {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.list_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.list_title.black {
  color: #2e384b;
}

.txt_cation {
  margin-top: 10px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
}

/* 아래 택스트 */

.caution_inner {
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #ff5360;
  margin-bottom: 40px;
}

.caution_text_box {
  margin-left: 10px;
}

.caution_text {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.caution_text:last-child {
  line-height: 1.56;
}

@media screen and (max-width: 1024px) {
  .img_box {
    margin-bottom: 20px;
  }

  .text_box {
    margin-bottom: 30px;
  }

  .text {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
  }

  .title {
    font-size: 15px;
  }

  .content_box {
    margin-bottom: 30px;
  }

  .list_img_box {
    margin-bottom: 12px;
  }

  /* 아래 택스트 */

  .caution_text:last-child {
    line-height: 1.13;
  }

  .caution_inner {
    padding: 10px;
  }

  .caution_text_box {
    margin-left: 10px;
  }

  .caution_text {
    font-size: 14px;
    line-height: 1.13;
  }
}
