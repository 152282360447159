.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: black;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  z-index: 1;
}

.box_btn {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  z-index: 9999;
}

.btn_window {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 100%;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

:global(#video-container > iframe) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  opacity: 0;
  transition: all ease-in-out 5s;
  z-index: 10;
  visibility: hidden;
}

:global(#video-container > iframe.active) {
  opacity: 1;
  transition: all ease-in-out 3s;
  visibility: visible;
  z-index: 100;
}
