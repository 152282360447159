.sub_container {
  position: relative;
  display: flex;
  overflow-y: hidden;
}

.no-data {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #333;
}

/* side_bar */

.side_container {
  width: 300px;
  margin-bottom: -5000px;
  padding-bottom: 5000px;
}

.side_inner {
  height: 100%;
  padding-top: 71.5px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.menu_list_box {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.active .menu_list_item_box {
  background-color: #f9f9f9;
}

.menu_list_item_box {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 20px 50px;
  box-sizing: border-box;
}

.menu_list_item_box:hover .list_text {
  color: #ff5360;
}

.menu_list_item_box:hover .img_off {
  display: none;
}

.menu_list_item_box:hover .img_on {
  display: block;
}

.active .list_text {
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.active .img_on {
  display: block;
}

.active .img_off {
  display: none;
}

.img_on {
  display: none;
}

.img_off {
  display: block;
}

.list_text {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.select_media_box {
  padding: 30px;
  box-sizing: border-box;
  border-top: 1px solid #dfdfdf;
  height: 100%;
}

.bottom_select_inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.select_number_text {
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.selecet_count_number {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: right;
  color: #d1d5db;
}

.select_keep_box {
  border-top: 1px solid #e9e9e9;
  padding: 30px;
  box-sizing: border-box;
}

.select_keep_text_box {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_keep_button {
  cursor: pointer;
  outline: none;
  border: 0;
  width: 100%;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 30px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.select_keep_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: center;
  color: #ff5360;
}

/* play list box */

.playlist_item_content_inner {
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: 680px;
  overflow: hidden;
  overflow-y: scroll;
}

.inner {
  transition: all 0.3s ease-in-out;
  width: calc(100% - 300px);
  background-color: #f6f6f6;
  margin-top: 71.5px;
  position: relative;
}

/* slider */

.slider_container {
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
}

.slider_sub_container,
.slider_inner_box {
  width: 100%;
  height: 100%;
}

.slider_bg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider_sub_bg {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba($color: #000000, $alpha: 0.2);
  padding: 50px 0 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.slider_inner {
  transition: all 0.3s ease-in-out;
  width: 42%;
  height: 360px;
  border-radius: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 15px 20px;
  box-sizing: border-box;
  position: relative;
}

.slider_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider_title_box {
  display: flex;
  align-items: center;
}

.sluder_quality_box {
  padding: 3px 10px;
  box-sizing: border-box;
  border-radius: 11.5px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: solid 1px #fff;
  background-color: #000;
}

.sluder_quality {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

.text_box {
  margin-left: 15px;
}

.text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

.slider_button_inner {
  transition: all 0.3s ease-in-out;
  position: absolute;
  width: 42%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
}

.slider_button_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.slider_play_button_inner {
  display: flex;
  align-items: center;
}

.slider_skip_button_box {
  cursor: pointer;
}

.slider_play_button_box {
  cursor: pointer;
  margin: 0 10px;
}

.slider_shuffle_button {
  cursor: pointer;
}

.slider_play_time_inner {
  position: relative;
}

.slider_play_time_progress {
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #000;
  position: relative;
}

.slider_play_time_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.slider_play_time {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

.sound_inner {
  transition: all 0.3s ease-in-out;
  position: absolute;
  right: 10%;
  transform: translateX(-50%);
  bottom: 105px;
  display: flex;
  align-items: center;
}

.sound_box {
  margin-right: 16px;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  z-index: 1;
}

.sound_progressbar {
  cursor: pointer;
  width: 50px;
  height: 3px;
  border-radius: 1.5px;
  // background-color: #000000;
  position: relative;
}

.slider_play_time_progress > span,
.sound_progressbar > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #ff5360;
}

/* 밑에 내용 */

.slider_pop_repeat_button {
  cursor: pointer;
}

.playlist_inner {
  padding: 40px 60px 30px;
  box-sizing: border-box;
}

.playlist_button_inner {
  width: 100%;
  margin-bottom: 30px;
}

.playlist_button_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playlist_icon_inner {
  display: flex;
  align-items: center;
}

.playlist_icon_box {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 10px;
  box-sizing: border-box;
  border-radius: 22px;
  border: solid 1px #d1d5db;
  background-color: #f6f6f6;
  min-height: 46px;
}

.box_select {
  margin-right: 20px;
}

.select_duration {
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #56677e;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.select_duration option {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #56677e;
}

.playlist_icon_box:last-child {
  margin-left: 20px;
}

.playlist_icon_box:first-child {
  margin-left: 0;
}

.playlist_text_box {
  margin-left: 5px;
}

.playlist_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #56677e;
}

.playlist_item_header {
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  margin-bottom: 20px;
}

.playlist_item_header_title_box {
  width: 50%;
}

.playlist_item_header_title_box:first-child {
  width: 20%;
}

.playlist_item_header_title_box:last-child {
  width: 20%;
}

.playlist_item_header_division_title_box {
  width: 10%;
}

.playlist_item_header_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #56677e;
}

.playlist_item_header_title:last-child {
  text-align: left;
}

.playlist_item_content_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
}

.playlist_item_content_box.active,
.playlist_item_content_box:hover {
  background-color: #fcedee;
}

.playlist_item_content_box:last-child {
  margin-bottom: 0;
}

.playlist_agree_box {
  width: 20%;
}

.playlist_item_content_title_box {
  width: 50%;
  display: flex;
  align-items: center;
}

.playlist_item_img_box {
  width: 160px;
  height: 90px;
  display: flex;
  align-items: center;
}

.playlist_item_img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.playlist_item_box {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.playlist_item_quality_box {
  border-radius: 11.5px;
  border: solid 1px #56677e;
  background-color: #f6f6f6;
  padding: 5px 10px 3px;
  box-sizing: border-box;
}

.playlist_item_quality {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #56677e;
}

.playlist_item_title_box {
  margin-left: 15px;
}

.playlist_item_title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

// 구분 추가

.playlist_item_division_box {
  width: 10%;
}

.playlist_item_division {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

.playlist_item_content_play_box {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.playlist_item_icon_inner {
  display: flex;
  align-items: center;
  padding-right: 20px;
  box-sizing: border-box;
}

.playlist_item_icon_box {
  margin-left: 30px;
}

.mobile_footer_inner,
.mobile_edit_box,
.mobile_edit_inner {
  display: none;
}

// 데이터 없을 떄

.no_data_inner {
  width: 100%;
  height: calc(100vh - 315px);
  min-height: 770px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no_data_box {
  display: flex;
  flex-direction: column;
}

.no_data_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.no_data_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #2e384b;
}

.slide_hart_box {
  cursor: pointer;
}

.checkbox {
  display: none;
}

.checkbox.checked ~ .checklabel {
  background-color: #32c4ff;
  border-radius: 5px;
  border: solid 1px #32c4ff;
}

.checklabel:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox.checked ~ .checklabel:after {
  display: block;
}

.checkbox.checked + label::after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox + label {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  border: 0;
  border-radius: 5px;
  border: solid 1px #efefef;
  position: relative;
  background-color: #fff;
}

.agree_checkbox {
  padding: 0 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 1330px) {
  .agree_checkbox {
    padding: 0 40px;
  }

  .slider_inner {
    width: 52%;
  }

  .slider_button_inner {
    width: 52%;
  }

  .sound_inner {
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .agree_checkbox {
    padding: 0 20px 0 0;
  }

  .checkbox + label {
    margin-right: 0;
  }

  .side_container {
    display: none;
  }

  .inner {
    width: 100%;
  }

  .slide_hart_box {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .slider_inner {
    height: 60%;
  }

  .slider_button_inner {
    bottom: 120px;
  }

  .slider_play_button_inner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -80px;
  }

  .sound_inner {
    right: 22%;
    bottom: 55px;
  }

  .sound_box {
    margin-right: 0;
  }

  .sound_progressbar {
    display: none;
  }

  .playlist_button_inner {
    padding: 0 20px;
    box-sizing: border-box;
  }

  .playlist_icon_box:last-child {
    margin-left: 10px;
  }

  .playlist_text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-align: left;
    color: #56677e;
  }

  .playlist_icon_inner:last-child {
    display: none;
  }

  .mobile_edit_box {
    cursor: pointer;
    display: block;
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 18px;
    border: solid 1px #d1d5db;
    background-color: #f6f6f6;
    transition: all 0.3s ease-in-out;
  }

  .mobile_edit {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #56677e;
    transition: all 0.3s ease-in-out;
  }

  .active_mobile_edit_box {
    cursor: pointer;
    display: block;
    padding: 10px 15px;
    box-sizing: border-box;
    border-radius: 18px;
    border: solid 1px #ff5360;
    background-color: #f6f6f6;
    transition: all 0.3s ease-in-out;
  }

  .active_mobile_edit {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ff5360;
    transition: all 0.3s ease-in-out;
  }

  .mobile_edit_inner {
    z-index: 1;
    display: block;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    background-color: #ff5360;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .mobile_select_edit {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_select_edit_b {
    font-weight: bold;
  }

  .mobile_select_edit_option_del {
    margin-right: 30px;
    cursor: pointer;
  }

  .mobile_select_edit_option_hart {
    cursor: pointer;
  }

  .playlist_inner {
    padding: 20px 0;
  }

  .playlist_item_header {
    display: none;
  }

  .playlist_item_content_box {
    padding: 15px 20px;
    box-sizing: border-box;
  }

  .playlist_item_content_title_box {
    width: 100%;
  }

  .playlist_item_box {
    margin-left: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .playlist_item_quality_box {
    margin-bottom: 10px;
  }

  .playlist_item_title_box {
    margin-left: 0;
  }

  .playlist_item_title {
    font-size: 14px;
  }

  .playlist_item_quality {
    font-size: 11px;
  }

  .playlist_item_time_box {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 35px;
    right: 20px;
  }

  .playlist_item_time,
  .playlist_item_division {
    font-size: 12px;
  }

  .playlist_item_division_box {
    width: auto;
    position: absolute;
    bottom: 15px;
    right: 20px;
  }

  .playlist_item_content_play_box {
    width: auto;
  }

  .playlist_item_icon_inner {
    display: none;
  }

  /* 모바일 footer */

  .mobile_footer_inner {
    display: block;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 1;
  }

  .mobile_footer_box {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .mobile_footer_header_box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0;
    box-sizing: border-box;
    width: 100%;
    background-color: #ff5360;
  }

  .mobile_footer_select_box {
    position: absolute;
    border-radius: 14px;
    background-color: #2e384b;
    padding: 8px 12px;
    box-sizing: border-box;
    top: -14px;
    right: 20px;
  }

  .mobile_footer_select {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_footer_select_b {
    font-weight: bold;
  }

  .mobile_footer_header_img_box {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .mobile_footer_header_title {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #fff;
  }

  .mobile_footer_menu_inner {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: inset 0 1px 0 0 #dfdfdf;
    background-color: #fff;
  }

  .menu_list_item_box {
    flex-direction: column;
    padding: 10px;
  }

  .list_text {
    margin-left: 0;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .active .menu_list_item_box {
    background-color: #ffffff;
  }

  .active .list_text {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5360;
  }

  //누르면 나오는 메뉴

  .mobile_hide_select_box {
    transition: all 0.3s ease-in-out;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    z-index: -1;
    animation: close 0.5s;
  }

  @keyframes close {
    from {
      top: -380px;
    }

    to {
      top: 0;
    }
  }

  .active_mobile_hide_select_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    position: absolute;
    z-index: -1;
    top: -380px;
    transition: all 0.3s ease-in-out;
    animation: open 0.5s;
  }

  @keyframes open {
    from {
      top: 0;
    }

    to {
      top: -380px;
    }
  }

  .mobile_hide_button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
  }

  .mobile_hide_text_box {
    margin-bottom: 24px;
  }

  .mobile_hide_text {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .mobile_hide_menu_item_inner {
    height: 230px;
    overflow-y: scroll;
  }

  .mobile_hide_menu_item_box {
    padding: 12px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  .mobile_hide_img_box {
    margin-right: 15px;
  }

  .mobile_hide_img {
    border-radius: 4px;
  }

  .mobile_hide_menu_item_text {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #2e384b;
  }

  .mobile_hide_menu_item_button_box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mobile_hide_menu_item_button {
    width: 100%;
    border: 0;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #fff;
    padding: 16px 0;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #d1d5db;
  }

  // 데이터 없을 때
  .no_data_inner {
    height: calc(100vh - 315px);
    min-height: 450px;
  }

  .no_data_title {
    text-align: center;
    font-size: 18px;
  }

  .no_data_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .slider_inner {
    width: 62%;
  }

  .slider_button_inner {
    width: 62%;
  }

  .slider_inner {
    height: 60%;
  }

  .sound_inner {
    right: 16.5%;
  }
}

@media screen and (max-width: 650px) {
  .slider_sub_bg {
    padding: 50px 20px 30px;
  }

  .slider_inner {
    width: 100%;
  }

  .slider_button_inner {
    padding: 0 20px;
    box-sizing: border-box;
    width: 100%;
  }

  .sluder_quality {
    font-size: 12px;
  }

  .text_box {
    margin-left: 10px;
  }

  .text {
    font-size: 13px;
  }

  .sound_inner {
    right: 0;
  }
}

@media screen and (max-width: 400px) {
  .slider_container {
    height: 500px;
  }

  .slider_inner {
    height: 180px;
  }

  .playlist_item_img_box {
    width: 80px;
    height: 45px;
  }

  .playlist_item_time_box {
    top: 20px;
  }
}
