.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.text_box {
  margin-bottom: 80px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
}

.header_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.title_inner {
  display: flex;
  align-items: center;
}

.title_box {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  position: relative;
  width: 280px;
}

.sub_title_box {
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
  width: 280px;
}

.hr {
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  border: 0.5px solid #ddd;
  height: 36px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.sub_title {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #d1d5db;
}

.change_box {
  text-decoration: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px 25px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.change_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #56677e;
  transition: all 0.3s ease-in-out;
}

.change_box:hover {
  border: solid 1px #ff5360;
}

.change_box:hover .change_text {
  color: #ff5360;
}

/* 테이블 */

.table_inner {
  width: 100%;
}

.table_header {
  padding: 12px 50px;
  box-sizing: border-box;
  border-radius: 10px 10px 0 0;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_header_title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
}

.table_box {
  width: 15%;
  text-align: center;
}

.table_box:last-child {
  width: 62%;
}

.table_body {
  margin-bottom: 30px;
}

.table_data_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 22px 50px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px 0 0 #dfdfdf;
  background-color: #fff;
}

.table_data_box {
  width: 15%;
  text-align: center;
}

.table_data_box:last-child {
  width: 62%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table_data,
.history_table_data {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #969696;
  overflow: hidden;
}

.history_table_data {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
}

.detail_box {
  cursor: pointer;
  text-decoration: none;
  width: 12%;
  border-radius: 15px;
  border: solid 1px #dfdfdf;
  padding: 3px 17px 0;
  box-sizing: border-box;
  text-align: center;
}

.detail_button {
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  line-height: 1.69;
  color: #56677e;
}

/* 페이지네이션 */

.pagination_inner {
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}

.pagination_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  padding: 3px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.53;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.53;
  letter-spacing: normal;
  color: #ff5360;
}

/* AS 신청 */

.button_box {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  text-decoration: none;
}

.button {
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

/* 테이블 하단 */

.info_inner {
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
}

.info_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: normal;
  color: #656565;
}

.info_text_wait {
  font-weight: bold;
  color: #56677e;
}

.info_text_assignment {
  font-weight: bold;
  color: #2e384b;
}

.info_text_complete {
  font-weight: bold;
  color: #ff5360;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .title_inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  .title_box {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    position: relative;
    width: 50%;
  }

  .sub_title_box {
    cursor: pointer;
    box-sizing: border-box;
    text-decoration: none;
    width: 50%;
  }

  .hr {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 23px;
  }

  .title {
    font-size: 20px;
    line-height: 3;
    text-align: right;
  }

  .sub_title {
    font-size: 18px;
    line-height: 3.33;
    text-align: left;
  }

  .change_box {
    width: 100%;
    padding: 20px 0;
    text-align: center;
  }

  .change_button {
    font-size: 15px;
    line-height: 2;
  }

  .text_box {
    margin-bottom: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }

  .table_header {
    display: none;
  }

  .table_data_inner {
    flex-wrap: wrap;
    padding: 0 0 20px 0;
  }

  .table_data_box {
    width: 50%;
  }

  .table_data_box:nth-child(1),
  .table_data_box:nth-child(2) {
    padding: 20px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #efefef;
  }

  .table_data_box:last-child {
    padding-top: 20px;
    width: 100%;
    flex-direction: column;
  }

  .table_data,
  .history_table_data {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #969696;
    overflow: hidden;
  }

  .history_table_data {
    width: 100%;
  }

  .detail_box {
    margin-top: 20px;
    width: 100%;
    padding: 13px 0;
  }

  .detail_button {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.67;
  }

  .button_box {
    right: 0;
    top: 80px;
    transform: translateX(0);
    width: 100%;
  }

  .button {
    width: 100%;
    padding: 16px 0;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 2;
  }

  .pagination_box {
    margin-bottom: 100px;
  }

  .mobile_br {
    display: block;
  }

  .mobile_b {
    display: none;
  }
}
