/* 솔루션 헤더 */

.sub_container {
  padding-top: 71.5px;
  box-sizing: border-box;
}

.header_container {
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
}

.header_inner {
  display: flex;
  align-items: center;
}

.select_process_box {
  width: 11%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #ff5360;
  background-color: #fff;
}

.select_process_img_box {
  margin-right: 10px;
}

.select_process_title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.select_process_bar {
  width: 4%;
  height: 2px;
  background-color: #ff5360;
}

.process_box {
  width: 11%;
  height: 50px;
  padding: 16px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #efefef;
  background-color: #fff;
}

.process_status_box {
  width: 12%;
  height: 50px;
  padding: 16px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #efefef;
  background-color: #fff;
}

.process_title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #56677e;
}

.process_bar {
  width: 4%;
  height: 2px;
  background-color: #efefef;
}

.selected_process_box {
  width: 11%;
  height: 50px;
  padding: 16px 0;
  box-sizing: border-box;
  border-radius: 30px;
  border: solid 2px #ecedef;
  background-color: #ecedef;
}

.selected_process_title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c9c9c9;
}

.selected_process_bar {
  width: 4%;
  height: 2px;
  background-color: #ecedef;
}

/* 솔루션 컨텐트 */

.inner_box {
  padding: 50px 20px;
  box-sizing: border-box;
  position: relative;
}

.checklist_button {
  height: 64px;
  cursor: pointer;
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 30px;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
  background-color: #32c4ff;
  box-shadow: 0 3px 10px 0 rgba(50, 196, 255, 0.3);
  border-radius: 35px;
}

.checklist_button:hover {
  padding-right: 130px;
}

.checklist_icon_box {
  height: 64px;
}

.hide_text_box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
}

.hide_text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: all 1s ease-in-out;
  opacity: 0;
  white-space: nowrap;
}

.checklist_button:hover .hide_text_box {
  visibility: visible;
  opacity: 1;
}

.checklist_button:hover .hide_text {
  opacity: 1;
}

.title_box {
  margin-bottom: 50px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #101e32;
}

.content_inner {
  width: 450px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content_img_box {
  margin-bottom: 80px;
}

.text_box {
  margin-bottom: 20px;
}

.text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #101e32;
}

.sub_text_box {
  margin-bottom: 50px;
}

.sub_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #656565;
  line-height: 1.5;
}

.content_button_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm_button {
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #dfdfdf;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  padding: 13px 50px;
  box-sizing: border-box;
}

/* 버튼 */

.button_box {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev_button_box {
  margin-right: 50px;
}

.next_button,
.prev_button {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  padding: 20px 55px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #d1d5db;
  background-color: #d1d5db;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
}

.prev_button {
  border: solid 1px #dfdfdf;
  background-color: #fff;
  color: #d1d5db;
}

.mobile_header_inner {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header_inner {
    display: none;
  }

  .mobile_header_inner {
    display: block;
  }

  .mobile_header_progressbar_box {
    margin-bottom: 25px;
    width: 100%;
    height: 4px;
    background-color: rgba($color: #2e384b, $alpha: 0.2);
  }

  .mobile_header_progressbar {
    width: 39%;
    height: 4px;
    background-color: #ff5360;
  }

  .select_process_box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: initial;
    align-items: inherit;
    flex-direction: column;
    padding: 0;
    border: none;
    background-color: transparent;
  }

  .mobile_select_text_box {
    margin-bottom: 10px;
  }

  .mobile_select_text {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #969696;
  }

  .mobile_select_process_title_box {
    display: flex;
    align-items: center;
  }

  .select_process_img_box {
    margin-right: 0;
    margin-left: 10px;
  }

  .select_process_title {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ff5360;
  }

  .checklist_button {
    bottom: 80px;
  }

  .checklist_button:hover {
    padding-right: 110px;
  }

  .hide_text_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  .hide_text {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    opacity: 0;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.5;
    letter-spacing: normal;
    text-align: center;
  }

  .content_inner {
    width: 100%;
    margin-bottom: 100px;
  }

  .content_img_box {
    margin-bottom: 50px;
  }

  .text {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.33;
    letter-spacing: normal;
  }

  .sub_text {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    letter-spacing: normal;
  }

  .confirm_button {
    width: 48%;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: normal;
    text-align: center;
    padding: 14px 0;
  }

  /* 버튼 */

  .button_box {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    margin-top: 0;
  }

  .prev_button_box,
  .next_button_box {
    margin-right: 0;
    width: 50%;
  }

  .next_button,
  .prev_button {
    display: block;
    width: 100%;
    padding: 20px 0;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 2;
  }
}
