
/* 페이지네이션 */

.pagination_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  padding: 3px;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.53;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 5.53;
  letter-spacing: normal;
  color: #ff5360;
}