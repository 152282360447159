.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 80px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 80px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.header_inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.change_box {
  text-decoration: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 20px 25px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.change_button {
  outline: none;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #56677e;
  transition: all 0.3s ease-in-out;
}

.change_box:hover {
  border: solid 1px #ff5360;
}

.change_box:hover .change_button {
  color: #ff5360;
}

.text_box {
  margin-bottom: 80px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
}

.category_select_inner {
  margin-bottom: 40px;
}

.label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.31;
  letter-spacing: normal;
  color: #101e32;
}

.category_select_box {
  width: 62.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.category_input_box {
  width: 48%;
}

.category_input {
  width: 100%;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
}

.category_input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.category_input:focus::placeholder {
  color: transparent;
}

.category_select {
  width: 48%;
  cursor: pointer;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  -webkit-appearance: none;
  /* 화살표 없애기 for chrome*/
  -moz-appearance: none;
  /* 화살표 없애기 for firefox*/
  appearance: none;
  /* 화살표 없애기 공통*/
  background-position: 95% 50%;
  background-repeat: no-repeat;
}

.category_select::-ms-expand {
  display: none;
  /* 화살표 없애기 for IE10, 11*/
}

.textarea_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.textarea {
  resize: none;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  outline: none;
  height: 300px;
}

.textarea::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.textarea:focus::placeholder {
  color: transparent;
}

.button_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  cursor: pointer;
  border: 0;
  width: 25%;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #fff;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
}

// 추가 된 내용

.file_upload_text_box {
  margin-bottom: 20px;
}

.file_upload_text {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.file_upload_box {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  gap: 0 20px;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .header_inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }

  .title_box {
    width: 100%;
    margin-bottom: 30px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
  }

  .change_box {
    width: 100%;
    text-align: center;
    padding: 16px 0;
  }

  .change_button {
    font-size: 15px;
    line-height: 2;
  }

  .text_box {
    margin-bottom: 40px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }

  .category_select_inner {
    margin-bottom: 50px;
  }

  .category_select_box {
    width: 100%;
    flex-direction: column;
  }

  .category_input_box {
    width: 100%;
  }

  .category_input {
    width: 100%;
    font-size: 15px;
    padding: 16px 20px;
  }

  .category_input::placeholder {
    font-size: 15px;
  }

  .category_select {
    margin-top: 20px;
    width: 100%;
    padding: 16px 20px;
    font-size: 15px;
  }

  .textarea_box {
    margin-bottom: 30px;
  }

  .textarea {
    width: 100%;
    font-size: 14px;
    line-height: 1.71;
  }

  .textarea::placeholder {
    font-size: 14px;
    line-height: 1.71;
  }

  .button {
    width: 100%;
    font-size: 15px;
    padding: 16px 0;
  }

  .file_upload_box {
    gap: 10px;
    flex-wrap: wrap;
  }
}
