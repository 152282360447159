// 전체

.container {
  background-color: #f5f5f5;
  padding: 50px 20px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

// 테이블

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead {
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #f9f9f9;
}

.td {
  padding: 12px 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
  text-align: center;
}

.tbody_tr {
  background-color: #fff;
}

.tbody_td {
  padding: 12px 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
  box-shadow: inset 0 -1px 0 0 #efefef;
  text-align: center;
}

.tbody_tr_img {
  width: 100px;
  height: 56px;
  border-radius: 4px;
  background-color: grey;
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  align-items: center;
  color: #fff;
  justify-content: center;
}

.mobile_tbody_td {
  display: none;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 30px 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.mobile_box {
  display: none;
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 30px 0;
  }

  // 테이블
  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .thead {
    display: none;
  }

  .tbody_tr {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: inset 0 -1px 0 0 #dfdfdf;
  }

  .tbody_td {
    padding: 20px;
    box-sizing: border-box;
    font-size: 12px;
    box-shadow: initial;
    text-align: initial;
    &:first-child {
      width: 100%;
      padding-bottom: 0;
    }
  }

  .mobile_inner {
    padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
  }

  .tbody_tr_img {
    width: 170px;
    height: 100px;
    border-radius: 4px;
  }

  .mobile_tbody_td {
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #2e384b;
  }

  .tbody_payment_td {
    display: none;
  }

  .mobile_box {
    display: block;
  }

  .mobile_text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: normal;
    text-align: right;
    color: #56677e;
  }

  .mobile_text_b {
    font-size: 17px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #2e384b;
  }
}
