.inner {
  margin-bottom: 80px;
}

.img_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.img_box {
  width: 48%;
}

.img {
  width: 100%;
}

.text {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384b;
}

@media screen and (max-width: 1024px) {
  .img_inner {
    margin-bottom: 20px;
  }

  .text {
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
  }
}
