.form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}

.input-file-upload {
  display: none;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

.label-file-upload.dragActive {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: 'Oswald', sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.seal_img_box {
  margin-bottom: 30px;
}

.sign_text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  color: #56677e;
}

.box_seal {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 0 40px;
}

.upload-button {
  position: absolute;
  top:0;
  left: 0;
  bottom: 0;
  right:0;
}

.btn_remove {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.btn_remove button {
  cursor: pointer;
}