.inner {
  max-width: 1520px;
  margin: 0 auto;
  padding: 200px 20px 220px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}

.inner_box {
  width: 100%;
  position: relative;
  margin-bottom: 94px;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  transition: all 0.3s ease-in-out;
}

.title_color {
  color: #ff5360;
}

.text_box {
  margin-bottom: 80px;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
  transition: all 0.3s ease-in-out;
}

/* Time 원형 시계 */

.time_box_img {
  position: absolute;
  top: 30px;
  right: 30px;
  transition: all 0.3s ease-in-out;
}

.time_bg {
  width: 100%;
}

.mobile_time_bg_box {
  display: none;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 120px 20px 150px;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;
  }

  .inner_box {
    position: relative;
    margin-bottom: 0;
  }

  .title_box {
    margin-bottom: 20px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #0c1522;
  }

  .title_color {
    color: #ff5360;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #696969;
  }

  .time_box_img {
    width: 150px;
    height: 150px;
    top: 90px;
    right: 0;
  }

  .time_bg_box {
    display: none;
  }

  .mobile_time_bg_box {
    display: block;
  }

  .mobile_time_bg {
    width: 100%;
    height: 310px;
  }
}

@media screen and (max-width: 500px) {
  .mobile_br {
    display: block;
  }

  .text_box {
    margin-bottom: 100px;
  }

  .time_box_img {
    top: 175px;
    right: 30%;
    transform: translateX(-50%);
  }
}
