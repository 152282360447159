.sub_container {
  max-width: 1820px;
  padding: 0 50px;
  box-sizing: border-box;
  margin: 64px auto 200px auto;
}

.sub_container_bg {
  width: 100%;
  border-radius: 20px;
  // background-color: #6c63ff;
  position: relative;
}

.box_sub {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
  background-color: #6c63ff;
}

.inner {
  max-width: 1520px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  position: relative;
  padding: 156px 20px;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.pig_img_box {
  position: absolute;
  right: 0;
}

.pig_img {
  width: 100%;
}

.mobile_br {
  display: none;
}

@media screen and (max-width: 1500px) {
  .mobile_br {
    display: block;
  }
}

@media screen and (max-width: 1100px) {
  .inner {
    flex-direction: column;
    justify-content: space-between;
    align-items: initial;
    padding: 40px 30px 190px;
  }

  .pig_img_box {
    width: 220px;
    height: 220px;
    bottom: -30px;
  }
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 0 20px;
    margin: 0 auto 110px auto;
  }

  .title_box {
    margin-bottom: 30px;
  }

  .title {
    font-size: 30px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
  }
}
