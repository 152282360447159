.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 80px 0 80px;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 80px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
}

.category_box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.category {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.title_box {
  margin-bottom: 20px;
}

.title {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

.date_box {
  margin-left: 20px;
}

.date {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
}

.hr {
  margin: 0;
  border: 0.5px solid #dfdfdf;
}

.process_box {
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  box-sizing: border-box;
  margin: 50px 0 30px;
}

.process_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #d1d5db;
}

.process_img {
  margin: 0 30px;
}

.select_process_text {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ff5360;
}

.content_box {
  width: 100%;
  margin-bottom: 50px;
}

.content_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
}

.engineer_inner {
  width: 100%;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.engineer_box {
  display: flex;
  align-items: center;
}

.engineer_profile_img_box {
  width: 60px;
  height: 60px;
}

.engineer_profile_img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.profile_name_box {
  margin-left: 20px;
}

.profile_name {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.profile_tel {
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #32c4ff;
}

.engineer_vist_box {
  border-radius: 25px;
  border: solid 1px #ff5360;
  padding: 15px 32px;
  box-sizing: border-box;
}

.engineer_vist {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .category {
    font-size: 15px;
    line-height: 2;
  }

  .title {
    font-size: 24px;
    line-height: 1.42;
  }

  .date {
    font-size: 14px;
    line-height: 2.14;
  }

  .process_box {
    padding: 20px;
    box-sizing: border-box;
    margin: 30px 0;
  }

  .process_text {
    font-size: 15px;
    line-height: 1.47;
  }

  .process_img {
    width: 18px;
    height: 18px;
    margin: 0 5px;
  }

  .select_process_text {
    font-size: 15px;
    line-height: 1.47;
  }

  .content_box {
    margin-bottom: 30px;
  }

  .content_text {
    font-size: 13px;
    line-height: 1.54;
  }

  .engineer_inner {
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  .engineer_box {
    margin-bottom: 20px;
  }

  .engineer_profile_img_box {
    width: 60px;
    height: 60px;
  }

  .engineer_profile_img {
    width: 100%;
  }

  .profile_name_box {
    margin-left: 15px;
  }

  .profile_name {
    font-size: 15px;
  }

  .profile_tel {
    font-size: 13px;
  }

  .engineer_vist_box {
    width: 100%;
    padding: 14px 0;
    text-align: center;
  }

  .engineer_vist {
    font-size: 15px;
    font-weight: bold;
  }
}

// 업로드 파일 추가

.file_upload_box {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
  gap: 20px;
}
