/* common */
.sub_container {
  padding: 180px 20px;
  box-sizing: border-box;
}

.inner {
  max-width: 1520px;
  margin: 0 auto;
}

.inner_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.password_box {
  width: 100%;
  max-width: 450px;
}

.step_box {
  margin-bottom: 30px;
}

.step_text {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.step_text_b {
  font-weight: bold;
  color: #ff5360;
}

.title_box {
  margin-bottom: 80px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #101e32;
}

/* password */
.password_input_box {
  width: 100%;
  margin-bottom: 50px;
  position: relative;
}

.password_input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
}

.password_input:focus::placeholder {
  color: transparent;
}

.password_input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.password_fail_box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 15px;
}

.password_fail_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.password_fail_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.password_img_box {
  position: relative;
}

.password_view_img {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 18px;
  background: transparent;
  border: none;
}

.button_box {
  width: 100%;
}

.button {
  cursor: pointer;
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #2e384b;
}

.button.disabled {
  opacity: 0.5;
  cursor: default;
}

@media screen and (max-width: 1024px) {
  .sub_container {
    padding: 110px 20px;
  }

  .inner {
    max-width: 1520px;
    margin: 0 auto;
  }

  .inner_box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .password_box {
    max-width: 100%;
  }

  .step_text {
    font-size: 15px;
  }

  .title {
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
  }

  /* password */

  .password_fail_box {
    top: 65px;
    left: 15px;
  }

  .password_input_box {
    width: 100%;
    margin-bottom: 50px;
  }

  .password_input {
    font-size: 14px;
  }

  .password_input::placeholder {
    font-size: 14px;
  }

  .password_fail_text {
    font-size: 12px;
    line-height: 2.31;
  }

  .button {
    font-size: 15px;
    font-weight: bold;
    line-height: 2;
    padding: 16px 0;
  }

  .button.disabled {
    opacity: 0.5;
    cursor: default;
  }
}
