.inner {
  max-width: 1520px;
  margin: 0 auto 250px auto;
  padding: 0 20px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.inner_box {
  display: flex;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;
}

.text_box {
  margin-bottom: 20px;
}

.text {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #6c63ff;
  transition: all 0.3s ease-in-out;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  transition: all 0.3s ease-in-out;
}

/* right inner */

.list_container {
  width: 54%;
  transition: all 0.3s ease-in-out;
}

.list_item_inner {
  width: 100%;
  margin-bottom: 30px;
  background-color: #f6f6f6;
  border-radius: 10px;
}

.list_item_box {
  padding: 28px 30px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

.list_sub_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list_text {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
}

.active_list_text {
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.list_cross_box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

.list_cross_x,
.active_list_cross_x,
.list_cross_y,
.active_list_cross_y {
  width: 24px;
  height: 2px;
  background-color: #dfdfdf;
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.list_cross_y,
.active_list_cross_y {
  width: 2px;
  height: 24px;
}

.list_cross_x {
  transition: all 0.8s ease-in-out;
}

.active_list_cross_x {
  background-color: #ff5360;
  transform: translateX(-12px) translateY(-1px) rotate(225deg);
}

.active_list_cross_y {
  background-color: #ff5360;
  transform: translateX(-1px) translateY(-12px) rotate(405deg);
}

.list_hide_box {
  width: 100%;
  transition: all 0.3s ease-in-out;
  position: absolute;
  padding: 52px 30px 30px 30px;
  box-sizing: border-box;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f6f6f6;
  border-radius: 0 0 10px 10px;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.list_hide_text {
  display: -webkit-box;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #565656;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (max-width: 1500px) {
  .inner {
    max-width: 1200px;
  }

  .list_container {
    width: 70%;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    margin: 0 auto 80px auto;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: initial;
  }

  .text {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.87;
    letter-spacing: normal;
    text-align: left;
    color: #6c63ff;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #0c1522;
  }

  .list_container {
    width: 100%;
  }

  .list_item_inner {
    margin-bottom: 20px;
  }

  .list_item_box {
    padding: 15px 16.5px;
  }

  .list_sub_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list_text,
  .active_list_text {
    font-size: 15px;
    line-height: 1.87;
    width: 90%;
  }

  .list_hide_box {
    padding: 20px 15px 30px 16px;
  }

  .list_cross_box {
    position: absolute;
    top: 30px;
    right: 20px;
  }

  .list_cross_y,
  .active_list_cross_y {
    width: 2px;
    transition: all 0.5s ease-in-out;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .list_cross_x,
  .active_list_cross_x,
  .list_cross_y,
  .active_list_cross_y {
    width: 20px;
    height: 2px;
    background-color: #dfdfdf;
    transition: all 0.5s ease-in-out;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .list_cross_y,
  .active_list_cross_y {
    width: 2px;
    height: 20px;
  }

  .active_list_cross_x {
    background-color: #ff5360;
    transform: translateX(-10px) translateY(-1px) rotate(225deg);
  }

  .active_list_cross_y {
    background-color: #ff5360;
    transform: translateX(-1px) translateY(-10px) rotate(405deg);
  }
}
