// 전체

.container {
  width: 100%;
  height: 100%;
  padding-top: 71.5px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 1200px;
  margin: 60px auto 0;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

// 아티스트 정보 상단

.artist_info_inner {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

// 왼쪽

.media_box {
  width: 62%;
  height: auto;
  position: relative;
}

.artist_media_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.media_play_button_box {
  cursor: pointer;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media_play_button_img {
  padding-left: 5px;
  box-sizing: border-box;
}

// 오른쪽

.artist_info_box {
  width: 33%;
  display: flex;
  flex-direction: column;
}

.info_title_box {
  margin-bottom: 10px;
}

.info_title {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #2e384b;
}

.info_text_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #696969;
}

.info_dot {
  width: 2px;
  height: 2px;
  background-color: #969696;
}

.hr {
  display: block;
  background-color: #dfdfdf;
  margin: 40px 0 30px;
}

.info_payment_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.info_payment_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: #2e384b;
}

.info_price {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: normal;
  color: #ff5360;
}

// 구매 버튼

.direct_button_box {
  width: 100%;
  margin-bottom: 20px;
}

.direct_button {
  cursor: pointer;
  outline: none;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  border: 0;
  border-radius: 10px;
  background-color: #ff5360;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.direct_button:disabled {
  background-color: grey;
}

.sub_button_inner {
  display: flex;
  justify-content: space-between;
}

.sub_button_box {
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  &:last-child {
    padding-left: 10px;
    padding-right: 0;
  }
}

.like_button,
.basket_button {
  cursor: pointer;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  border: 0;
  border-radius: 10px;
  background-color: #f6f6f6;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #2e384b;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basket_button {
  background-color: #56677e;
  font-weight: bold;
  line-height: 1.88;
  text-align: center;
  color: #fff;
}

.basket_button:disabled {
  background-color: grey;
}

.like_img {
  margin-right: 10px;
}

// 하단 아티스트 정보

.info_inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.info_content_box,
.info_sub_content_inner {
  width: 47%;
}

.info_content {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: #696969;
}

.info_vertical {
  width: 1px;
  height: 100%;
  background-color: #dfdfdf;
}

.info_sub_content_inner {
  display: flex;
  justify-content: space-between;
}

.profile_box {
  width: 60px;
  height: 60px;
  border: solid 1px #dfdfdf;
  border-radius: 50%;
  margin-right: 20px;
}

.profile_img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.info_sub_content_box {
  width: calc(100% - 82px);
}

.info_sub_content {
  margin-top: 18px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #696969;
}

// 모바일 전 삭제

.mobile_info_vertical,
.mobile_content {
  display: none;
}

// 장바구니 팝업창

.popup_container {
  position: absolute;
  width: 320px;
  border-radius: 20px;
  border: 1px solid #dcdcdc;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.popup_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
  &:first-child {
    margin-bottom: 10px;
  }
}

.popup_button_box {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup_button {
  cursor: pointer;
  outline: none;
  width: 30%;
  padding: 10px 0;
  box-sizing: border-box;
  border: 0;
  border-radius: 15px;
  margin-right: 10px;
  box-sizing: border-box;
  background-color: #56677e;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.88;
  text-align: center;
  color: #fff;
  &:last-child {
    margin-left: 10px;
    margin-right: 0;
    background-color: #ff5630;
  }
}

@media screen and (max-width: 1024px) {
  // 전체

  .inner {
    margin: 40px auto 0;
  }

  // 아티스트 정보 상단

  .artist_info_inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 50px;
  }

  // 왼쪽

  .media_box {
    width: 60%;
    margin-bottom: 50px;
  }

  // 오른쪽

  .artist_info_box {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .info_title_box {
    margin-bottom: 10px;
  }

  // 하단 아티스트 정보

  .info_inner {
    display: flex;
    justify-content: initial;
    align-items: center;
    flex-direction: column;
  }

  .info_content_box,
  .info_sub_content_inner {
    width: 60%;
  }

  .info_vertical {
    display: none;
  }

  .mobile_info_vertical {
    display: block;
    width: 60%;
    height: 1px;
    background-color: #dfdfdf;
    margin: 20px 0;
  }

  .info_sub_content_inner {
    display: flex;
    justify-content: initial;
    flex-direction: column;
  }

  // 모바일용 프로필 박스

  .mobile_profile_inner {
    display: flex;
    align-items: center;
  }

  .profile_box {
    width: 60px;
    height: 60px;
    border: solid 1px #dfdfdf;
    border-radius: 50%;
    margin-right: 20px;
  }

  .mobile_content {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #000;
  }

  .info_sub_content_box {
    width: calc(100% - 82px);
  }

  .mobile_info_content {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  // 왼쪽

  .media_box {
    width: 100%;
  }

  // 오른쪽

  .artist_info_box {
    width: 100%;
  }

  .info_title {
    font-size: 18px;
    line-height: 1.22;
  }

  .info_text {
    font-size: 13px;
    line-height: 1.69;
  }
  // 구매 버튼

  .like_button,
  .basket_button {
    font-size: 15px;
  }

  // 하단 컨텐츠 부분

  .info_content_box,
  .info_sub_content_inner {
    width: 100%;
  }

  .info_content {
    font-size: 13px;
    line-height: 1.77;
  }

  .mobile_info_vertical {
    width: 100%;
  }
}
