.box_post {
  display: block;
  position: fixed;
  top: 50%;
  z-index: 9999;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
}

.box_post.active {
  visibility: visible;
  opacity: 1;
}

.btn_close {
  position: absolute;
  top: -36px;
  right: 0px;
  content: '';
  display: inline-block;
  width: 32px;
  height: 32px;
  background-image: url('/images/ic_photo_del.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}