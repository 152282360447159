.cancel_button {
  height: 64px;
  cursor: pointer;
  position: fixed;
  left: 20px;
  bottom: 40px;
  display: flex;
  z-index: 1;
  align-items: center;
  transition: all 0.5s ease-in-out;
  background-color: #f32424;
  box-shadow: 0 3px 10px 0 rgba(243, 36, 36, 0.3);
  border-radius: 35px;
}

.cancel_button:hover {
  padding-right: 140px;
}

.cancel_icon {
  width: 24px;
  height: auto;
  display: inline-block;
}

.cancel_icon_box {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide_text_box {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  white-space: nowrap;
}

.hide_text {
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  transition: all 1s ease-in-out;
  opacity: 0;
  white-space: nowrap;
}

.cancel_button:hover .hide_text_box {
  visibility: visible;
  opacity: 1;
}

.cancel_button:hover .hide_text {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .cancel_button {
    bottom: 80px;
  }

  .cancel_button:hover {
    padding-right: 110px;
  }

  .hide_text_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }

  .hide_text {
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    opacity: 0;
  }
}
