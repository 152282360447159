.sub_container {
  padding: 71.5px 20px 0;
}

.inner {
  padding: 105px 0 80px;
  box-sizing: border-box;
  max-width: 450px;
  margin: 0 auto;
  position: relative;
}

.back_box {
  cursor: pointer;
  position: absolute;
  top: 105px;
  left: -50px;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.back_text_box {
  margin-left: 10px;
}

.back_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #56677e;
}

.inner_box {
  margin-top: 63px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title_box {
  margin-bottom: 80px;
}

.title {
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #101e32;
}

.password_input_box {
  width: 100%;
  margin-bottom: 50px;
}

.password_input {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  background-color: #fff;
  font-size: 18px;
}

.password_input:focus::placeholder {
  color: transparent;
}

.password_input::placeholder {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #969696;
}

.password_fail_inner {
  position: relative;
  margin-top: 10px;
}

.password_fail_box {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 15px;
}

.password_fail_img {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.password_fail_text {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #ff5360;
}

.password_img_box,
.last_password_img_box {
  position: relative;
  margin-bottom: 20px;
}

.last_password_img_box {
  margin-bottom: 0;
}

.password_view_img {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 18px;
}

.button_box {
  width: 100%;
}

.button {
  width: 100%;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  background-color: #2e384b;
  padding: 20px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #fff;
}

.button.disabled {
  opacity: 0.5;
  cursor: default;
}

@media screen and (max-width: 1325px) {
  .back_box {
    left: 0;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    padding: 45px 0;
    max-width: 100%;
  }

  .back_box {
    top: 45px;
    left: 0;
  }

  .back_text_box {
    margin-left: 10px;
  }

  .back_text {
    font-size: 15px;
    font-weight: 500;
  }

  .inner_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title_box {
    margin-bottom: 50px;
  }

  .title {
    font-size: 30px;
    line-height: 2;
  }

  .password_input {
    width: 100%;
    padding: 16px 20px;
    font-size: 14px;
    line-height: 2.14;
  }

  .password_input::placeholder {
    font-size: 14px;
    line-height: 2.14;
  }

  .password_fail_text {
    font-size: 12px;
    line-height: 2.31;
  }

  .button {
    padding: 17px 0;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 2;
  }
}
