// 전체

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 71.5px 20px 50px;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  margin-top: 60px;
}

// 타이틀

.title_box {
  display: flex;
  align-items: center;
  margin-bottom: 65px;
}

.title {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.text {
  margin-left: 20px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
}

// 테이블

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead {
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #f9f9f9;
}

.td {
  padding: 12px 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #56677e;
  text-align: center;
}

.tbody_td {
  padding: 12px 0;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
  box-shadow: inset 0 -1px 0 0 #efefef;
  text-align: center;
}

.tbody_tr_img {
  width: 100px;
  height: 56px;
  border-radius: 4px;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 30px 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

// 총 결제 금액

.total_container {
  width: 100%;
  background-color: rgba(41, 179, 235, 0.9);
}

.total_inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media_total_inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media_total_payment_inner {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media_total_box {
  display: flex;
  justify-content: center;
}

.media_sub_text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: #fff;
  margin-right: 20px;
}

.media_text,
.media_payment_text {
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #fff;
}

.media_total_payment_box {
  display: flex;
  align-items: center;
}

.payment_button {
  cursor: pointer;
  outline: none;
  margin-left: 50px;
  border: 0;
  border-radius: 10px;
  background-color: #fff;
  padding: 10px 70px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

// 모바일 디스플레이 none
.mobile_tbody_td {
  display: none;
}

@media screen and (max-width: 1024px) {
  // 전체

  .container {
    padding: 71.5px 20px 0;
  }

  .inner {
    margin-top: 50px;
  }

  // 타이틀

  .title_box {
    display: flex;
    align-items: initial;
    flex-direction: column;
    margin-bottom: 45px;
  }

  .title {
    font-size: 30px;
    line-height: 1.33;
  }

  .text {
    margin-left: 0;
    font-size: 15px;
    line-height: 2;
  }

  // 테이블

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .thead {
    display: none;
  }

  .tbody_tr {
    box-shadow: inset 0 -1px 0 0 #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .tbody_td {
    padding: 20px 10px;
    line-height: 1.83;
    box-shadow: initial;
    text-align: initial;
    display: flex;
    flex-direction: column;
  }

  .tbody_tr_img {
    width: 180px;
    height: 110px;
    border-radius: 4px;
  }

  .mobile_tbody_td {
    display: block;
  }

  /* 페이지네이션 */

  .pagination_box {
    margin: 30px 0 50px;
  }

  .pagination_number_inner {
    margin: 0 15px;
  }

  .pagination_number_box {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }

  .pagination_number {
    font-size: 13px;
  }

  .active_pagination_number {
    font-size: 13px;
  }

  // 결제 방식

  .selection_inner {
    margin-bottom: 50px;
  }

  .selection_title_box {
    flex-direction: column;
    align-items: initial;
  }

  .selection_text {
    margin-left: 0;
  }

  // 결제 방식 선택

  .selection_way_inner {
    margin-bottom: 50px;
  }

  .selection_way_box {
    align-items: center;
    flex-direction: column;
  }

  .way_button_box {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    &:last-child {
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 0;
    }
  }

  .way_button_img {
    margin-right: 10px;
  }

  .way_button,
  .active_way_button {
    cursor: pointer;
    width: 100%;
    padding: 15px 0;
  }

  .active_way_button {
    background-color: #ff5360;
    color: #fff;
  }

  // 세금계산서

  .tax_inner {
    margin-bottom: 30px;
  }

  // 세금 계산서 인풋창

  .tax_input_inner {
    margin-top: 30px;
  }

  .input_inner {
    display: flex;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
    margin-bottom: 0;
  }

  .input_box {
    width: 100%;
    margin-bottom: 30px;
  }

  // 총 결제 금액

  .total_inner {
    padding: 15px 20px;
    flex-direction: column;
  }

  .media_total_inner {
    width: 100%;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
  }

  .media_total_sub_inner {
    width: 100%;
    align-items: initial;
    justify-content: initial;
    flex-direction: column;
  }

  .media_total_box {
    display: flex;
    justify-content: initial;
    flex-direction: initial;
    // margin-bottom: 20px;
  }

  .media_sub_text {
    font-size: 11px;
  }

  .media_text,
  .media_payment_text {
    font-size: 15px;
  }

  .media_total_payment_inner {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-direction: initial;
  }

  .media_total_payment_box {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  .mobile_media_sub_text {
    margin-right: 0;
  }

  .mobile_media_payment_text {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
  }

  .payment_button {
    margin-top: 20px;
    width: 100%;
    margin-left: 0;
    padding: 10px 0;
    font-size: 18px;
  }
}

@media screen and (max-width: 550px) {
  .tbody_tr {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .tbody_td {
    padding: 10px 0;
    font-size: 16px;
    line-height: 0.7;
    display: flex;
    flex-direction: initial;
    width: 50%;
    &:first-child {
      flex-direction: column;
      width: 100%;
    }
  }

  .mobile_tbody_td {
    margin-right: 10px;
  }

  .mobile_tbody_td_name {
    font-weight: 400;
  }

  .tbody_tr_img {
    width: 100%;
    height: 250px;
    border-radius: 4px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .tbody_tr_img {
    height: 180px;
  }

  .tbody_td {
    font-size: 13px;
  }
}
