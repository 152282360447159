// 첫번째 카테고리

.container {
  width: 100%;
  padding: 71.5px 20px 0;
  box-sizing: border-box;
}

.sub_container {
  width: 100%;
  max-width: 1200px;
  margin: 90px auto 0;
}

.category_inner {
  margin-bottom: 80px;
}

.category_title_box {
  margin-bottom: 30px;
}

.category_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #101e32;
}

.category_text,
.category_text_b {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #565656;
  margin-left: 10px;
}

.category_text_b {
  font-weight: 500;
  color: #ff5360;
}

.category_box {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 0;
}

// 인풋 리벨 변경

.category_input_box {
  width: calc(100% / 5);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hide_input {
  display: none;
}

.custom_label {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

.circle_label {
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid #efefef;
  margin-right: 15px;
}

.hide_input.active + .custom_label {
  color: #65bcfd;
}

.hide_input.active + .custom_label .circle_label {
  width: 14px;
  height: 14px;
  border: 5px solid #65bcfd;
}

// 위험 카테고리

.category_danger_text {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2e384b;
}

// 반응형

@media screen and (max-width: 1024px) {
  .container {
    padding: 71.5px 20px 0;
  }

  .sub_container {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto 0;
  }

  .category_inner {
    margin-bottom: 50px;
  }

  .category_title_box {
    margin-bottom: 30px;
  }

  .category_title {
    font-size: 24px;
    line-height: 1.7;
  }

  .category_text {
    display: block;
    font-size: 14px;
    margin-left: 0;
  }

  .category_box {
    gap: 30px 0;
  }

  // 인풋 리벨 변경

  .category_input_box {
    width: 200px;
  }

  .custom_label {
    font-size: 18px;
  }

  .circle_label {
    width: 20px;
    height: 20px;
  }

  .hide_input:checked + .custom_label .circle_label {
    width: 12px;
    height: 12px;
  }

  // 위험 카테고리

  .category_danger_text {
    line-height: 1.5;
  }
}

@media screen and (max-width: 500px) {
  .category_input_box {
    width: 50%;
  }
}
