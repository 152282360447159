.container {
  height: 100vh;
}

.sub_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: rgba($color: #000000, $alpha: 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner {
  width: 100%;
  max-width: 560px;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  background-color: #fff;
  border-radius: 20px;
}

/* 헤더 */

.header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 20px 20px 0 0;
}

.title_box {
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e384b;
  text-align: center;
}

.close_button_box {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 10px;
}

/* 컨텐트 */

.content_inner {
  border-radius: 0 0 20px 20px;
  background-color: #fff;
  padding: 50px;
  box-sizing: border-box;
}

.sign_box {
  width: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.seal_img_box {
  margin-bottom: 30px;
}

.sign_text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  text-align: center;
  color: #56677e;
}

.button_inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_box {
  width: 50%;
  box-sizing: border-box;
  padding-right: 10px;
  box-sizing: border-box;
}

.button_box:last-child {
  padding-right: 0;
  padding-left: 10px;
}

.cancle_button {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #dfdfdf;
  border-radius: 10px;
  background-color: transparent;
  border: solid 1px #dfdfdf;
}

.complete_button {
  cursor: pointer;
  display: inline-block;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  border-radius: 10px;
  background-color: #2e384b;
  border: solid 1px #2e384b;
}

@media screen and (max-width: 1024px) {
  .inner {
    max-width: 100%;
  }

  /* 헤더 */

  .header {
    padding: 10px;
  }

  .title {
    font-size: 18px;
  }

  /* 컨텐트 */

  .content_inner {
    padding: 20px;
  }

  .sign_box {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .button_inner {
    width: 100%;
    padding: 0;
  }

  .complete_button {
    font-size: 15px;
  }
}
