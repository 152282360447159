// 전체

.container {
  width: 100%;
  height: 100%;
}

// 테이블

.table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

.thead_tr {
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #f9f9f9;
}

.thead_td {
  padding: 10px;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #56677e;
  &:last-child {
    text-align: right;
    padding-right: 40px;
  }
}

.title_thead_td {
  width: 40%;
}

// 테이블 바디

.tbody_tr {
  box-shadow: inset 0 -1px 0 0 #efefef;
  background-color: #fff;
}

.tbody_td {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: center;
  color: #969696;
  padding: 12px 0;
  box-sizing: border-box;
}

.tbody_title_td {
  display: flex;
  align-items: center;
}

.tbody_td_img {
  width: 100px;
  height: 56px;
  border-radius: 4px;
}

.title_box {
  margin-left: 20px;
}

.title {
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  text-align: left;
  color: #2e384b;
}

.name {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: left;
  color: #56677e;
}

.tbody_payment_td {
  padding-right: 40px;
  box-sizing: border-box;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: right;
  color: #2e384b;
}

/* 페이지네이션 */

.pagination_box {
  width: 100%;
  margin: 30px 0 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination_button {
  cursor: pointer;
  outline: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #dedede;
  background-color: #fff;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
}

.pagination_button:hover {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
}

.pagination_number_inner {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.pagination_number_box {
  margin-right: 30px;
}

.pagination_number_box:last-child {
  margin-right: 0;
}

.pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #999;
}

.active_pagination_number {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ff5360;
}

.mobile_tbody_td,
.mobile_payment_td_inner {
  display: none;
}

.empty_box {
  min-height: calc(100vh - 610px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty_text {
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 1024px) {
  // 전체
  .container {
    width: 100%;
    height: 100%;
  }

  // 테이블

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
  }

  .thead_tr {
    box-shadow: inset 0 -1px 0 0 #efefef;
    background-color: #f9f9f9;
    display: none;
  }

  // 테이블 바디

  .tbody_tr {
    box-shadow: inset 0 -1px 0 0 #efefef;
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }

  .tbody_td {
    width: 100%;
    text-align: left;
    display: flex;
    &:first-child {
      display: none;
    }
  }

  .mobile_tbody_purchase_td {
    padding: 12px 0 0 0;
    box-sizing: border-box;
  }

  .mobile_tbody_date_td {
    display: none;
  }

  .mobile_tbody_td {
    display: block;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: #969696;
  }

  .tbody_title_td {
    display: flex;
    align-items: center;
  }

  .tbody_td_img {
    width: 100px;
    height: 56px;
    border-radius: 4px;
  }

  .tbody_payment_td {
    display: none;
  }

  // 하단 모바일에 나오는 날짜와 금액

  .mobile_payment_td_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .mobile_td_p {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #2e384b;
  }
}
