.inner {
  padding-top: 50px;
  box-sizing: border-box;
  min-width: 100vw;
}

.box {
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

/* left inner */

.text_inner {
  width: 43%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.title_box {
  margin-bottom: 30px;
}

.title {
  font-size: 46px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  text-align: left;
  color: #0c1522;
  transition: all 0.3s ease-in-out;
}

.title_color {
  color: #ff5360;
}

.text {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #696969;
  transition: all 0.3s ease-in-out;
}

/* right inner */

.img_inner {
  display: flex;
  width: 50%;
  height: 800px;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.img_inner {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.img_inner::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, Opera*/
}

.left_img_box,
.center_img_box,
.right_img_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 30px;
  width: 33%;
  transition: all 0.3s ease-in-out;
}

.left_img_box,
.right_img_box {
  padding: 105px 0 25px;
  box-sizing: border-box;
}

.right_img_box {
  margin-right: 0;
}

.img_box {
  flex: 0 0 auto;
  width: 100%;
  height: 270px;
  border-radius: 20px;
  margin-bottom: 40px;
  transition: all 0.3s ease-in-out;
}

.img_box:last-child {
  margin-bottom: 0;
}

.mobile_br {
  display: none;
}

/* 1200px media query */

@media screen and (max-width: 1500px) {
  .box {
    padding: 0 20px;
  }

  .text_inner {
    width: 35%;
  }

  .img_inner {
    width: 55%;
  }

  .pc_br {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .img_inner {
    width: 60%;
  }

  .title {
    font-size: 38px;
    line-height: 1.32;
  }

  .text {
    font-size: 18px;
    line-height: 1.71;
  }
}

@media screen and (max-width: 1024px) {
  .inner {
    margin-top: 280px;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .text_inner {
    width: 100%;
  }

  .img_inner {
    width: 100%;
    height: 370px;
    justify-content: center;
  }

  .title_box {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
  }

  .title {
    font-size: 26px;
    line-height: 1.32;
  }

  .text_box {
    margin-bottom: 56px;
  }

  .text {
    font-size: 14px;
    line-height: 1.71;
    text-align: center;
  }

  .mobile_br {
    display: block;
  }

  .left_img_box,
  .center_img_box,
  .right_img_box {
    width: 30%;
    margin-right: 14px;
  }

  .right_img_box {
    margin-right: 0;
  }

  .img_box {
    width: 100%;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 650px) {
  .img_box {
    height: 200px;
  }
}

@media screen and (max-width: 360px) {
  .title_box {
    margin-bottom: 30px;
  }

  .mobile_br {
    display: none;
  }

  .img_box {
    width: 85px;
    height: 120px;
  }
}
